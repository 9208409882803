import { Box } from '@mui/material'
import PhoneInput from 'react-phone-input-2'
import BaseInput, { BaseTextFieldProps } from './base-input.component'
import './scss/phone-input.style.scss'

export default function PhoneInputComponent({ phonePrefix, phone, handleChangeCountry, ...otherProps }: Props) {
    return (
        <Box className='account'>
            <Box className='phone-country'>
                <PhoneInput
                    enableSearch={true}
                    preferredCountries={['it', 'es', 'cn']}
                    containerClass='react-input-fixed'
                    inputStyle={{
                        display: 'none'
                    }}
                    containerStyle={{
                        width: 50,
                        height: 40,
                    }}
                    searchStyle={{
                        border: 'none',
                        outline: 'none',
                    }}
                    disableSearchIcon={true}
                    searchNotFound=''
                    value={phonePrefix}
                    onChange={handleChangeCountry}
                />
            </Box>
            <BaseInput
                {...otherProps as BaseTextFieldProps}
                className='account-input'
                value={phone}
                InputLabelProps={{
                    shrink: true
                }}
            />
        </Box>
    )
}

type Props = {
    phonePrefix: string;
    phone: string;
    handleChangeCountry: ((value: string) => void) | undefined
} & BaseTextFieldProps
