import React, { ReactNode } from 'react';
import { Box, Checkbox, CircularProgress, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import TablePagination from '@mui/material/TablePagination';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import _ from 'lodash';
// scss
import './scss/table.style.scss'
import i18n from 'i18next';
import { KeyboardArrowDown } from '@mui/icons-material';
import ButtonComponent, { ButtonComponentType } from '../button/button.component';
import DeleteDialogComponent from '../dialog/delete-dialog.component';

// table组件
export interface Data {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

// 头部类型声明
export interface HeadCell {
    id: string;
    label: string;
    // -1 不显示排序得框框 ， 0 不排序  1 降序 2 升序
    sortStatus?: number;
    renderFunction?: (row: Data, index: number) => ReactNode;
    width?: string;
}

interface EnhancedTableProps {
    stickyHeaderItemClass?: string;
    headCellList: Array<HeadCell>;
    numSelected: number;
    rowCount: number;
    showChecked: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSort?: (headCell: HeadCell) => void;
    // 单选或者多选，默认多选
    selectType?: 'RADIO' | 'CHECK';
    hasExpand?: boolean;
    // 是否显示表头序号
    isShowSerialNum?: boolean;
}

interface TableRowCellProps {
    headCellList: Array<HeadCell>;
    row: Data;
    index: number;
    hasExpand?: boolean;
    isExpandValue?: boolean;
    onHandleExpand?: (_id: string, index: number, isExpandValue?: boolean) => void;
}

const useToolbarStyles = makeStyles(() =>
    createStyles({
        root: {
            // paddingLeft: theme.spacing(2),
            // paddingRight: theme.spacing(1),
        },
        highlight:
        {
            color: '#fff',
            background: 'linear-gradient(72.47deg, rgb(115, 103, 240) 22.16%, rgba(115, 103, 240, 0.7) 76.47%)',
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,

        },
        title: {
            flex: '1 1 100%',
        }
    })
);

interface EnhancedTableToolbarProps {
    numSelected: number;
    handleDelete: () => void;
    renderToolBar?: () => React.ReactNode;
}

// $ 复选框的头部
const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, handleDelete, renderToolBar } = props;
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
            sx={{ padding: '0 16px' }}
        >
            {numSelected > 0 ? (
                <>
                    <Typography className={classes.title} color='inherit' variant='subtitle1' component='div'>
                        {numSelected} {i18n.t('table_component_text_selected')}
                    </Typography>
                    <Tooltip title={i18n.t('general_text_delete')}>
                        <IconButton aria-label='delete' onClick={() => handleDelete()}>
                            <DeleteIcon style={{ color: '#fff' }} />
                        </IconButton>
                    </Tooltip>
                </>

            ) : (
                <>
                    {renderToolBar?.()}
                </>
            )}
        </Toolbar>
    );
};

// $ 头部信息
function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount, showChecked, headCellList,
        handleSort, selectType, hasExpand, stickyHeaderItemClass = '', isShowSerialNum } = props;
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow className={classes.tableHeadBg}>

                {/* 复选框 */}
                {
                    showChecked !== 0 && (
                        <TableCell padding='checkbox' className={`${stickyHeaderItemClass}`}>
                            {selectType === 'RADIO' ? null :
                                <Checkbox
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    checked={rowCount > 0 && numSelected === rowCount}
                                    onChange={onSelectAllClick}
                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                />
                            }
                        </TableCell>
                    )
                }
                {/* 展开图标的表头占位 */}
                {
                    hasExpand ?
                        <TableCell className={`${stickyHeaderItemClass}`}>
                        </TableCell>
                        : null
                }
                {
                    isShowSerialNum &&
                    <TableCell className={`${stickyHeaderItemClass}`}>
                        <div className='headerTableCell'
                            style={{ cursor: 'default' }}
                        >
                            <p className='headerLabel'>{i18n.t('table_component_text_serial_number')}</p>
                        </div>
                    </TableCell>
                }
                {/* 列表头部数据 */}
                {headCellList.map((headCell: HeadCell) => (
                    <TableCell
                        key={headCell.id}
                        className={`${stickyHeaderItemClass}`}
                    >
                        <div className='headerTableCell'
                            style={{ cursor: [0, 1, 2].includes(headCell.sortStatus ?? -1) ? 'pointer' : 'default' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                [0, 1, 2].includes(headCell.sortStatus ?? -1) && handleSort?.(headCell);
                            }}
                        >
                            <p className='headerLabel'>{headCell.label}</p>

                            {/* 需要排序字段才显示 */}
                            {
                                [0, 1, 2].includes(headCell.sortStatus ?? -1) ? (
                                    <div className='sortButton'>
                                        <ArrowDropDownIcon
                                            className='down'
                                            style={{ color: headCell.sortStatus === 2 ? '#1F78FB' : '#707174' }}
                                        />
                                        <ArrowDropDownIcon
                                            className='down'
                                            style={{ color: headCell.sortStatus === 1 ? '#1F78FB' : '#707174' }}
                                        />
                                    </div>
                                ) : null
                            }
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

/**
 * 默认表内容
 */
function RenderChildrenBase(props: TableRowCellProps) {

    const { row, index, headCellList, hasExpand, isExpandValue } = props;

    return (
        <>
            {hasExpand && <TableCell size='small'>
                <ButtonComponent
                    variant='text'
                    sx={(theme) => { return { color: isExpandValue ? theme.palette.primary.main : theme.palette.secondary.main } }}
                    handleClick={() => {
                        props.onHandleExpand && props.onHandleExpand(row._id as string, index, isExpandValue);
                    }}>
                    {/* 操作对应接团id的变量控制 */}
                    {isExpandValue ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </ButtonComponent>
            </TableCell>}
            {headCellList?.map((column, columnKey) => {
                return (<TableCell key={columnKey} size='small'>
                    {column.renderFunction
                        ? column.renderFunction(row, props.index)
                        : _.get(row, column.id, '')}
                </TableCell >)
            })}
        </>
    )
}

// 表格组件自定义样式
const useStyles = makeStyles(() =>
    createStyles({
        tableShadow:
        {
            boxShadow: 'rgba(36, 34, 43, 0.03) 0px 3px 9px 4px, rgb(51 48 60 / 2%) 0px 8px 9px 2px, rgb(51 48 60 / 1%) 0px 1px 6px 1px'
        },
        tableHeadBg:
        {

        }
    })
);

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

// 自定义的页脚分页组件
function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

// $ 表格
export default function TableComponent(
    props: TableComponentProps
): JSX.Element {

    const { renderExtraCollapseComponent, showChecked, list, headCellList, isPagination = false, allCount = 0, selectType,
        stickyHeader = true, currentPage = 0, rowsPerPageOptions = [20, 50, 100], rowsPerPage = 20, extraClass = '',
        isTableLoading = false, stickyHeaderItemClass = '', compareSymbol = '_id', originSelect = [], synchronize = true, isUseFold,
        isUseCustomPagination, operateCollapseComponent, handleSort, handleCheck, handleDeleteAll,
        filterCheckData, renderToolBar, hasExpand, changePageCallback, isActivateSummation, summationArray, isCurrentPageData } = props;

    // 自定义表格样式
    const classes = useStyles();
    // 复选框选中的数据
    const [selected, setSelected] = React.useState<Data[]>(originSelect);
    // 当前页数  切换页数
    const [page, setPage] = React.useState<number>(currentPage);
    // 设置当前页数显示的行数
    const [curRowsPerPage, setCurRowsPerPage] = React.useState<number>(rowsPerPage)
    // 控制复选框批量删除提示
    const [deleteAllDialog, setDeleteAllDialog] = React.useState<boolean>(false);
    // 操作列表折叠组件
    const [expandValue, setExpandValue] = React.useState<typeof operateCollapseComponent>({
        _id: '',
        activeIndex: 0
    })
    // 表格数据
    const tableData = isCurrentPageData ? list : list.slice(page * curRowsPerPage, page * curRowsPerPage + curRowsPerPage);

    // $ 列表项删除需要同步复选数据, 可能在外层删除
    React.useEffect(() => {
        /**
         * $ 1.列表长度有改变，因此需要排除复选框的数据 (主要为了解决单删除没有同步到)
         * $ 2.可能是搜索导致的，如果是搜索就不应该重置selected
         */
        // 如果只是搜索不让他清除掉selected
        if (synchronize) {
            const originSelected = _.cloneDeep(selected);
            const newArr: Array<Data> = [];
            originSelected.forEach((item) => {
                // todo 这里需要优化
                const idList = list.map((i) => {
                    return i?.[compareSymbol];
                })
                if (idList.includes(item?.[compareSymbol])) {
                    newArr.push(item)
                }
            })
            setSelected(newArr);
        }
        // eslint-disable-next-line
    }, [allCount, page, curRowsPerPage]);

    // 同步初始值
    React.useEffect(() => {
        setSelected(originSelect);
        // 每次刷新列表时设置当前页数为第一页
        if (isTableLoading && (!isCurrentPageData)) {
            setPage(0);
        }
        if (typeof props.page === 'number') {
            setPage(props.page)
        }
    }, [originSelect.length, isTableLoading, props.page])

    // 选中所有或者清除所有
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        /**
         * 1.设置选中只选中当前页行数的数据
         * 2.全选按钮,选中取消,没有选中全选
         * 3.触发父类的 handleCheck方法
         * $ 4.过滤不需要被勾选在的数据
         */
        if (event.target.checked) {
            // 设置选中只选中当前页行数的数据
            // 当前页行数选中的第一个索引
            const firstIndex = page * curRowsPerPage;
            // 当前页行数选中的最后一个索引
            const lastIndex = (page + 1) * curRowsPerPage;
            const currentRowsSelected = list.slice(firstIndex, lastIndex);
            // $ 数据过滤
            const newSelected = filterCheckData?.(currentRowsSelected) || currentRowsSelected;
            handleCheck?.(newSelected);
            setSelected(newSelected);
            return;
        }
        handleCheck?.([]);
        setSelected([]);
    };

    // 空值函数 没有获取到数据时
    const emptyFunc = () => { }

    // 单机选中单行
    const handleClick = (event: React.MouseEvent<unknown>, row: Data) => {
        /**
         * 1.这里单选状态和复选状态
         * 2.保存选中的数据
         * 3.过滤掉不需要的字段勾选的
         */
        // 不可以编辑 (不给高亮选中状态)
        if (showChecked === 0) return;
        // 选中的数据处理
        // const selectedIndex = selected.findIndex((item) => {
        //     if (item[compareSymbol]) {
        //         return item[compareSymbol] === row[compareSymbol];
        //     }
        //     return false;
        // });

        // 选中的数据处理
        const selectedItem = selected.some((item) => {
            if (item[compareSymbol]) {
                return item[compareSymbol] === row[compareSymbol];
            }
            return false;
        });

        let newSelected: Data[] = [];
        // if (selectType === 'RADIO') {
        //     if (selectedIndex === -1) {
        //         // $ 如果是单选而且选择是禁用数据，不进行替换
        //         if (filterCheckData?.([row])?.length === 0) return
        //         newSelected = [row];
        //     } else if (selectedIndex === 0) {

        //         newSelected = [];
        //     }
        // } else {
        //     // splice会影响原数组
        //     if (selectedIndex === -1) {
        //         newSelected = newSelected.concat(selected, row);
        //     } else if (selectedIndex === 0) {
        //         newSelected = newSelected.concat(selected.slice(1));
        //     } else if (selectedIndex === selected.length - 1) {
        //         newSelected = newSelected.concat(selected.slice(0, -1));
        //     } else if (selectedIndex > 0) {
        //         newSelected = newSelected.concat(
        //             selected.slice(0, selectedIndex),
        //             selected.slice(selectedIndex + 1)
        //         );
        //     }
        // }
        if (selectType === 'RADIO') {
            if (!selectedItem) {
                if (filterCheckData?.([row])?.length === 0) return;
                newSelected = [row];
            } else {
                newSelected = [];
            }
        } else {
            if (!selectedItem) {
                newSelected = [...selected, row];
            } else {
                newSelected = selected.filter((item) => {
                    return !(item[compareSymbol] === row[compareSymbol]);
                });
            }
        }
        // $ 数据过滤
        // newSelected = filterCheckData?.(newSelected) || newSelected;
        const finalSelected = filterCheckData?.(newSelected.filter((item) => !!item)) || newSelected;
        handleCheck?.(finalSelected || []);
        setSelected(finalSelected || newSelected);
    };

    // 改变当前页数  下一页 | 上一页
    const handleSetPage = (event: any, newPage: number) => {
        setPage(newPage);
        changePageCallback?.({ page: newPage, curRowsPerPage });
        /**
         * 讨论决定 切换表格页数时重置复选框选择的数据
         */
        if (selected.length > 0) {
            setSelected([]);
        }
    }

    // 设置每页显示的...行数
    const handleSetRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const rowsPerPage = parseInt(event.target.value, 10)
        setCurRowsPerPage(rowsPerPage);
        setPage(0);
        changePageCallback?.({ page: 0, curRowsPerPage: rowsPerPage });
        /**
         * 讨论决定 改变表格每页显示的行数时重置复选框选择的数据
         */
        if (selected.length > 0) {
            setSelected([]);
        }
    };

    // 判断是否被选中了 当前行
    const isSelected = (row: Data) => selected.findIndex((item) => {
        if (item[compareSymbol]) {
            return item[compareSymbol] === row[compareSymbol];
        }
        return false;
    }) !== -1;

    // 关闭或者开启 批量删除弹窗
    function toggleDialog(status: boolean) {
        setDeleteAllDialog(status);
    }

    // 批量删除确定按钮
    async function handleConfirmDelete(status: number) {
        /**
         * 1.触发父级删除事件
         * 2.清空选中的复选内容
         * 3.关闭提示框
         */
        if (status === 1) {
            await handleDeleteAll?.(selected);
            setSelected([]);
            setDeleteAllDialog(false);
        }
    }

    // 获取到没有数据时 colSpan的值
    function getColSpan() {
        // 头部加复选框
        const num = (headCellList?.length ?? 0) + (showChecked === 0 ? 0 : 1) + (hasExpand ? 1 : 0);
        return num
    }

    //
    /**
     * 操作折叠组件展开
     * @param _id 表格_id
     * @param activeKey 表格索引
     * @param isExpand 展开的值
     */
    function handleExpand(_id: string, activeKey: number, isExpand?: boolean) {
        // 存在展开的项时关闭
        if (isExpand) { setExpandValue({ _id: '', activeIndex: -1 }) }
        // 不存在则展开
        else { setExpandValue({ _id: _id, activeIndex: activeKey }) }
    }

    // 复选框是否可用
    function checkDisabled(row: any) {
        /**
         * 1.过滤函数不存在返回false
         * 2.过滤函数存在数组，说没没用不符合数据 返回false
         */
        return filterCheckData ? filterCheckData?.([row])?.length ? false : true : false
    }

    return (

        <div className={`tableComponent ${classes.tableShadow} ${extraClass}`}>
            {
                //  $ 复选框删除提示框 (只有1的时候才显示)
                [1].includes(showChecked) && selectType !== 'RADIO' && (
                    <EnhancedTableToolbar
                        {...{
                            numSelected: selected.length,
                            handleDelete: () => { toggleDialog(true) },
                            renderToolBar
                        }}
                    />
                )
            }
            {/* 主要内容 */}
            <TableContainer className='tableContainer'>
                <Table
                    stickyHeader={stickyHeader}
                    aria-labelledby='tableTitle'
                    aria-label='enhanced table'
                    className='table'
                >
                    {/* table头部 */}
                    <EnhancedTableHead
                        stickyHeaderItemClass={stickyHeaderItemClass}
                        headCellList={headCellList}
                        showChecked={showChecked}
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={selected.length}
                        handleSort={handleSort}
                        selectType={selectType}
                        hasExpand={hasExpand}
                        isShowSerialNum={props.isShowSerialNum || isActivateSummation}
                    />
                    {/* loading 加载框显示 */}
                    {
                        isTableLoading && (
                            <TableBody className='loadingBody'>
                                <TableRow className='tableRow'>
                                    <TableCell colSpan={getColSpan()} className='tableCell' >
                                        <CircularProgress sx={(theme) => { return { color: theme.palette.primary.main } }} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )
                    }

                    {/* table内容 */}
                    <TableBody className='tableBody'>
                        {
                            list.length > 0 ? (
                                // 截取当前页行数数据
                                tableData.map((row, index) => {
                                    const isItemSelected = isSelected(row);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    // 是否展开折叠组件列表
                                    const isExpandValue = expandValue?.activeIndex === index && expandValue?._id === row?._id;
                                    return (
                                        <React.Fragment key={row?.[compareSymbol] || index}>
                                            <TableRow
                                                hover
                                                onClick={(event) => isUseFold ? emptyFunc() : handleClick(event, row)}
                                                role='checkbox'
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row?.[compareSymbol] || index}
                                                selected={isItemSelected}
                                                className='borderBottom-set'

                                            >
                                                {/* 复选框 */}
                                                {
                                                    showChecked !== 0 && (
                                                        <TableCell
                                                            size='small'
                                                            padding='checkbox'
                                                            onClick={(event) => isUseFold ? handleClick(event, row) : emptyFunc()}
                                                        >
                                                            <Checkbox
                                                                disabled={checkDisabled(row)}
                                                                checked={isItemSelected}
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </TableCell>
                                                    )
                                                }
                                                {
                                                    (isActivateSummation && list.length > 0) ?
                                                        <TableCell
                                                            size='small'
                                                            style={{
                                                                borderTop: 0,
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </TableCell>
                                                        : null
                                                }
                                                {
                                                    (props.isShowSerialNum && list.length > 0) ?
                                                        <TableCell
                                                            size='small'
                                                            style={{
                                                                borderTop: 0,
                                                            }}
                                                        >
                                                            {index + 1 + page * curRowsPerPage}
                                                        </TableCell>
                                                        : null
                                                }

                                                {/* 子元素 */}
                                                {
                                                    <RenderChildrenBase
                                                        {...{
                                                            headCellList,
                                                            row,
                                                            index,
                                                            hasExpand,
                                                            isExpandValue,
                                                            onHandleExpand: handleExpand
                                                        }}
                                                    />
                                                }
                                            </TableRow>
                                            {/* 折叠的组件 用于集团列表页展示授权点和授权集合相关信息 */}
                                            {
                                                renderExtraCollapseComponent &&
                                                <TableRow style={{}}>
                                                    <TableCell
                                                        style={{
                                                            padding: '0px 10px',
                                                            borderTop: 0,
                                                        }}
                                                        colSpan={10}
                                                    >
                                                        <Collapse
                                                            in={isExpandValue}
                                                            timeout={450}
                                                            unmountOnExit
                                                            className='collapse-class'
                                                        >
                                                            {renderExtraCollapseComponent(row, index)}
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                // 没有数据的时候信息提示
                                <TableRow
                                    hover
                                    role='checkbox'
                                    tabIndex={-1}
                                    className='borderBottom-set'
                                >

                                    <TableCell
                                        colSpan={getColSpan()}
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                            borderBottom: 'none'
                                        }}
                                    >
                                        {i18n.t('table_component_text_no_or_more_data')}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        {
                            (isActivateSummation && list.length > 0) ?
                                <TableRow
                                    hover
                                    role='checkbox'
                                    tabIndex={-1}
                                    className='borderBottom-set'
                                >
                                    <TableCell
                                        size="small"
                                        style={{
                                            // textAlign: 'center',
                                            fontWeight: 'bold',
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {`${i18n.t('table_component_text_total')}   `}
                                    </TableCell>
                                    {
                                        (summationArray || []).map((sumData, sumIndex) => {
                                            return (
                                                <TableCell
                                                    key={sumIndex}
                                                    size="small"
                                                    style={{
                                                        // textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        fontStyle: 'normal',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {sumData}
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                                : null
                        }


                    </TableBody>
                </Table>
            </TableContainer>
            {/* 分页 */}
            {
                isPagination && list.length > 0 && (
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        labelRowsPerPage={i18n.t('general_row_each_page')}
                        component='div'
                        count={allCount}
                        rowsPerPage={curRowsPerPage}
                        page={page}
                        onPageChange={(e, page) => handleSetPage(e, page)}
                        onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSetRowsPerPage(event)}
                        // 自定义的页脚分页组件 isUseCustomPagination变量控制是否启用
                        ActionsComponent={isUseCustomPagination ? TablePaginationActions : undefined}
                    />
                )
            }
            {/* 是否批量删除所选择项目 */}
            <DeleteDialogComponent
                {...{
                    title: i18n.t('general_text_tips'),
                    open: deleteAllDialog,
                    onCancel: () => setDeleteAllDialog(false),
                    onConfirm: () => handleConfirmDelete(1),
                    onClose: () => setDeleteAllDialog(false),
                }}
            />
        </div >
    );
}

/**
 * 列表每行的功能按钮组
 */
export function TableRowFeatures(props: { buttons: ButtonComponentType[] }) {
    return (
        <div className='table-row-features'>
            {props?.buttons?.map((item, index) => {
                return (
                    <ButtonComponent
                        key={index}
                        variant={'contained'}
                        color={'primary'}
                        buttonStyle={{ margin: '0 0.5rem' }}
                        {...item}
                    >
                        {item.children}
                    </ButtonComponent>
                )
            })}
        </div>
    )
}


export type TableComponentProps = {
    // 列表项
    headCellList: Array<HeadCell>;
    // 列表数据
    list: Array<Data>;
    // 是否显示复选框 0 不显示 1显示  2 显示但是不显示删除提示框
    showChecked: number;
    // 是否分页
    isPagination?: boolean;
    // 总条数
    allCount?: number;
    // $ 当前页  description: 暂时改用组件内部的变量
    currentPage?: number;
    // $ 一页显示的条数 description: 暂时改用组件内部的变量
    rowsPerPage?: number;
    // 分页列表  一页 20 50 100 自定义
    rowsPerPageOptions?: Array<number>;
    // 额外的class
    extraClass?: string;
    // $ 换页  description: 暂时改用组件内部的函数
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleChangePage?: (event: any, page: number) => void;
    // $ 换一页显示的条数  description: 暂时改用组件内部的函数
    handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    // 排序方法
    handleSort?: (headCell: HeadCell) => void;
    // 复选按钮触发事件
    handleCheck?: (selected: Data[]) => void;
    // 删除复选框选中项
    handleDeleteAll?: (selected: Data[]) => void | Promise<void>;
    // 是否显示加载框 0不开启
    isTableLoading?: boolean;
    // 是否为粘性
    stickyHeader?: boolean;
    stickyHeaderItemClass?: string;
    // 单选或者多选，默认多选
    selectType?: 'RADIO' | 'CHECK';
    // $ 过滤复选框选中的数据
    filterCheckData?: (selected: Data[]) => Data[];
    // 渲染表头工具栏
    renderToolBar?: () => React.ReactNode;
    // 作比较唯一表示  id \  _id \ item_id (后端唯一属性值，key不一致，因此可用传递唯一标识key ， 默认为_id)
    compareSymbol?: string;
    // 初始化复选框勾选数据
    originSelect?: Data[];
    // list长度改变是否同步selected  true同步 (删除，跟换页记录selected状态)
    synchronize?: boolean;
    // 是否用于行可以展示折叠的表格
    isUseFold?: boolean;
    // 操作集团下的对应的折叠组件  集团id区分
    /**
     * 操作集团下的对应的折叠组件
     * @param _id 操作的对应集团id
     * @param activeIndex 当前点击的索引
     * */
    operateCollapseComponent?: {
        _id: string;
        activeIndex: number;
    };
    // 渲染额外的折叠组件
    renderExtraCollapseComponent?: (row: Data, index: number) => React.ReactNode;
    // 是否启用自定页脚分页组件  针对数据量大的表格  启用可以跳转表格数据的末尾
    isUseCustomPagination?: boolean;
    // 是否有折叠组件
    hasExpand?: boolean;
    // $ 操作切换页码、每页数量后返回当前页码、每页数量
    changePageCallback?: (params: { page: number; curRowsPerPage: number }) => void;
    // 启用表格计算合计功能
    isActivateSummation?: boolean;
    // 序号
    isShowSerialNum?: boolean;
    // 合计功能传入数据
    summationArray?: (number | string)[];
    // 后端分页 还是前端分页
    isCurrentPageData?: boolean;
    // 当前页码
    page?: number;
}