import * as React from 'react';
import { Routes, Route, useLocation, Navigate, HashRouter } from 'react-router-dom';
import Layout from '../layout';
import LoginPage from '../pages/login-page';
import { asyncRoutes } from './router.config';
import RegisterPage from '../pages/login-page/register';
import LSM from '../modules/cache/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../modules/cache/local-storage.constant';


const AppRouter = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/" element={(<LayoutBox><Layout /></LayoutBox>)} >
                    {
                        asyncRoutes.map((item) => (
                            <Route
                                path={item.path}
                                key={item.id}
                                element={
                                    <RequireAuth accountType={item.auth}>
                                        {item.component}
                                    </RequireAuth>
                                }
                            />
                        ))
                    }
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes >
        </HashRouter >
    );
}


function RequireAuth({ children, accountType }: { children: JSX.Element, accountType: string[] }) {
    // 当前用户权限
    const account = LSM.get(LOCAL_STORAGE_KEYS.ACCOUNT)
    const location = useLocation();
    if (!accountType.includes(account.type)) {
        // 无权限页面
        return <Navigate to="/no-auth" state={{ from: location }} replace />;
    }
    return children;
}

/**
 * 处理路径为/的情况
 * @param param0 
 * @returns 
 */
function LayoutBox({ children }: { children: JSX.Element }) {
    // 是否登录
    const auth = LSM.get(LOCAL_STORAGE_KEYS.TOKEN)
    // 当前用户权限
    const account = LSM.get(LOCAL_STORAGE_KEYS.ACCOUNT) || {}
    const location = useLocation();
    if (!auth) {
        // 未登录先登录
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    if (location.pathname === '/') {
        if (account.type === 'ADMIN') {
            // 默认到company页面
            return <Navigate to="/company" state={{ from: location }} replace />;
        } else if (account.type === 'STAFF') {
            // 默认到账号页面
            return <Navigate to="/user" state={{ from: location }} replace />;
        } else {
            // 未登录先登录
            return <Navigate to="/login" state={{ from: location }} replace />;
        }
    }
    return children;
}



export default AppRouter