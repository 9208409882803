import { Box, Button, CircularProgress, SxProps, Theme, Tooltip } from '@mui/material';
import React, { useState, useTransition } from 'react';
import { makeStyles, createStyles, } from '@mui/styles';
import { Add, Delete, Refresh, Save, CloseSharp, RateReview, ArrowBackIosNew } from '@mui/icons-material';
import i18n from '../../i18n';

export enum LoadingOption {
    None, Avoid, AvoidNoLoading
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            minWidth: '36px !important',
            minHeight: '36px !important',
            height: '36px !important',
            width: '36px !important',
            padding: '0px !important',
        },
        secondRoot: {
            minHeight: '36px !important',
            height: '36px !important',
        }
    })
)
/**
 * 正方形按钮组件(icon)
 * - 默认样式，宽高
 */
export default function ButtonComponent(
    props: ButtonComponentType
): JSX.Element {
    useTransition();
    const { size, color, children, variant = 'contained', titleText, action, handleClick,
        disabled = false, extraButtonStyle = '', forbidConfirm = LoadingOption.None, buttonStyle, sx, loadingStyle,
        space = true, iconBtn = true
    } = props
    const classes = useStyles();
    const [isLoading, setLoad] = useState(false);
    const actionIcon: Record<string, IconObj> = {
        'ADD': { com: <Add />, title: i18n.t('general_text_new'), },
        'DELETE': { com: <Delete />, title: i18n.t('general_text_delete'), },
        'REFRESH': { com: <Refresh />, title: i18n.t('general_text_refresh'), },
        'SAVE': { com: <Save />, title: i18n.t('general_text_save'), },
        'CLOSE': { com: <CloseSharp />, title: i18n.t('general_text_delete'), },
        'VIEW': { com: <RateReview />, title: i18n.t('general_text_details'), },
        'BACK': { com: <ArrowBackIosNew />, title: i18n.t('google_text_return'), }
    };
    const getActionIcon = (action = '', children?: JSX.Element | string): IconObj => {
        return {
            com: actionIcon[action]?.com || (typeof children === 'string' ? <>{children}</> : children || <></>),
            title: actionIcon[action]?.title || titleText || '',
        };
    };
    const ActionIcon = () => {
        const { com, title } = getActionIcon(action, children);
        return { com, title }
    }


    // *********************
    // View
    // *********************
    return (
        <Tooltip title={ActionIcon().title} placement='top' >
            <span style={space ? { padding: '8px', marginLeft: '-8px' } : {}}>
                <Button
                    variant={variant}
                    size={size}
                    color={color}
                    disabled={disabled || forbidConfirm !== 0 || isLoading ? true : false}
                    className={`${typeof children === 'string' || !iconBtn ? classes.secondRoot : classes.root} ${extraButtonStyle} `}
                    onClick={async (e) => {
                        e.stopPropagation();
                        setLoad(true)
                        await handleClick?.(e);
                        setLoad(false);
                    }}
                    style={{ ...((disabled || forbidConfirm !== 0 || isLoading ? true : false) ? { borderColor: ' #999' } : {}), ...buttonStyle }}
                    sx={sx}
                >
                    {
                        isLoading &&
                        <Box style={{
                            backgroundColor: '#fff3',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1,
                            borderRadius: 'inherit',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <CircularProgress
                                color='primary'
                                size={'20px'}
                                style={{
                                    ...loadingStyle
                                }} />
                        </Box>
                    }
                    {ActionIcon().com}
                </Button >
            </span>
        </Tooltip >
    )
}

type IconObj = {
    com: JSX.Element;
    title: string;
};

export type ButtonComponentType = {
    // 按钮尺寸
    size?: 'large' | 'medium' | 'small';
    // 按钮颜色
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    // 按钮类型
    variant?: 'contained' | 'outlined' | 'text';
    // 按钮图标或文字  自定义
    children?: JSX.Element | string;
    // 点击事件
    handleClick?: (e: any) => void | Promise<void>;
    // 是否禁用
    disabled?: boolean;
    // 按钮样式
    extraButtonStyle?: string;
    // 按钮文字样式
    extraTitleStyle?: string;
    // 按钮操作类型
    action?: 'ADD' | 'DELETE' | 'REFRESH' | 'SAVE' | 'CLOSE' | 'VIEW' | 'BACK';
    // 按钮标题提示文字
    titleText?: string;
    // 是否禁止掉确定按钮，主要这里开启loading，确定交互 0不禁止 1 禁止 2禁止不开启loading ...
    forbidConfirm?: LoadingOption;
    //
    buttonStyle?: React.CSSProperties

    async?: boolean;

    sx?: SxProps<Theme>
    loadingStyle?: React.CSSProperties,
    // 间隔
    space?: boolean;
    // 图标按钮
    iconBtn?: boolean;
}

