import React, { useRef, useState } from 'react';
import { Box, Button, ButtonGroup, IconButton } from '@mui/material';
// TODO 用公共组件
// import DialogComponent from '../dialog/dialog.component';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { RotateLeft, RotateRight, SwapVert, SwapHoriz } from '@mui/icons-material';
import { makeStyles, createStyles } from '@mui/styles';
import i18n from '../../i18n';
import DialogComponent from '../../components/dialog/dialog.component';
import { compressAccurately } from 'image-conversion';
import WaitButton from '../button/wait-button.component';


const useStyles = makeStyles(
    createStyles({
        toolButtons: {
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            borderRadius: 8,
            padding: '2px 6px',
            '& .MuiIconButton-root': {
                color: '#fff'
            }
        }
    })
)

export default function CropperDialog(props: {
    open: boolean;
    onClose: () => void;
    confirm: (avatarUrl: string, file: Blob) => void;
    imgSrc: string;
}) {
    const { open, onClose, confirm, imgSrc } = props;
    const [aspectRatio, setAspectRatio] = useState(NaN);
    const [rotate, setRotate] = useState(0);
    const [cropper, setCropper] = useState<Cropper>();
    const cropperRef = useRef<HTMLImageElement>(null);
    // 纵横比数组
    const aspectRatioList = [
        {
            value: 1 / 1,
            label: '1 : 1',
        },
        {
            value: 16 / 9,
            label: '16 : 9',
        },
        {
            value: 16 / 10,
            label: '16 : 10',
        },
        {
            value: 3 / 2,
            label: '3 : 2',
        },
        {
            value: 4 / 3,
            label: '4 : 3',
        },
        {
            value: 12 / 27,
            label: '12 : 27',
        }

    ]
    const classes = useStyles();
    //获取base64图片大小，返回kb数字
    const showSize = (base64url: string, type: string) => {
        //把头部去掉
        let str = base64url.replace(`data:${type};base64,`, '');
        // 找到等号，把等号也去掉
        const equalIndex = str.indexOf('=');
        if (str.indexOf('=') > 0) {
            str = str.substring(0, equalIndex);
        }
        // 原来的字符流大小，单位为字节
        const strLength = str.length;
        // 计算后得到的文件流大小，单位为字节
        const fileLength = strLength - (strLength / 8) * 2;
        // 由字节转换为kb
        let size = '';
        size = (fileLength / 1024).toFixed(2);
        const sizeStr = size + ''; //转成字符串
        const index = sizeStr.indexOf('.'); //获取小数点处的索引
        const dou = sizeStr.substr(index + 1, 2) //获取小数点后两位的值
        if (dou === '00') { //判断后两位是否为00，如果是则删除00
            return Number(sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2))
        }
        return Number(size);
    }

    return (
        <DialogComponent
            maxWidth='sm'
            open={open}
            onClose={() => onClose()}
            defaultHeaderProps={{
                title: i18n.t('general_text_change_image'),
                onClose: () => { onClose() }
            }}
            footer={
                <div>
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => {
                            onClose();
                        }}
                    >{i18n.t('general_button_cancel')}</Button>
                    <Box marginLeft={4} display="inline-block">
                        <WaitButton
                            color='primary'
                            variant='contained'
                            onClick={async () => {
                                await new Promise<void>((resolve) => {
                                    const type = imgSrc.split(';base64,')[0].split(':')[1];
                                    // // 除了png格式，cropper会放大图片体积
                                    const avatarUrl = cropper?.getCroppedCanvas().toDataURL(type, 1) || '';
                                    const imageSize = showSize(avatarUrl, type);
                                    const imgData = cropper?.getData();

                                    if (imgData!.height > 2000 && imageSize > 300) {
                                        // 尺寸太大，则缩小一半
                                        cropper?.scale(0.5, 0.5);
                                    }

                                    cropper?.getCroppedCanvas().toBlob(async (blob) => {
                                        if (!blob)
                                            return;
                                        const size = blob.size / 1024;

                                        if (size > 300) {
                                            // 通过第三方工具压缩
                                            await compressAccurately(blob, 300).then((newBlob: any) => {
                                                confirm(avatarUrl, newBlob);
                                                resolve();
                                            });
                                        } else {
                                            confirm(avatarUrl, blob);
                                            resolve();
                                        }
                                    }, type, 1);
                                })
                            }}
                            titleText={''}
                        >
                            {i18n.t('general_button_confirm')}
                        </WaitButton>
                    </Box>
                </div>
            }
        >
            <Box width='100%' position='relative'>
                <Cropper
                    src={imgSrc}
                    style={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center' }}
                    initialAspectRatio={aspectRatio}
                    guides={true}
                    ref={cropperRef as any}
                    background={false}
                    // onInitialized={(instance) => {
                    //     setCropper(instance);
                    // }}
                    crop={() => {
                        const imageElement: any = cropperRef?.current;
                        const cropper: any = imageElement?.cropper;
                        setCropper(cropper);
                    }}
                    viewMode={1}
                />
                <Box className={classes.toolButtons}>
                    <IconButton
                        edge="start"
                        size="small"
                        onClick={() => {
                            setRotate(rotate - 45);
                            cropper?.rotateTo(rotate - 45)
                        }}>
                        <RotateLeft />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => {
                            setRotate(rotate + 45);
                            cropper?.rotateTo(rotate + 45)
                        }}>
                        <RotateRight />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => {
                            cropper?.scaleY(-cropper.getData().scaleY || -1);
                        }}>
                        <SwapVert />
                    </IconButton>
                    <IconButton
                        edge="end"
                        size="small"
                        onClick={() => {
                            cropper?.scaleX(-cropper.getData().scaleX || -1);
                        }}>
                        <SwapHoriz />
                    </IconButton>
                </Box>

            </Box>

            <Box display='flex' justifyContent='center' marginTop={4}>
                <ButtonGroup
                    color="primary"
                    aria-label="aspectRatio button group"
                >
                    {
                        aspectRatioList.map((item) => (
                            <Button
                                variant={item.value === aspectRatio ? 'contained' : 'outlined'}
                                key={item.value}
                                onClick={() => {
                                    if (item.value !== aspectRatio) {
                                        setAspectRatio(item.value);
                                        cropper?.setAspectRatio(item.value)
                                    }
                                    else {
                                        setAspectRatio(NaN);
                                        cropper?.setAspectRatio(NaN)
                                    }
                                }}>
                                {item.label}
                            </Button>
                        ))
                    }
                </ButtonGroup>
            </Box>
        </DialogComponent>
    )
}