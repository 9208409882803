import { Box, Typography } from '@mui/material';
import CardFrom, { CardFormConfig } from '../../components/card/card-from';
import DialogComponent from '../../components/dialog/dialog.component';
import TableComponent, { HeadCell } from '../../components/table/table.component';
import { Account } from '../../models/abstract/account.entity';
import moment from 'moment';
import { ContentCopy, Edit, Save, Refresh } from '@mui/icons-material';
import ButtonComponent from '../../components/button/button.component';
import GlobalNotification from '../../components/common/snackbar';
import copy from 'copy-to-clipboard';
import CardView from '../../components/card/card-view.component';
import { accountState } from './account-state';
import CommonInput from '../../components/form/common-input.component';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ConditionType, SearchKeyInput, SearchKeyInputItems } from '../../components/form/search-key-input.component';
import i18n from '../../i18n';
import BaseInput from '../../components/form/base-input.component';
import { CustomerLoading } from '../../components/loading/loading';


export default observer(function AccountDetail(props: Readonly<{
	isOpen: boolean;
	onClose: () => void;
	detail: Account;
}>) {
	const { t } = useTranslation();
	// 员工账号
	const staffInfo: CardFormConfig[] = [
		{
			key: 'baseInfo',
			title: t('ground_staff_component_text_basic_information'),
			rowSpacing: 6,
			columnSpacing: { md: 4, xs: 4 },
			children: [
				{
					key: 'avatar',
					comType: 'AVATAR',
					md: 12,
					isDisabledUpload: true
				},
				{
					key: 'name',
					label: t('general_text_name'),
					regularType: 'inputLimit',
					comType: 'INPUT',
					inputType: 'INPUT',
					xs: 12,
					md: 6,
					disabledInput: true,
				},
				{
					key: 'birthday',
					label: t('person_page_text_birthday'),
					comType: 'INPUT',
					inputType: 'DATE',
					handleChangePicker: () => { },
					xs: 12,
					md: 6,
					maxDate: new Date(),
					disabledInput: true,
				},
			]
		},
		{
			key: 'contact',
			title: t('restaurant_info_page_text_contact'),
			rowSpacing: 6,
			columnSpacing: { md: 4, xs: 4 },
			children: [
				{
					key: 'phone_prefix',
					label: t('general_text_country_code'),
					comType: 'INPUT',
					inputType: 'COUNTRY_SELECT',
					xs: 12,
					md: 4,
					disabledInput: true,
				},
				{
					key: 'phone',
					label: t('general_text_phone'),
					comType: 'INPUT',
					inputType: 'INPUT',
					xs: 12,
					md: 8,
					disabledInput: true,
				},
				{
					key: 'email',
					label: t('general_text_email'),
					comType: 'INPUT',
					inputType: 'INPUT',
					xs: 12,
					md: 12,
					disabledInput: true,
				}
			]
		}
	];

	// 密钥表格
	const secretKeyTableHeadCell: HeadCell[] = [
		{
			id: 'name',
			label: t('general_text_name'),
			renderFunction: () => {
				return (
					<Typography sx={{ wordBreak: 'keep-all' }} >
						{t('restaurant_extensions_page_text_key')}
					</Typography>)
			}
		},
		{
			id: 'key',
			label: t('restaurant_extensions_page_text_key'),
			renderFunction: (row) => {
				return (
					<Box className='FC' style={{ wordBreak: 'break-all', minWidth: '200px' }} >
						<ButtonComponent
							variant='text'
							handleClick={() => {
								const res = copy(row?.key);
								if (res) GlobalNotification.success(t('restaurant_page_input_tip_message_copy_successfully'))
							}}>
							<ContentCopy />
						</ButtonComponent>
						<ButtonComponent
							variant='text'
							handleClick={async () => {
								const res = await accountState.refreshKey()
								if (res === 'success') {
									GlobalNotification.success(t('general_text_refresh_success'))
								}
							}}>
							<Refresh />
						</ButtonComponent>
						{row?.key}
					</Box>
				)
			}
		},
	];

	// 电子发票
	const invoiceTableHeadCell: HeadCell[] = [
		{
			id: 'create_time',
			label: t('restaurant_extensions_page_text_create_time'),
			renderFunction: (row) => {
				return (
					<Typography sx={{ wordBreak: 'break-all' }} >
						{moment(row?.create_time).format('YYYY-MM-DD HH:mm')}
					</Typography>
				)
			}
		},
		{
			id: 'vat',
			label: t('partner_page_text_vat')
		},
		{
			id: 'type',
			label: t('partner_page_text_type')
		},
		{
			id: 'status',
			label: t('general_text_state'),
			renderFunction: (row) => {
				return (
					<Typography sx={{ wordBreak: 'break-all', color:row?.status ? 'green' : 'red' }} >
						{row?.status ? t('general_text_success') : t('general_text_failed')}
					</Typography>
				)
			}
		}
	];

	// 查找表格条件下拉框 静态数据
	const filterList: SearchKeyInputItems[] = [
		{
			value: 'vat',
			label: i18n.t('vat'),
			type: 'TEXT'
		}
	];

	const renderDetail = () => {
		return (
			<div>
				{/* loading 加载框显示 */}
				{accountState.loading && <CustomerLoading />}
				<CardFrom
					config={staffInfo}
					data={props.detail}
				/>
				<CardView
					{...{
						className: '',
						title: t('login_page_text_password'),
						style: { marginTop: '20px', width: '400px' },
						actionRender: <Box>
							<ButtonComponent
								variant='outlined'
								handleClick={() => {
									accountState.editDisabledPassword(!accountState.disabledPassword)
								}}>
								{
									accountState.disabledPassword ? <Edit /> : 
										<Save 
											onClick={async () => { 
												const res = await accountState.changePassword();
												if (res === 'success') {
													GlobalNotification.success(t('global_text_operate_success'))
												}
											}} 
										/>
								}
							</ButtonComponent>
						</Box>,
						contentRender:  <BaseInput
							className='code-input'
							type='phone'
							InputLabelProps={{
								shrink: true
							}}
							size={'small'}
							value={accountState.password}
							disabled={accountState.disabledPassword}
							variant="outlined"
							placeholder={'enter password...'}
							label={i18n.t('login_page_text_password')}
							onChange={(event) => {
								accountState.onChange(event.target.value);
							}}
							fullWidth
						/>
					}}
				/>
				<CardView
					{...{
						className: '',
						title: t('setting_page_text_authorization_time'),
						style: { marginTop: '20px', width: '400px' },
						actionRender: <Box>
							<ButtonComponent
								variant='outlined'
								handleClick={() => {
									accountState.editDisabled(!accountState.disabledDate)
								}}>
								{
									accountState.disabledDate ? <Edit /> : <Save onClick={async () => { await accountState.auditAccountDate() }} />
								}
							</ButtonComponent>
						</Box>,
						contentRender: <CommonInput
							inputType={'DATE_TIME_RANGE'}
							label={t('setting_page_text_authorization_time')}
							disabled={accountState.disabledDate}
							rangeHandleChangePicker={(dates) => {
								accountState.setDate(dates)
							}}
							rangeValue={[accountState.start_date, accountState.end_date]}
						/>
					}}
				/>
				{/* 密钥表格 */}
				<CardView
					{...{
						className: '',
						title: t('restaurant_extensions_page_text_key'),
						style: { marginTop: '20px' },
						contentRender: <TableComponent
							{...{
								showChecked: 0,
								list: [{ key: props.detail.key }],
								headCellList: secretKeyTableHeadCell || [],
							}}
						/>
					}}
				/>
				{/* 获取电子发票表格 */}
				<CardView
					{...{
						className: '',
						style: { marginTop: '20px' },
						title: t('partner_page_text_record'),
						contentRender: 
						<div>
							<SearchKeyInput
                                // ref={''}
                                items={filterList}
                                conditions={accountState.qRecordConditions}
                                onSearch={async (conditions: ConditionType[]) => {
                                    await accountState.getAccountSearchData(conditions);
                                }}
                                showConditionsBox
                            />
							<TableComponent
								{...{
									showChecked: 0,
									list: accountState.use_details || [],
									headCellList: invoiceTableHeadCell || [],
									isUseCustomPagination: true,
									isPagination: true,
									allCount: accountState.use_details.length || 0,
									isTableLoading: accountState.isBillTableLoading,
								}}
							/>
						</div>
						
					}}
				/>
			</div>
		)
	}

	return (
		<DialogComponent
			maxWidth='md'
			heightSize='medium'
			open={props.isOpen}
			defaultHeaderProps={{
				title: t('operation_record_operation_object_details'),
			}}
			onClose={props.onClose}

		>
			{renderDetail()}
		</DialogComponent>
	)
})