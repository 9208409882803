import React, { useState } from 'react';
import { Box, Button, Card, InputProps, Typography } from '@mui/material';
import './index.style.scss';
import { pic_logo } from '../../static/images';
import 'react-phone-input-2/lib/style.css';
import { CustomerLoading } from '../../components/loading/loading';
import { useNavigate } from 'react-router-dom';
import GlobalNotification from '../../components/common/snackbar';
import apiManage from '../../request';
import LSM from '../../modules/cache/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../modules/cache/local-storage.constant';
import { mainStore } from '../../stores';
import { useTranslation } from 'react-i18next';
import { CardFormChildren, CardFormChildrenConfig, CardFormConfig, checkCardFromRule } from '../../components/card/card-from';
import { User } from '../../models';

export default function LoginPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // 是否开启校验
    const [isValidate, setIsValidate] = useState(false)
    // 表单数据
    const [data, setData] = React.useState<DataProps>({
        phone_prefix: '39'
    })
    const [isLoading, setIsLoading] = React.useState(false);

    /**
     * 登录
     * @param cardFormConfig 
     * @returns 
     */
    const onLogin = async (cardFormConfig: CardFormConfig[]) => {
        setIsValidate(true)
        // 表单校验
        const errorList = checkCardFromRule({
            config: cardFormConfig,
            data: data
        });
        if (errorList.length) {
            return;
        }
        setIsLoading(true);
        const result = await apiManage.post_login_account({
            phone: data.phone ?? '',
            phone_prefix: data.phone_prefix ?? '',
            password: data.password ?? ''
        });
        if (!result.error) {
            GlobalNotification.success(t('login_page_text_login_suc'));
            // 获取的token记录
            LSM.set({ path: LOCAL_STORAGE_KEYS.TOKEN, value: result });
            // 解析Token
            const metadata = await apiManage.get_auth_token_metadata();
            if (!metadata.error && metadata._id) {
                // 将用户信息存入缓存
                LSM.set({ path: LOCAL_STORAGE_KEYS.ACCOUNT, value: metadata });
                mainStore.updateAccount(metadata)
                if (metadata.type === 'ADMIN') {
                    navigate('/company');
                    LSM.set({ path: LOCAL_STORAGE_KEYS.TITLE, value: 'compony_page_text_compony_management' });
                } else {
                    navigate('/user');
                    LSM.set({ path: LOCAL_STORAGE_KEYS.TITLE, value: 'main_page_text_person_page' });
                }
            }
            setIsLoading(false);
        } else {
            // 错误提示
            const message = result?.result?.message ?? '';
            if (message.includes('Verification Failed.')) {
                GlobalNotification.error(t('login_page_text_login_user_identification_error'));
            } else {
                GlobalNotification.error(t('login_page_text_login_failed'));
            }
            return setIsLoading(false);
        }
    };

    /**
     * 表单回调
     * @param key 
     * @param value 
     */
    const onChange = (key: DataKeys, value: string) => {
        data[key] = value
        setData({
            ...data
        })
    }

    // 表单
    const formData: CardFormChildrenConfig[] = [
        {
            comType: 'INPUT',
            key: 'phone' as const,
            label: t('general_text_account'),
            inputType: 'PHONE' as const,
            required: true,
            regularType: 'phone',
            country: data.phone_prefix,
            handleChangeCountry: (value: string) => {
                onChange('phone_prefix', value)
            },
        },
        {
            comType: 'INPUT',
            key: 'password' as const,
            label: t('login_page_text_password'),
            regularType: 'password',
            required: true,
            inputType: 'PASSWORD' as const,
        }
    ]

    // 表单设置
    const cardConfig: CardFormConfig = {
        key: 'baseInfo',
        title: '',
        rowSpacing: 2,
        columnSpacing: { md: 2, xs: 2 },
        xs: 12,
        children: formData.map((item) => ({
            xs: 12,
            inputIsEdited: false,
            InputProps: {
                size: 'medium'
            } as InputProps,
            onChange: (event) => {
                const value = event.target.value;
                onChange(item.key as DataKeys, value)
            },
            isEmpty: isValidate,
            ...item
        }))
    }
    return (
        <Box className='loginPage' sx={(theme) => ({
            background: theme.palette.mode === 'dark' ? '#25293c' : '#fff'
        })}>
            <Box className={`controll-panel `}>
                <Box className='controll-panel-content' >
                    <Card style={{
                        padding: '1.5rem',
                        width: '100%'
                    }}>
                        <Box className='title-box'>
                            <Box className='title-box-1'>
                                <img width={65} src={pic_logo} alt="" />
                                <Typography variant='body1' className='title_1'>
                                    DataBubble
                                </Typography>
                            </Box>
                            <Typography variant='body1' className='title_2'>
                                {t('login_page_text_login_welcome')}
                            </Typography>
                            <Typography variant='body1' className='title_3'>
                                {t('login_page_text_login_welcome_desc')}
                            </Typography>
                        </Box>
                        <CardFormChildren
                            form={cardConfig}
                            data={data}
                        />
                        <Button
                            type='submit'
                            className='login-button'
                            variant='contained'
                            color={'primary'}
                            disabled={isLoading}
                            onClick={async () => {
                                await onLogin([cardConfig]);
                            }}
                            fullWidth
                        >
                            {t('login_page_button_login')}
                            {isLoading &&
                                <CustomerLoading />}
                        </Button>

                        <Box className='tail-box'>
                            <Typography variant='body1' className='title_4'>
                                {t('login_page_text_no_account')}
                            </Typography>
                            <Typography variant='body1' className='title_5' onClick={() => {
                                navigate('/register')
                            }}>
                                {t('login_page_text_create_account')}
                            </Typography>
                        </Box>
                    </Card>
                </Box>
            </Box>
        </Box>
    );


}
// 表单数据
type DataProps = Pick<User, 'phone' | 'phone_prefix' | 'password'>
type DataKeys = 'phone' | 'phone_prefix' | 'password'