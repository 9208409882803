import React from 'react';
import { Edit, Save } from '@mui/icons-material';
import { Box } from '@mui/system';
import ButtonComponent from '../../components/button/button.component';
import CardView from '../../components/card/card-view.component';
import './index.scss';
import { TextField, Typography } from '@mui/material';
import { IOSSwitch } from '../../components/form/switch.component';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '../../components/dragComponent/strict-mode-droppable';
import { CustomerLoading } from '../../components/loading/loading';
import { observer } from 'mobx-react-lite';
import { settingState } from './setting-state';
import { useTranslation } from 'react-i18next';

export default observer(function SettingPage() {
	const { ITInvoice, ESInvoice } = settingState
	const { t } = useTranslation();
	React.useEffect(() => {
		settingState.init();
		return () => { }
	}, [])

	const renderContent = () => {
		return <Box>
			{/* <Typography>
				{"本地电子发票更新周期:"}
			</Typography> */}
			<TextField
				className='account-input'
				type='number'
				InputLabelProps={{
					shrink: true
				}}
				value={settingState.month || ''}
				variant="outlined"
				placeholder={''}
				label={`${t('setting_page_text_update_cycle')} (${t('general_text_month')})`}
				onChange={event => {
					if (event.target.value) {
						if (Number(event.target.value) > 0) {
							settingState.setMonth(Number(event.target.value.replace(/\.\d+/g, '')))
						}
					} else {
						settingState.setMonth(undefined)
					}
				}}
				disabled={!settingState.isEditDate}
				margin="normal"
				fullWidth
			/>
		</Box>
	}

	const renderGybContent = () => {
		return <Box>
			<TextField
				className='account-input'
				type='string'
				InputLabelProps={{
					shrink: true
				}}
				value={settingState.gyb_nickname}
				variant="outlined"
				placeholder={''}
				label={`${t('账号')}`}
				onChange={event => {
					settingState.setGybName(event.target.value)
				}}
				disabled={!settingState.isEditGyb}
				margin="normal"
				fullWidth
			/>
			<TextField
				className='account-input'
				type='string'
				InputLabelProps={{
					shrink: true
				}}
				value={settingState.isEditGyb ? settingState.gyb_password : `${t('restaurant_extensions_page_text_key')}`}
				variant="outlined"
				placeholder={''}
				label={`${t('restaurant_extensions_page_text_key')}`}
				onChange={event => {
					settingState.setGybPassword(event.target.value)
				}}
				disabled={!settingState.isEditGyb}
				margin="normal"
				fullWidth
			/>
		</Box>
	}

	const renderGybButton = () => {
		return <Box>
			<ButtonComponent
				variant='outlined'
				handleClick={() => {
					settingState.setIsEditGYB(!settingState.isEditGyb);
				}}>
				{
					settingState.isEditGyb ? <Save onClick={async () => {
						await settingState.editSetting({
							username: settingState.gyb_nickname,
							password: settingState.gyb_password
						});
					}} /> : <Edit />
				}
			</ButtonComponent>
		</Box>
	}

	const renderCervedContent = () => {
		return <Box>
			<TextField
				className='account-input'
				type='string'
				InputLabelProps={{
					shrink: true
				}}
				value={settingState.cerved_key}
				variant="outlined"
				placeholder={''}
				label={`${t('key')}`}
				onChange={event => {
					settingState.setCervedKey(event.target.value)
				}}
				disabled={!settingState.isEditKey}
				margin="normal"
				fullWidth
			/>
		</Box>
	}

	const renderCervedButton = () => {
		return <Box>
			<ButtonComponent
				variant='outlined'
				handleClick={() => {
					settingState.setIsEditKey(!settingState.isEditKey);
				}}>
				{
					settingState.isEditKey ? <Save onClick={async () => {
						await settingState.editSetting({
							cerved_key: settingState.cerved_key
						});
					}} /> : <Edit />
				}
			</ButtonComponent>
		</Box>
	}

	const renderButton = () => {
		return <Box>
			<ButtonComponent
				variant='outlined'
				handleClick={() => {
					settingState.setIsEditDate(!settingState.isEditDate);
				}}>
				{
					settingState.isEditDate ? <Save onClick={async () => {
						await settingState.editSetting({
							renewal_cycle: settingState.month || 6
						})
					}} /> : <Edit />
				}
			</ButtonComponent>
		</Box>
	}

	const renderITInvoice = () => {
		return (
			<DragDropContext onDragEnd={(result) => settingState.ITOnDragEnd(result)}>
				<StrictModeDroppable droppableId="column-0">
					{(provided) => (
						<Box ref={provided.innerRef} {...provided.droppableProps}>
							{ITInvoice.map((item, index) => (
								<Draggable isDragDisabled={!settingState.isEditITInvoice} key={item.name} draggableId={item.name} index={index}>
									{(provided) => (
										<Box
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="item_invoice"
										>
											<Typography>{item.name}</Typography>
											<IOSSwitch
												sx={{ m: 1 }}
												disabled={!settingState.isEditITInvoice}
												checked={item.status}
												onChange={() => { settingState.onChange('IT', item.name, item.status) }}
											/>
										</Box>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</Box>
					)}
				</StrictModeDroppable>
			</DragDropContext>

		)

	}

	const renderESButton = () => {
		return <Box>
			<ButtonComponent
				variant='outlined'
				handleClick={() => {
					settingState.setIsEditESInvoice(!settingState.isEditESInvoice);
				}}>
				{
					settingState.isEditESInvoice ? <Save onClick={async () => {
						await settingState.editSetting({
							priority: {
								IT: settingState.ITInvoice,
								ES: settingState.ESInvoice,
							}
						})
					}} /> : <Edit />
				}
			</ButtonComponent>
		</Box>
	}

	const renderITButton = () => {
		return <Box>
			<ButtonComponent
				variant='outlined'
				handleClick={() => {
					settingState.setIsEditITInvoice(!settingState.isEditITInvoice);

				}}>
				{
					settingState.isEditITInvoice ? <Save onClick={async () => {
						await settingState.editSetting({
							priority: {
								IT: settingState.ITInvoice,
								ES: settingState.ESInvoice,
							}
						});
					}} /> : <Edit />
				}
			</ButtonComponent>
		</Box>
	}

	const renderESInvoice = () => {
		return (
			<DragDropContext onDragEnd={(result) => settingState.ESOnDragEnd(result)}>
				<StrictModeDroppable droppableId="column-0">
					{(provided) => (
						<Box ref={provided.innerRef} {...provided.droppableProps}>
							{ESInvoice.map((item, index) => (
								<Draggable isDragDisabled={!settingState.isEditESInvoice} key={item.name} draggableId={item.name} index={index}>
									{(provided) => (
										<Box
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="item_invoice"
										>
											<Typography>{item.name}</Typography>
											<IOSSwitch
												sx={{ m: 1 }}
												disabled={!settingState.isEditESInvoice}
												checked={item.status}
												onChange={() => { settingState.onChange('ES', item.name, item.status) }}
											/>
										</Box>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</Box>
					)}
				</StrictModeDroppable>
			</DragDropContext>
		)
	}

	const content = [
		{
			className: 'setting_update_cycle',
			title: t('setting_page_text_update_cycle'),
			actionRender: renderButton(),
			contentRender: renderContent()
		},
		{
			className: 'setting_update_gyb',
			title: `gyb ${t('general_text_account')}`,
			actionRender: renderGybButton(),
			contentRender: renderGybContent()
		},
		{
			className: 'setting_update_gyb',
			title: `cerved ${t('restaurant_extensions_page_text_key')}`,
			actionRender: renderCervedButton(),
			contentRender: renderCervedContent()
		},
		{
			className: 'setting_invoice_it',
			title: `${t('setting_page_text_third_party_platform_priority')} (IT)`,
			actionRender: renderITButton(),
			contentRender: renderITInvoice()
		},
		{
			className: 'setting_invoice_it',
			title: `${t('setting_page_text_third_party_platform_priority')} (ES)`,
			actionRender: renderESButton(),
			contentRender: renderESInvoice()
		}
	];

	return <Box className={'setting_box'}>
		{
			settingState.isLoading && <CustomerLoading />
		}
		{
			content.map((item, index) => {
				return <CardView
					key={index}
					{...{
						className: item.className,
						title: item.title,
						actionRender: item.actionRender,
						contentRender: item.contentRender,

					}}
				/>
			})
		}
	</Box>
})