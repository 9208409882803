import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router-dom';
import TopBarComponent from '../components/app-bar/top-bar.component';
import NavComponent from '../components/nav/nav.component';
import ScrollTop from '../components/scroll-top/scroll-top';
import LSM from '../modules/cache/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../modules/cache/local-storage.constant';

export default function Layout() {
    // 移动端抽屉开关
    const [mobileOpen, setMobileOpen] = React.useState(false);
    // pc端侧边栏宽度
    const [drawerWidth, setDrawerWidth] = React.useState(240);
    // 标题
    const [title, setTitle] = React.useState(LSM.get(LOCAL_STORAGE_KEYS.TITLE))

    /**
     * 移动端抽屉开关
     */
    const handleMobileDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    /**
     * pc端侧边栏宽度
     */
    const handleDrawerToggle = () => {
        setDrawerWidth(drawerWidth === 240 ? 80 : 240)
    }

    /**
     * 修改标题
     * @param value 
     */
    const handleChangeTitle = (value: string) => {
        setTitle(value)
        LSM.set({
            path: LOCAL_STORAGE_KEYS.TITLE,
            value: value
        })
    }


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <NavComponent
                drawerWidth={drawerWidth}
                mobileOpen={mobileOpen}
                handleMobileDrawerToggle={handleMobileDrawerToggle}
                handleDrawerToggle={handleDrawerToggle}
                handleChangeTitle={handleChangeTitle}
            />
            <Box
                component="main"
                sx={(theme) => ({
                    flexGrow: 1,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    minHeight: '100vh',
                    background: theme.palette.mode === 'light' ? '#f8f7fa' : '#25293c'
                })}
            >
                <TopBarComponent
                    title={title}
                    drawerWidth={drawerWidth}
                    handleMobileDrawerToggle={handleMobileDrawerToggle}
                    handleDrawerToggle={handleDrawerToggle}
                />
                <Box id="back-to-top-anchor" sx={{ height: '24px' }}></Box>
                <Box sx={{ padding: '0 24px 24px 24px' }}>
                    <Outlet />
                </Box>
                <ScrollTop />
            </Box>
        </Box>
    );
}
