import http, { HttpSuccessOrErrorType, ListRequestParams, ListResults, OkPacket } from '../http';
import { User } from '../../models';
import { Log } from '../../models/log.entity';


const userApi = {

    /**
     * 根据id获取用户信息
     * @param id 
     * @returns 
     */
    async get_user_info_id(id: string): Promise<HttpSuccessOrErrorType<User>> {
        return await http.get(`/user/${id}`)
    },

    /**
     * 根据id修改用户信息
     * @param id 
     * @returns 
     */
    async patch_user_info_id(id: string, params: User): Promise<HttpSuccessOrErrorType<OkPacket>> {
        return await http.patch(`/update/info/${id}`, params)
    },

    /**
     * 根据id修改用户密码
     * @param id 
     * @returns 
     */
    async patch_user_info_id_password(id: string, params: {
        currentPassword: string;
        newPassword: string;
    }): Promise<HttpSuccessOrErrorType<OkPacket>> {
        return await http.patch(`/update/info/${id}`, params)
    },


    /**
     * 修改用户头像
     * @param id 
     * @param file 
     * @returns 
     */
    async post_user_avatar_id(id: string, file: File): Promise<HttpSuccessOrErrorType<OkPacket>> {
        const formData = new FormData();
        formData.append('avatar', file);
        return await http.post(`/upload/avatar/${id}`, formData)
    },

    /**
     * 获取顾客查询记录
     * @param id 
     */
    async get_user_details(id: string, params: ListRequestParams<{}>): Promise<HttpSuccessOrErrorType<ListResults<Log>[]>> {
        return await http.get(`/customer/${id}/use_details`, {
            params
        })
    },

    /**
     * 删除合作伙伴
     * @param ids 
     * @returns 
     */
    async delete_user(ids: string[]): Promise<HttpSuccessOrErrorType<OkPacket>> {
        return await http.delete('/delete/user', {
            data: {
                _id: ids
            }
        })
    },

    /**
     * 刷新顾客key
     * @param id 
     */
    async patch_user_refresh(id: string): Promise<any> {
        return await http.patch(`/refresh/key/${id}`)
    },


    /**
     * 根据id修改用户信息
     * @param id 
     * @returns 
     */
    async patch_user_id(id: string, params: User): Promise<HttpSuccessOrErrorType<OkPacket>> {
        return await http.patch(`/update/staff/${id}`, params)
    },

}

export default userApi