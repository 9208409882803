import { Box } from '@mui/material';
import DrawerListComponent from './drawer-list.component'
import Drawer from '@mui/material/Drawer';

export default function NavComponent({
    drawerWidth,
    mobileOpen,
    handleMobileDrawerToggle,
    handleChangeTitle
}: Readonly<Props>) {
    return (
        <Box
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleMobileDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                <DrawerListComponent isOpen={true} handleChangeTitle={handleChangeTitle} />
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, overflow: 'hidden' },
                }}
                open
            >
                <DrawerListComponent isOpen={drawerWidth === 240} handleChangeTitle={handleChangeTitle} />
            </Drawer>
        </Box>
    )
}

type Props = {
    // 侧边栏宽度
    drawerWidth: number;
    // 移动端开关
    mobileOpen: boolean;
    // 移动端开关
    handleMobileDrawerToggle: () => void;
    // 侧边栏宽度
    handleDrawerToggle: () => void;
    // 修改标题
    handleChangeTitle: (value: string) => void;
}