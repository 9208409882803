import { Box, IconButton, MenuItem, TextField, TextFieldProps } from '@mui/material';
import React, { ForwardedRef, forwardRef, useState } from 'react';
import BASE_CHECK_TYPE from '../../constants/regular.constant';
import CloseIcon from '@mui/icons-material/Close';
import './scss/base-input.style.scss';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';


/**
 * 输入框组件
 * - regularType 正则检验
 */
const BaseInput = forwardRef((props: BaseTextFieldProps, ref: ForwardedRef<HTMLDivElement>) => {
    useTranslation()
    // isEmpty：文本为空时是否校验 (false表示不校验)，保存时才校验必填且为空的值
    const { isEmpty = false, value = '', regularType, allowClear, onChange, label, style, items, select, ...otherProps } = props;
    // 记录文本信息
    const [textValue, setTextValue] = useState<BaseTextFieldProps['value']>(value);
    const [counter, setCounter] = useState<number>(0);
    const [helperTextValue, setHelperTextValue] = useState<string>('');
    function getError() {
        /**
         * isEmpty：文本为空时是否校验
         * counter: 输入款失去焦点
         */
        if (isEmpty || counter) {
            if (String(textValue)?.trim()) {
                if (regularType && Object.prototype.hasOwnProperty.call(BASE_CHECK_TYPE, regularType)) {
                    const regular = BASE_CHECK_TYPE[regularType]?.regular;
                    return !regular.test(String(textValue))
                }
            } else {
                return props.required
            }
        } else {
            return false
        }
    }
    React.useEffect(() => {
        if (regularType && Object.prototype.hasOwnProperty.call(BASE_CHECK_TYPE, regularType)) {
            setHelperTextValue(i18n.t(BASE_CHECK_TYPE[regularType]?.helperText, BASE_CHECK_TYPE[regularType]?.i18nRule))
        }
    }, [regularType]);

    React.useEffect(() => {
        setTextValue(value)
    }, [value]);

    React.useEffect(() => {
        if (!isEmpty) {
            setCounter(0)
        }
    }, [isEmpty]);

    React.useEffect(() => {
        if (getError()) {
            if (regularType && Object.prototype.hasOwnProperty.call(BASE_CHECK_TYPE, regularType)) {
                setHelperTextValue(i18n.t(BASE_CHECK_TYPE[regularType]?.helperText, BASE_CHECK_TYPE[regularType]?.i18nRule))
            }
        }
    }, [])

    /**
     * 数字输入框只能输入1-9
     * @param event
     */
    const onNumberInputKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { key } = event;
        const filteredKeys = new Set(['e', '-', '+', 'E']);
        if (filteredKeys.has(key)) {
            event.preventDefault();
        }
    };

    // *********************
    // 视图区
    // *********************
    return (
        <Box width='100%' position='relative' className={`base-input ${props.value && allowClear ? 'allow-clear' : ''}`}>
            <TextField
                ref={ref}
                className={props.className}
                onBlur={() => setCounter(1)}
                autoComplete={props.autoComplete}
                autoFocus={props.autoFocus}
                color={props.color}
                disabled={props.disabled}
                error={getError()}
                FormHelperTextProps={props.FormHelperTextProps}
                fullWidth={props.fullWidth}
                id={props.id}
                // 错误文本
                helperText={getError() ? (props?.helperText ?? helperTextValue ?? '') : ''}
                InputLabelProps={props.InputLabelProps}
                InputProps={props.InputProps}
                inputProps={props.inputProps}
                inputRef={props.inputRef}
                label={typeof label === 'string' ? i18n.t(label as string) : label}
                margin={props.margin}
                multiline={props.multiline}
                name={props.name}
                onChange={(event) => {
                    if (props.maxLength ? (event.target.value.length <= props.maxLength) : true) {
                        setTextValue(event.target.value);
                        onChange?.(event);
                    }
                }}
                placeholder={props.placeholder}
                required={props.required}
                // rows={props.rows}
                // rowsMax={props.rowsMax}
                select={select}
                SelectProps={props.SelectProps}
                size={props.size || 'small'}
                style={{
                    minHeight: props.helperText ? 75 : 'auto',
                    imeMode: 'disabled',
                    ...style
                }}
                type={props.type}
                value={props.value ?? ''}
                variant={props.variant ?? 'outlined'}
                onKeyDown={(event) => props.type === 'number' ? onNumberInputKeyDown(event) : undefined}
                {...otherProps}
            >
                {
                    props.select ?
                        (items ?? []).map((option, index) => (
                            props.SelectProps?.native ?
                                <option key={option.value + '_' + index} value={option.value}>
                                    {option.label}
                                </option>
                                :
                                <MenuItem key={option.value + '_' + index} value={option.value} disabled={option.disabled}>
                                    {i18n.t(option.label)}
                                </MenuItem>
                        ))
                        : null
                }
                {
                    props.select && (items ?? []).length === 0 &&
                    <MenuItem value='' disabled>
                        {i18n.t('general_text_no_data')}
                    </MenuItem>
                }
            </TextField>
            {
                allowClear &&
                <div className='clear-button'>
                    <IconButton
                        size='small'
                        onClick={() => {
                            const event = {
                                target: {
                                    value: ''
                                }
                            }
                            setTextValue(event.target.value);
                            props?.onChange?.(event as any);
                        }}>
                        <CloseIcon />
                    </IconButton>
                </div>
            }
        </Box>
    )
})

BaseInput.displayName = 'BaseInput'
export default BaseInput;

export type BaseTextFieldProps = {
    items?: SelectOption[];
    // isEmpty：文本为空时是否校验 (false表示不校验)，保存时才校验必填且为空的值
    isEmpty?: boolean;
    customStyle?: object;
    value?: string | number | Date | string[] | number[];
    maxLength?: number;
    textFieldRef?: any;
    // 校验文本规则
    regularType?: 'nickname' |
    'phone' |
    'email' |
    'password' |
    'name' |
    'printName' |
    'staffName' |
    'optionalName' |
    'description' |
    'vatNumber' |
    'zipCode' |
    'recipientCode' |
    'fiscalCode' |
    'pec' |
    'website' |
    'name2' |
    'required' | 'dishName' | string;
    // 支持清除
    allowClear?: boolean;
} & TextFieldProps

// 下拉选项
export type SelectOption = {
    value: string | number;
    label: string;
    disabled?: boolean;
}