import { AxiosRequestConfig } from 'axios';
import http, { HttpSuccessOrErrorType } from '../http';


export default { 
	/**
	 * 获取当前登录的信息
	 */
	async get_account_condition(params: {
		name?: string;
		email?: string;
		phone?: string;
		audit?: boolean;
		start_time?: string | Date | null;
		end_timer?: string | Date | null;
		sort_key?: string;
		sort_value?: number;
		pag_per_page?: number;
		pag_page?: number;
	},config?: AxiosRequestConfig): Promise<HttpSuccessOrErrorType<any>> {
		return await http.get(`/customer/condition`,
			{
				params: {
					...params
				},
				...config
			}
		)
	},


	/**
	 * 审核通过当前用户
	 */
	async patch_account_audit(params: {_id: string, audit: string, start_time: string, end_time: string}, config?: AxiosRequestConfig): Promise<HttpSuccessOrErrorType<any>> {
		return await http.patch(`/update/audit/${params._id}`,
			{
				audit: params.audit,
				start_time: params.start_time,
				end_time: params.end_time,
				...config
			}
		)
	},


}