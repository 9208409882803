import { Box, CircularProgress } from '@mui/material';


// 自定义页面loading
export function CustomerLoading(): JSX.Element {
    // *********************
    // View
    // *********************

    return (
        <Box
            sx={(theme) => ({
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: theme.palette.mode === 'dark' ? '#17192599' : '#f1f4f870',
                zIndex: 9999
            })}>
            <CircularProgress />
        </Box >
    )
}