import { AxiosRequestConfig } from 'axios'
import http, { HttpSuccessOrErrorType } from '../http'



export default { 

	/**
	 * 获取设置
	 */
	async get_admin_setting(config?: AxiosRequestConfig): Promise<HttpSuccessOrErrorType<any>> {
		return await http.get(`/setting`, {
            ...config
        })
	},

	/**
	 * 编辑设置
	 */
	async patch_admin_setting(param: {
		priority?: {
			ES: Array<{name: string, status: boolean}>,
			IT: Array<{name: string, status: boolean}>
		},
		renewal_cycle?: number,
		cerved_key?: string,
		username?: string,
		password?: string,
	},config?: AxiosRequestConfig): Promise<HttpSuccessOrErrorType<any>> {
		return await http.patch(`/setting`, {
			...param,
            ...config
        })
	},
}