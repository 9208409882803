import { Box, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            textAlign: 'center',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            padding: 8
        },
        avatar: {
            background: '#FFF'
        },
        listItem: {
            cursor: 'pointer'
        }
    })
)
/**
 * 基础弹出窗
 */
export default function BasePopover(props: {
    // Popover 开关
    open: boolean;
    // Popover 锚点
    anchorEl: HTMLElement | null;
    // 关闭Popover
    onClose: () => void;
    //
    id: string;
    // 标题
    title?: string;
    // 内容
    children?: JSX.Element;
    className?: string;
}) {
    const { open, anchorEl, onClose, id, title, children, className } = props;
    const classes = useStyles();

    return (
        <Popover
            id={open ? id : undefined}
            className={`${className || ''}`}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            elevation={8}
        >
            {title && <Box className={classes.title}>
                <Typography variant='subtitle1'>
                    {title}
                </Typography>
            </Box>}
            {
                children
                // <Box>
                //     <Box display='flex' alignItems='center' justifyContent='center' height={100}>
                //         暂无数据
                //     </Box>
                //     <Divider />
                // </Box>
            }
            {/* <Box css={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
                <Button size='small' disableRipple onClick={handleClick}>
                    <Typography >
                        show all
                    </Typography>
                </Button>
            </Box> */}
        </Popover >
    )

}