

// 基础校验信息
const BASE_CHECK_TYPE: { [key: string]: { regular: RegExp, helperText: string, i18nRule?: object } } = {
    // 中英文数字,中间只能有一个空格,首尾不能有空格
    // 税率名称
    nickname: {
        regular: /^([A-Za-z0-9\u4e00-\u9fa5\-_]+\\s?)*[A-Za-z0-9\u4e00-\u9fa5\-_]$/,
        helperText: 'input_rule_text_please_enter_x_y_characters_of_any_character'
    },
    // 数字 长度5-20 （手机）
    phone: {
        regular: /^[0-9+]([-]?[0-9]){5,20}$/,
        helperText: 'input_rule_text_please_enter_x_y_numbers_or_horizontal_lines',
        i18nRule: { x: 6, y: 21 }
    },
    email: {
        regular: /^$|^[A-Za-z0-9]+([_.-][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/,
        helperText: 'input_rule_text_please_enter_the_correct_mailbox_format'
    },
    // 暂时只限制6-20位，由数字、英文字母组成
    password: {
        regular: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/,
        helperText: 'input_rule_text_at_x_y_character_it_must_be_a_number_letter',
        i18nRule: { x: 6, y: 20 }
    },
    // 至少一位
    name: {
        regular: /^.{1,}$/,
        helperText: 'input_rule_text_required'
    },
    printName: {
        regular: /(^([A-Za-z0-9\u4e00-\u9fa5]+\\s?)*[A-Za-z0-9\u4e00-\u9fa5]$)|(^$)/,
        helperText: 'input_rule_text_required'
    },
    // 税率名称（展示）| 名称（员工）
    staffName: {
        regular: /(^([A-Za-z0-9\u4e00-\u9fa5]+\\s?)*[A-Za-z0-9\u4e00-\u9fa5]$)|(^$)/,
        helperText: 'input_rule_text_required'
    },
    // 中文英文数字
    optionalName: {
        regular: /^[\u4e00-\u9fa5_A-Za-z0-9]{0,30}$/,
        helperText: 'input_rule_text_please_enter_x_y_characters_of_any_character',
        i18nRule: { x: 0, y: 30 }
    },
    description: {
        regular: /^.{0,255}$/,
        helperText: 'input_rule_text_please_enter_x_y_characters_of_any_character',
        i18nRule: { x: 0, y: 255 }
    },
    // 增值税号正则 11位数字
    vatNumber: {
        regular: /^[0-9]{11,11}$/,
        helperText: 'input_rule_text_please_enter_x_numbers',
        i18nRule: { x: 11 }
    },
    // 邮编
    zipCode: {
        regular: /^[0-9]{5,5}$/,
        helperText: 'input_rule_text_please_enter_x_numbers',
        i18nRule: { x: 5 }
    },
    // 收件人代码
    recipientCode: {
        regular: /^[A-Za-z0-9]{7,7}$/,
        helperText: 'input_rule_text_please_enter_x_numbers_or_letters',
        i18nRule: { x: 7 }
    },
    // 税控码 税务注册号正则：（16位数字或字母组成，字母必须大写，至少包含一个字母或数字） 或 （11位数字）
    fiscalCode: {
        regular: /^([A-Za-z0-9]{16,16}|[0-9]{11,11})$/,
        helperText: 'input_rule_text_please_enter_x_numbers_or_letters_at_least_y_numbers_or_letters',
        i18nRule: { x: 16, y: 11 }
    },
    pec: {
        // eslint-disable-next-line
        regular: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/,
        helperText: 'input_rule_text_required'
    },
    // 网址
    website: {
        // eslint-disable-next-line
        regular: /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*([\?&]\w+=\w*)*$/,
        helperText: 'input_rule_text_required'
    },
    name2: {
        regular: /(^$|^([A-Za-z0-9\u4e00-\u9fa5\-_]+\\s?)*[A-Za-z0-9\u4e00-\u9fa5\-_]$)/,
        helperText: 'input_rule_text_required'
    },
    // 至少有一位
    required: {
        regular: /^.+$/,
        helperText: 'input_rule_text_required'
    },
    // 至少有一位
    dishName: {
        regular: /^([A-Za-z0-9\u4e00-\u9fa5\-_]+\\s?)*[A-Za-z0-9\u4e00-\u9fa5\-_]$/,
        helperText: 'forbid_entering_special_characters'
    },
    // 轮次规则相关(每轮次分钟 | 每回合点菜数 | 最大回合数)
    roundRules: {
        regular: /^([1-9][0-9]{0,3})$/,
        helperText: 'must_be_an_integer_greater_than_1_and_less_than_9999'
    },
    // 匹配空字符
    emptyString: {
        regular: /^[\s]*$/,
        helperText: 'can_not_be_empty'
    },
    // 名称正则 (中间可以含有空格)
    emptyName: {
        regular: /^([A-Za-z0-9\u4e00-\u9fa5]+\s?)*[A-Za-z0-9\u4e00-\u9fa5]$/,
        helperText: 'input_rule_text_starts_with_letters_numbers_Chinese_contain_space_symbols_middle'
    },
    // 限制只能输入1-30位字符
    inputLimit: {
        regular: /^.{1,30}$/,
        helperText: 'input_rule_text_please_enter_x_y_characters_of_any_character',
        i18nRule: { x: 1, y: 30 }
    },
    // 图片校验
    imageCheck: {
        regular: /image\/\w+/,
        helperText: 'input_rule_text_please_upload_the_correct_picture_format'
    },
    // 最少一个字符必须是数字、英文字母或下划线
    lessOne: {
        regular: /^\w+$/,
        helperText: 'input_rule_text_please_enter_at_least_x_number_english_letter_or_underscore',
        i18nRule: { x: 1 }
    },
    // 最大6位置数字
    maxNumberSix: {
        regular: /^\d{0,6}$/,
        helperText: 'input_rule_text_please_enter_x_y_digit_characters',
        i18nRule: { x: 0, y: 6 }
    },
    /**
     * 校验餐厅税务信息公司名称规则
     * @description 允许字符：0-9a-zA-Z 和英文符号：空格 , . _ - & ( )
     * @description 注释：空格，逗号，句号，下横杠，中横杠，连接符，括号，反括号
     */
    regName: {
        regular: /^[a-zA-Z0-9 ,._&()-]{1,}$/,
        helperText: 'input_rule_text_at_least_x_character_it_must_be_a_number_letter_or_symbol',
        i18nRule: { x: 1 }
    },
   
};
export default BASE_CHECK_TYPE;

/**
 * 检查正则
 * - 配合BASE_CHECK_TYPE
 */
export function CheckRule(key: string, value: string) {
    const item = BASE_CHECK_TYPE[key];
    if (item) {
        if (item?.regular.test(String(value))) {
            return true
        }
    }
    return false
}