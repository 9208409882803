import { Typography, Dialog } from '@mui/material';
// TODO 换成公共组件
// import DialogComponent from '../dialog/dialog.component'
import { makeStyles, createStyles } from '@mui/styles';

export default function AvatarDialog(props: {
    open: boolean;
    onClose: () => void;
    data: Array<{
        // 图片地址
        imgSrc: string;
        // 图片名称
        imgName: string;
    }>;
}) {
    const { open, onClose, data } = props;
    const classes = useStyles();
    return (
        <Dialog
            maxWidth='sm'
            open={open}
            onClose={() => onClose()}
            // defaultHeaderProps={{
            //     title: i18n.t('general_text_preview_picture'),
            //     onClose: () => { onClose() }
            // }}
            className={classes.dialog}
        >
            {
                data.map((item, index) => (
                    <div className={classes.imgBox} key={index}>
                        <img src={item.imgSrc} alt="" className={classes.img} />
                        <Typography>{item.imgName}</Typography>
                    </div>
                ))
            }
        </Dialog>
    )
}

const useStyles = makeStyles(
    createStyles({
        dialog: {
            minHeight: 200
        },
        img: {
            maxWidth: '100%',
            maxHeight: '100%'
        },
        imgBox: {
            overflow: 'hidden',
            textAlign: 'center',
            margin: '10px 0px'
        }
    })
)