import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import GlobalNotification from '../components/common/snackbar';
import i18n from '../i18n';
import qs from 'qs';
import _ from 'lodash';
import LSM from '../modules/cache/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../modules/cache/local-storage.constant';
import { cleanAccountCache } from '../constants/tool-login';

//  $ 基础url (2种不同的环境：dev、release)
const URL = process.env.REACT_APP_BASE_URL ?? 'https://databubble-dev-dynamic-api.evophotic.com';
const VERSION = 'v1';
const BASE_URL = `${URL}/${VERSION}`;

// $ 创建axios对象
export const instant: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    // timeout: 20000,
    headers: { 'authorization-type': 'BASIC_AUTH' }
});

//  请求处理
const requestConfig = (config: InternalAxiosRequestConfig<{ version: string }>) => {

    // // 原来的配置
    config = config || {};

    // // 获取token
    const token = LSM.get(LOCAL_STORAGE_KEYS.TOKEN) || {};
    if (config.method === 'get') {
        // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
        config.paramsSerializer = {
            serialize: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' })
            }
        }
    }
    // 配置请求头的auth信息
    config.headers['Authorization'] = `Bearer ${_.get(token, 'accessToken') ?? ''}`;
    config.headers['Access-Control-Allow-Origin'] = '*';
    return {
        ...config
        // 这里可以加一些公共的配置参数
    }
}

const createErrorResponse = (error: any) => ({
    error: true,
    status: _.get(error, 'response.status'),
    result: _.get(error, 'response.data.error') || _.get(error, 'response.data'),
    message: error.message
});

// 响应出错
const responseError = (error: { message?: string; }) => {
    // 错误提示
    const message = `${_.get(error, 'response.data.error.message', '')}`;
    const details = `${_.get(error, 'response.data.error.details', '')}`;
    // $ 请求超时
    if (error?.message?.search(/timeout/) !== -1) {
        GlobalNotification.error(`${i18n.t('http_message_network_request_timed_out')}`);
    }
    //$ 当服务器发生错误时，直接弹出窗口
    if (`${_.get(error, 'response.status')}`.startsWith('5')) {
        console.error(error);
        GlobalNotification.error(i18n.t('request_failure_text_service_is_not_available'))
    }
    //$ token失效,token过期
    if (message.includes('please log in again') || details.includes('invalid token')) {
        GlobalNotification.error(i18n.t('general_text_Not_logged_in_for_a_long_time_need_to_log_in_again'))
        cleanAccountCache()
    }
    return createErrorResponse(error);
};

// 请求出错
const requestError = (error: any) => {
    return Promise.reject(error);
}

// 统一响应请求
const responseConfig = (config: AxiosResponse): AxiosResponse => {
    return config.data;
}

// 添加拦截器
instant.interceptors.request.use(requestConfig, requestError);
instant.interceptors.response.use(responseConfig, responseError);


export type HttpSuccessOrErrorType<T> = { data: T } extends WhenError ? WhenError : T & WhenError;
export type WhenError = { error: true, message: string, result: HttpErrorType }

export type HttpErrorType = {
    code?: number;
    details?: Record<string, unknown>;
    message?: string;
    statusCode?: number;
}

export default instant;

// 表格请求参数
export type ListRequestParams<T> = Partial<{
    pag_page: number;
    pag_per_page: number;
    sort_key: string;
    sort_value: number;
    vat: string
}> & T;

// 表格返回值类型
export type ListResults<T> = { dbResults: T[], totalCount: Array<{ count: number }> }

export interface WillStopConfig extends InternalAxiosRequestConfig<{ version: string }> {
    stop_by_unmount?: boolean;
}

export function createPageReqDocker() {
    const ctrl = new AbortController();
    return instant.interceptors.request.use((config: WillStopConfig) => {
        if (config.stop_by_unmount) {
            return { signal: ctrl.signal, ...config }
        } else {
            return config
        }
    });
}

export function removeReq(interceptorId: number) {
    instant.interceptors.request.eject(interceptorId);
}

// patch 返回值
export declare type OkPacket = {
    fieldCount: number;
    affectedRows: number;
    insertId: number;
    serverStatus: number;
    warningCount: number;
    message: string;
    protocol41: boolean;
    changedRows: number;
}