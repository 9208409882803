import AccountPage from '../pages/account-page';
import CompanyPage from '../pages/company-page';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogPage from '../pages/log-page';
import SettingPage from '../pages/setting-page';
import UserPage from '../pages/user-page';
import { CorporateFareOutlined, ErrorOutlineRounded } from '@mui/icons-material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SettingsIcon from '@mui/icons-material/Settings';

// 路由配置
export const asyncRoutes = [
    {
        path: 'company',
        id: 'company',
        name: 'compony_page_text_compony_management',
        component: <CompanyPage />,
        icon: <MenuBookIcon />,
        auth: ['ADMIN'],
    },
    {
        path: 'account',
        id: 'account',
        name: 'partner_page_text_partner_management',
        component: <AccountPage />,
        icon: <CorporateFareOutlined />,
        auth: ['ADMIN'],
    },
    {
        path: 'log',
        id: 'log',
        name: 'log_page_text_log_management',
        component: <LogPage />,
        icon: <ErrorOutlineRounded />,
        auth: ['ADMIN'],
    },
    {
        path: 'user',
        id: 'user',
        name: 'main_page_text_person_page',
        component: <UserPage />,
        icon: <PersonOutlineIcon />,
        auth: ['ADMIN', 'STAFF'],
    },
    {
        path: 'setting',
        id: 'setting',
        name: 'setting_page_title_setting',
        component: <SettingPage />,
        icon: <SettingsIcon />,
        auth: ['ADMIN'],
    }
]
