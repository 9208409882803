import { Switch, SwitchProps } from '@mui/material';
import { purple } from '@mui/material/colors';
import { createStyles, withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';


export const PurpleSwitch = withStyles({
  switchBase: {
    color: purple[300],
    '&$checked': {
      color: purple[500]
    },
    '&$checked + $track': {
      backgroundColor: purple[500]
    }
  },
  checked: {},
  track: {}
})(Switch);

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#556cd6',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const AntSwitch = withStyles((theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex'
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main
        }
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none'
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white
    },
    checked: {}
  })
)(Switch);

// export default function CustomizedSwitches() {
//   const [state, setState] = React.useState({
//     checkedA: true,
//     checkedB: true,
//     checkedC: true,
//   });

//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setState({ ...state, [event.target.name]: event.target.checked });
//   };

//   return (
//     <FormGroup>
//       <FormControlLabel
//         control={<PurpleSwitch checked={state.checkedA} onChange={handleChange} name="checkedA" />}
//         label="Custom color"
//       />
//       <FormControlLabel
//         control={<IOSSwitch checked={state.checkedB} onChange={handleChange} name="checkedB" />}
//         label="iOS style"
//       />
//       <Typography component="div">
//         <Grid component="label" container alignItems="center" spacing={1}>
//           <Grid item>Off</Grid>
//           <Grid item>
//             <AntSwitch checked={state.checkedC} onChange={handleChange} name="checkedC" />
//           </Grid>
//           <Grid item>On</Grid>
//         </Grid>
//       </Typography>
//     </FormGroup>
//   );
// }