import { Box, Card, Typography, InputProps } from '@mui/material';
import React, { useState } from 'react';
import './register.style.scss';
import { useNavigate } from 'react-router-dom';
import apiManage from '../../request';
import GlobalNotification from '../../components/common/snackbar';
import { useTranslation } from 'react-i18next';
import { CardFormChildren, CardFormChildrenConfig, CardFormConfig, checkCardFromRule } from '../../components/card/card-from';
import ButtonComponent from '../../components/button/button.component';
import { User } from '../../models';

export default function RegisterPage() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	// 表单数据
	const [data, setData] = React.useState<DataProps>({})
	// 是否开启校验
	const [isValidate, setIsValidate] = useState(false)
	// 注册事件
	const registerAccount = async (cardFormConfig: CardFormConfig[]) => {
		setIsValidate(true)
		// 表单校验
		const errorList = checkCardFromRule({
			config: cardFormConfig,
			data: data
		});
		if (errorList.length) {
			return;
		}
		// 判断密码
		if (data.password !== data.cPassword) {
			GlobalNotification.warning(t('password_page_text_inconsistent_passwords'));
			return
		}
		const result = await apiManage.post_register_account({
			phone: data.phone ?? '',
			phone_prefix: data.phone_prefix ?? '39',
			email: data.email ?? '',
			password: data.password ?? '',
			name: data.name ?? ''
		});
		if (result && (!result.error)) {
			GlobalNotification.success(t('register_page_text_register_suc'));
			// 跳转登录页面
			navigate('/login')
		} else {
			// 错误提示
			const message = result?.result?.message ?? '';
			if (message.includes('Mobile phone number already exists.')) {
				GlobalNotification.error(t('register_page_text_message_registered'));
			} else {
				GlobalNotification.error(message);
			}
		}
	}

	/**
	 * 表单回调
	 * @param key 
	 * @param value 
	 */
	const onChange = (key: DataKeys, value: string) => {
		data[key] = value
		setData({
			...data
		})
	}

	// 表单
	const formData: CardFormChildrenConfig[] = [
		{
			key: 'name' as const,
			label: t('general_text_name'),
			required: true,
			regularType: 'inputLimit',
			comType: 'INPUT',
		},
		{
			comType: 'INPUT',
			key: 'phone' as const,
			label: t('general_text_account'),
			inputType: 'PHONE' as const,
			required: true,
			regularType: 'phone',
			country: data.phone_prefix ?? '39',
			handleChangeCountry: (value: string) => {
				onChange('phone_prefix', value)
			},
		},
		{
			comType: 'INPUT',
			key: 'email' as const,
			label: t('general_text_email'),
			regularType: 'email',
		},
		{
			comType: 'INPUT',
			key: 'password' as const,
			label: t('login_page_text_password'),
			regularType: 'password',
			required: true,
			inputType: 'PASSWORD' as const,
		},
		{
			comType: 'INPUT',
			key: 'cPassword' as const,
			label: t('password_page_text_confirm_password'),
			regularType: 'password',
			required: true,
			inputType: 'PASSWORD' as const,
		}
	]

	// 表单设置
	const cardConfig: CardFormConfig = {
		key: 'baseInfo',
		title: '',
		rowSpacing: 2,
		columnSpacing: { md: 2, xs: 2 },
		xs: 12,
		children: formData.map((item) => ({
			xs: 12,
			inputIsEdited: false,
			InputProps: {
				size: 'medium'
			} as InputProps,
			onChange: (event) => {
				const value = event.target.value;
				onChange(item.key as DataKeys, value)
			},
			isEmpty: isValidate,
			...item
		}))
	}

	return (
		<Box className="registerPage" sx={(theme) => ({
			background: theme.palette.mode === 'dark' ? '#25293c' : '#fff'
		})}>
			<Box className={`controll-panel`}>
				<Card style={{
					padding: '1.5rem'
				}}>
					<Box className="title-box">
						<Box className="title-box-login">
							<Typography className="text_1">
								{t('register_page_text_have_account')}
							</Typography>
							<Typography className="text_2" onClick={() => {
								navigate('/login')
							}}>
								{t('login_page_button_login')}
							</Typography>
						</Box>
						<Box>
							<Typography variant='body1' className="text_3">
								{t('register_page_text_create_account')}
							</Typography>
						</Box>
					</Box>

					<CardFormChildren
						form={cardConfig}
						data={data}
					/>
					<ButtonComponent
						extraButtonStyle='login-button'
						variant='contained'
						color={'primary'}
						handleClick={async () => {
							await registerAccount([cardConfig])
						}}
					>
						{t('register_page_text_register')}
					</ButtonComponent>
				</Card>
			</Box>
		</Box>
	)
}

// 表单数据
type DataProps = Pick<User, DataKeys> & {
	cPassword?: string
}

type DataKeys = 'name' | 'phone' | 'phone_prefix' | 'password' | 'email'