import './App.css';
import { ThemeProvider } from '@emotion/react';
import { getDesignTokens } from './themes/theme';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './components/common/snackbar';
import AppRouter from './router';
import { createContext, useMemo, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

function App() {
  const [mode, setMode] = useState<PaletteMode>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={5}>
          <SnackbarUtilsConfigurator />
          <AppRouter />
        </SnackbarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;


export const ColorModeContext = createContext({ toggleColorMode: () => { } });
