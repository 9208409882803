
// 文件大小限制 (type为单位)
const LIMIT_FILE_SIZE = 512000;
/**
 * GYB 中介 Intermediary
 * - i18n 在组件内翻译
 */
const INTERMEDIARY = [{
    value: '1',
    label: 'gyb_page_text_in_te_s_a'
}, {
    value: '2',
    label: 'gyb_page_text_in_te_s_a_grandi_clienti'
}, {
    value: '3',
    label: 'gyb_page_text_fastInvoice'
}, {
    value: '4',
    label: 'gyb_page_text_seac'
}, {
    value: '5',
    label: 'gyb_page_text_infocert'
}, {
    value: '6',
    label: 'gyb_page_text_iper'
}, {
    value: '7',
    label: 'gyb_page_text_2c_solution'
}, {
    value: '8',
    label: 'gyb_page_text_camst'
}, {
    value: '9',
    label: 'gyb_page_text_agyo'
}, {
    value: '10',
    label: 'gyb_page_text_ditech'
}];

const CONSTANT_THAT = 1;


export {
    LIMIT_FILE_SIZE,
    INTERMEDIARY,
    CONSTANT_THAT
}

export type PropType<T, P extends keyof T> = T[P];

export type RequiredSome<T, K extends keyof T> = Partial<T> & Pick<T, K>

export type Unpacked<T> = T extends (infer U)[] ? U : T;