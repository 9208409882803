import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';

// 卡片
export default function CardView(props: {
    // 卡片标题
    title?: string | JSX.Element;
    // 按钮
    actionRender?: JSX.Element;
    // 内容
    contentRender?: JSX.Element;
    contentClassName?: string;
    className?: string;
    titleTypographyProps?: JSX.Element;
    style?: React.CSSProperties;
}) {
    const { title, actionRender, contentRender, contentClassName, className, titleTypographyProps, style } = props;
    return (
        <Card className={className} style={style}>
            {
                title &&
                <CardHeader
					disableTypography={false}
                    action={actionRender}
                    title={title}
                    titleTypographyProps={titleTypographyProps}
                />
            }
            <CardContent className={contentClassName ?? ''}>
                {contentRender}
            </CardContent>

        </Card>
    )
}