import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, InputAdornment } from '@mui/material';
import React from 'react';
import BaseInput, { BaseTextFieldProps } from './base-input.component';

/**
 * 密码输入框
 * @param props
 * @returns
 */
function PasswordInput(props: Props) {
    const { value, label, size, onChange, onEnterLogin, ...otherProps } = props;
    const [showPassword, setShowPassword] = React.useState(false);

    // 密码查看图标控制
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Box className='password'>
            <BaseInput
                required
                className={`searchInput`}
                onChange={(event) => {
                    event.stopPropagation();
                    onChange?.(event);
                }}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={label}
                value={value}
                regularType={'password'}
                onKeyDown={async (e) => {
                    e.stopPropagation()
                    if (e.key === 'Enter') {
                        onEnterLogin && await onEnterLogin?.();
                    }
                }}
                InputProps={{
                    autoComplete: 'new-password',
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                size={size ?? 'medium'}
                {...otherProps}
            />
        </Box>
    );
}

export default PasswordInput;

type Props = {
    // value: string;
    // onChange: (value: string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    // label?: string;
    // size?: 'medium' | 'small';
    // 回车键登录
    onEnterLogin?: () => Promise<void>;
} & BaseTextFieldProps