 // $ 消息条的配置

import React from 'react';
import { OptionsObject, useSnackbar } from 'notistack';

interface IProps {
  setUseSnackbarRef: (showSnackbar: ReturnType<typeof useSnackbar>) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: ReturnType<typeof useSnackbar>;

const setUseSnackbarRef = (useSnackbarRefProp: ReturnType<typeof useSnackbar>) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

const GlobalNotification = {
  success(message: string) {
    this.notification(`${message}`, { variant: 'success' });
  },
  warning(message: string) {
    this.notification(`${message}`, { variant: 'warning' });
  },
  info(message: string) {
    this.notification(`${message}`, { variant: 'info' });
  },
  error(message: string) {
    this.notification(`${message}`, { variant: 'error' });
  },
  notification(message: string, options?: OptionsObject) {
    const variant = options?.variant || 'default';
    useSnackbarRef.enqueueSnackbar(message, {
      ...options,
      variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 3000,
    });
  },
};
export default GlobalNotification;

