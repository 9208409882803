import { ContentCopy, Refresh } from '@mui/icons-material';
import ButtonComponent from '../../components/button/button.component';
import CardView from '../../components/card/card-view.component';
import GlobalNotification from '../../components/common/snackbar';
import TableComponent, { HeadCell } from '../../components/table/table.component';
import { dateFormatLocal, dateFormat } from '../../constants/tool';
import { User } from '../../models';
import copy from 'copy-to-clipboard';
import { Box, Chip } from '@mui/material';
import apiManage from '../../request';
import { useEffect, useState } from 'react';
import { Log } from '../../models/log.entity';
import { LOCAL_STORAGE_KEYS } from '../../modules/cache/local-storage.constant';
import LSM from '../../modules/cache/tool-local-storage';
import LogDetail, { LogDetailProps } from './log-detail';
import { useTranslation } from 'react-i18next';

export default function SettingDetail({ userDetail, updateUserDetail }: Readonly<Props>) {
    const { t } = useTranslation();
    // 表格数据
    const [logList, setLogList] = useState<Log[]>([])
    const [totalCount, setTotalCount] = useState(0)
    // 表格loading
    const [isLoading, setIsLoading] = useState(false)
    // 页码
    const [pagPage, setPagPage] = useState(1)
    const [pagPerPage, setPagPerPage] = useState(20)
    // 排序
    const [sortKey, setSortKey] = useState('')
    const [sortValue, setSortValue] = useState<0 | 1 | 2>(0)
    // 日志详情弹窗
    const [isOpenDetail, setIsOpenDetail] = useState(false)
    const [logDetail, setLogDetail] = useState<LogDetailProps>({ url: '', request: {}, response: {} })
    // 是否审核通过
    const isAudit = userDetail.audit === 'true';

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true)
            // 当前账号信息
            const account = LSM.get(LOCAL_STORAGE_KEYS.ACCOUNT);
            // 排序条件
            const sortParams = {
                sort_key: (sortKey === '' || sortValue === 0) ? 'create_time' : sortKey,
                sort_value: (sortValue === 0 || sortValue === 1) ? -1 : 1,
            }
            // 获取日志
            const result = await apiManage.get_user_details(account._id ?? '', {
                pag_page: pagPage,
                pag_per_page: pagPerPage,
                ...sortParams,
            });

            // 请求成功
            if (result && !result.error) {
                setLogList(result?.[0]?.dbResults ?? [])
                setTotalCount(result?.[0]?.totalCount?.[0]?.count ?? 0)
            }
            setIsLoading(false)
        }
        getData()

    }, [pagPage, pagPerPage, sortKey, sortValue])


    /**
     * 关闭日志详情弹窗
     */
    const handleClose = () => {
        setIsOpenDetail(false)
    }

    /**
     * 打开日志详情弹窗
     * @param value 
     */
    const handleOpen = (value: LogDetailProps) => {
        setLogDetail(value)
        setIsOpenDetail(true)
    }

    /**
     * 页码回调
     * @param params
     */
    const changePageCallback = (params: {
        page: number;
        curRowsPerPage: number;
    }) => {
        console.log('params.page', params.page)
        setPagPerPage(params.curRowsPerPage)
        setPagPage(params.page + 1)
    }


    /**
     * 排序
     * @param headCell
     */
    const handleSort = (headCell: HeadCell) => {
        let newSortValue = sortValue;
        switch (headCell.sortStatus) {
            case 0: {
                newSortValue = 1;
                break;
            }
            case 1: {
                newSortValue = 2;
                break;
            }
            case 2: {
                newSortValue = 0;
                break;
            }
        }
        setPagPage(1)
        setSortKey(headCell.id)
        setSortValue(newSortValue)
    }


    const refreshKey = async () => {
        try {
            const result = await apiManage.patch_user_refresh(userDetail._id || '');
            if (result && !result.error) {
                GlobalNotification.success(t('general_text_refresh_success'))
                updateUserDetail()
            } else {
                GlobalNotification.success(t('general_text_refresh_failed'))
            }
        } catch (error) {
            GlobalNotification.success(t('general_text_refresh_failed'))
        }
    }


    // 状态信息
    const headCellListStatus: HeadCell[] = [
        {
            id: 'time',
            label: t('restaurant_extensions_page_text_create_time'),
            renderFunction: (row: User) => {
                return dateFormat(row.create_time, 'YYYY-MM-DD HH:mm')
            }
        },
        {
            id: 'status',
            label: t('general_text_state'),
            renderFunction: () => {
                return (
                    <Chip
                        size='small'
                        label={isAudit ? t('setting_page_text_status_approved') : t('setting_page_text_status_to_be_reviewed')}
                        color={isAudit ? 'success' : 'error'}
                    />
                )
            }
        },
    ]
    // 密钥信息
    const headCellList: HeadCell[] = [
        {
            id: 'time',
            label: t('setting_page_text_authorization_time'),
            renderFunction: (row: User) => {
                return `${dateFormatLocal(row.start_time, 'YYYY-MM-DD HH:mm')} - ${dateFormatLocal(row.end_time, 'YYYY-MM-DD HH:mm')}`
            }
        },
        {
            id: 'key',
            label: t('restaurant_extensions_page_text_key'),
            renderFunction: (row: User) => {
                return (
                    <Box className='FC' sx={{ wordBreak: 'break-all', minWidth: '200px' }} >
                        <ButtonComponent
                            variant='text'
                            handleClick={() => {
                                const res = copy(row?.key ?? '');
                                if (res) GlobalNotification.success(t('restaurant_page_input_tip_message_copy_successfully'))
                            }}>
                            <ContentCopy />
                        </ButtonComponent>
                        <ButtonComponent
                            variant='text'
                            handleClick={refreshKey}>
                            <Refresh />
                        </ButtonComponent>
                        {row?.key}
                    </Box>
                )
            }
        }
    ]
    // 电子发票记录
    const headCellListLog: HeadCell[] = [
        {
            id: 'ID',
            label: t('table_component_text_serial_number'),
            renderFunction: (row, index) => {
                return (pagPage - 1) * pagPerPage + index + 1
            }
        },
        {
            id: 'create_time',
            label: t('restaurant_extensions_page_text_create_time'),
            sortStatus: sortKey === 'create_time' ? sortValue : 0,
            renderFunction: (row) => dateFormat(row.create_time)
        },
        {
            id: 'vat',
            label: t('restaurant_info_page_text_vat_number'),
        },
        {
            id: 'status',
            label: t('general_text_state'),
            renderFunction: (row: Log) => {
                return (
                    <Chip
                        size='small'
                        label={row?.status ? t('general_text_success') : t('general_text_failed')}
                        color={row?.status ? 'success' : 'error'}
                    />
                )
            }
        },
        {
            id: 'details',
            label: t('general_text_details'),
            renderFunction: (row) => {
                return (
                    <ButtonComponent
                        action="VIEW"
                        handleClick={() => handleOpen({
                            url: `/v1/location/check/${row.vat}`,
                            request: {
                                vat: row.vat,
                                country: 'IT'
                            },
                            response: row.bill ?? {}
                        })}
                    />
                )
            }
        }
    ];


    return (
        <div>
            {/*审核状态 */}
            <CardView
                {...{
                    className: '',
                    title: t('general_text_state'),
                    style: { marginTop: '20px' },
                    contentRender: <TableComponent
                        {...{
                            showChecked: 0,
                            list: [userDetail],
                            headCellList: headCellListStatus,
                        }}
                    />
                }}
            />
            {
                isAudit ?
                    <>
                        {/* 密钥表格 */}
                        <CardView
                            {...{
                                className: '',
                                title: t('restaurant_extensions_page_text_key'),
                                style: { marginTop: '20px' },
                                contentRender: <TableComponent
                                    {...{
                                        showChecked: 0,
                                        list: [userDetail],
                                        headCellList,
                                    }}
                                />
                            }}
                        />
                        {/* 获取电子发票表格 */}
                        <CardView
                            {...{
                                className: '',
                                style: { marginTop: '20px' },
                                title: t('partner_page_text_record'),
                                contentRender: <TableComponent
                                    {...{
                                        showChecked: 0,
                                        list: logList,
                                        headCellList: headCellListLog,
                                        isPagination: true,
                                        allCount: totalCount,
                                        handleSort,
                                        isTableLoading: isLoading,
                                        changePageCallback,
                                        isCurrentPageData: true,
                                    }}
                                />
                            }}
                        />

                    </>
                    :
                    null
            }
            <LogDetail
                isOpen={isOpenDetail}
                handleClose={handleClose}
                detail={logDetail}
            />
        </div>
    )
}

type Props = {
    userDetail: User;
    // 更新用户详情
    updateUserDetail: () => void;
}