import { useCallback, useEffect, useState } from 'react';
import TableComponent, { HeadCell } from '../../components/table/table.component';
import { GybBillInfo } from '../../models';
import apiManage from '../../request';
import { ConditionType, SearchKeyInput, SearchKeyInputItems } from '../../components/form/search-key-input.component';
import _ from 'lodash';
import ButtonComponent from '../../components/button/button.component';
import { Box, Grid, Paper } from '@mui/material';
import CommonInput from '../../components/form/common-input.component';
import ScrollTabs, { ActiveTabProps } from '../../components/tab/scroll-tabs.component';
import ComponyDetail from './compony-detail';
import { generateBig } from '../../constants/tool';
import DeleteDialog from '../../components/dialog/delete-dialog.component';
import GlobalNotification from '../../components/common/snackbar';
import { useTranslation } from 'react-i18next';


export default function CompanyPage() {
    const { t } = useTranslation();
    // 表格数据
    const [list, setList] = useState<GybBillInfo[]>([])
    const [totalCount, setTotalCount] = useState(0)
    // 表格loading
    const [isLoading, setIsLoading] = useState(false)
    // 页码
    const [pagPage, setPagPage] = useState(1)
    const [pagPerPage, setPagPerPage] = useState(20)
    // 排序
    const [sortKey, setSortKey] = useState('')
    const [sortValue, setSortValue] = useState<0 | 1 | 2>(0)
    // 搜索
    const [conditions, setConditions] = useState<ConditionType[]>([]);
    // 国家
    const [country, setCountry] = useState('IT');

    // 通讯录首字母
    const [corporate, setCorporate] = useState<ActiveTabProps>({ activeIndex: 0, activeValue: 'ALL' })

    // 详情弹窗开关
    const [isOpen, setIsOpen] = useState(false)
    const [detail, setDetail] = useState<GybBillInfo>({})

    // 删除二次确认弹窗
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [deleteIds, setDeleteIds] = useState<string[]>([])

    /**
     * 获取数据
     */
    const getData = useCallback(async () => {
        setIsLoading(true)
        // 搜索条件
        const searchObj: { [key: string]: string[] } = {}
        conditions.forEach((item) => {
            searchObj[item.key] = searchObj[item.key] ? _.concat(searchObj[item.key], item.value) : [item.value];
        })
        // 排序条件
        const sortParams = {
            sort_key: (sortKey === '' || sortValue === 0) ? 'corporate' : sortKey,
            sort_value: sortValue === 1 ? -1 : 1,
        }
        const result = await apiManage.get_compony_condition({
            pag_page: pagPage,
            pag_per_page: pagPerPage,
            country,
            corporate: corporate.activeValue,
            ...sortParams,
            ...searchObj
        })
        // 请求成功
        if (result && !result.error) {
            setList(result?.[0]?.dbResults ?? [])
            setTotalCount(result?.[0]?.totalCount?.[0]?.count ?? 0)
        }
        setIsLoading(false)
    }, [sortKey, sortValue, pagPage, pagPerPage, conditions, country, corporate.activeValue])


    useEffect(() => {
        // 排序、分页改变时重新获取数据
        getData()
    }, [sortKey, sortValue, pagPage, pagPerPage, conditions, country, corporate.activeValue, getData])




    /**
     * 搜索
     * @param conditions
     */
    const handleSearch = (conditions: ConditionType[]) => {
        setPagPage(1)
        setConditions([...conditions])
    }

    /**
     * 国家选择
     * @param value 
     */
    const handleChangeCountry = (value: string) => {
        setCountry(value)
    }


    /**
     * 切换首字母
     * @param value 
     */
    const handleChangeTab = (value: ActiveTabProps) => {
        setCorporate(value)
    }

    /**
     * 删除
     */
    const handleDelete = async (deleteIds: string[]) => {
        const result = await apiManage.delete_compony(deleteIds)
        if (result && !result.error) {
            GlobalNotification.success(t('global_text_operate_success'));
            setIsOpenDelete(false)
            getData()
        } else {
            GlobalNotification.error(t('global_text_operate_failure'));
        }
    }

    /**
     * 删除二次弹窗
     * @param value 
     */
    const handleOpenDelete = (value: GybBillInfo) => {
        setIsOpenDelete(true)
        setDeleteIds(value._id ? [value._id] : [])
    }


    /**
     * 关闭二次弹窗
     */
    const handleCancelDelete = () => {
        setIsOpenDelete(false)
    }

    /**
     * 排序
     * @param headCell
     */
    const handleSort = (headCell: HeadCell) => {
        let newSortValue = sortValue;
        switch (headCell.sortStatus) {
            case 0: {
                newSortValue = 1;
                break;
            }
            case 1: {
                newSortValue = 2;
                break;
            }
            case 2: {
                newSortValue = 0;
                break;
            }
        }
        setPagPage(1)
        setSortKey(headCell.id)
        setSortValue(newSortValue)
    }


    // 列
    const headCellList: HeadCell[] = [
        {
            id: 'corporate',
            label: t('compony_page_text_company_name'),
            sortStatus: sortKey === 'corporate' ? sortValue : 0,
        },
        {
            id: 'zip_code',
            label: t('compony_page_text_postcode'),
            sortStatus: sortKey === 'zip_code' ? sortValue : 0,
        },
        {
            id: 'vat_number',
            label: t('restaurant_info_page_text_vat_number'),
            sortStatus: sortKey === 'vat_number' ? sortValue : 0,
        },
        {
            id: 'tax_code',
            label: t('compony_page_text_fiscal_code'),
            sortStatus: sortKey === 'tax_code' ? sortValue : 0,
        },
        {
            id: 'PECDestinatario',
            label: t('compony_page_text_PEC'),
            sortStatus: sortKey === 'PECDestinatario' ? sortValue : 0,
        },
        {
            id: '',
            label: t('general_text_details'),
            renderFunction: (row) => {
                return (
                    <Box display='flex'>
                        <ButtonComponent action="VIEW" handleClick={() => handleOpen(row)} />
                        <ButtonComponent variant='outlined' action="DELETE" color="secondary" handleClick={() => handleOpenDelete(row)} />
                    </Box>
                )
            }
        },
    ]

    // 查找表格条件下拉框
    const filterList: SearchKeyInputItems[] = [
        {
            value: 'vat_number',
            label: t('restaurant_info_page_text_vat_number'),
            type: 'TEXT'
        },
        {
            value: 'corporate',
            label: t('compony_page_text_company_name'),
            type: 'TEXT'
        },
        {
            value: 'tax_code',
            label: t('compony_page_text_fiscal_code'),
            type: 'TEXT'
        }
    ];

    // 国家列表
    const countryList = [
        { value: 'IT', label: 'IT' },
        { value: 'ES', label: 'ES' },
    ]


    const getTabs = () => {
        const value = generateBig().map((item) => ({
            id: item,
            title: item,
        }))
        value.unshift({
            id: 'ALL',
            title: 'ALL'
        })
        return value
    }


    /**
     * 打开弹窗
     */
    const handleOpen = (value: GybBillInfo) => {
        setIsOpen(true)
        setDetail(value)
    }
    /**
     * 关闭弹窗
     */
    const handleClose = () => {
        setIsOpen(false)
    }

    /**
     * 页码回调
     * @param params
     */
    const changePageCallback = (params: {
        page: number;
        curRowsPerPage: number;
    }) => {
        setPagPerPage(params.curRowsPerPage)
        setPagPage(params.page + 1)
    }


    return (
        <Paper sx={{ padding: 4 }}>
            <Grid sx={{ mb: 3 }} container justifyContent='space-between' alignItems='center' >
                <SearchKeyInput
                    items={filterList}
                    conditions={conditions}
                    onSearch={handleSearch}
                    showConditionsBox
                />
                <Box sx={{ width: 100 }}>
                    <CommonInput
                        fullWidth
                        inputType={'SELECT'}
                        items={countryList}
                        value={country}
                        onChange={(e) => handleChangeCountry(e.target.value)}
                    />
                </Box>
            </Grid>
            <ScrollTabs
                data={getTabs()}
                activeIndex={corporate.activeIndex}
                onClick={handleChangeTab}
            />
            <TableComponent
                {...{
                    showChecked: 1,
                    headCellList,
                    list,
                    isTableLoading: isLoading,
                    handleSort,
                    isPagination: true,
                    allCount: totalCount,
                    handleDeleteAll: (value) => handleDelete(value.map((item) => item._id)),
                    changePageCallback,
                    isCurrentPageData: true,
                    page: pagPage - 1,
                    isShowSerialNum: true
                }}
            />
            <ComponyDetail
                isOpen={isOpen}
                onClose={handleClose}
                detail={detail}
            />
            <DeleteDialog
                open={isOpenDelete}
                title={''}
                onCancel={handleCancelDelete}
                onConfirm={() => handleDelete(deleteIds)}
            />
        </Paper>
    );
}