import { useEffect, useState } from 'react';
import { LockOutlined, Person2Outlined, Settings } from '@mui/icons-material';
import ScrollTabs, { ActiveTabProps } from '../../components/tab/scroll-tabs.component';
import AccountDetail from './account-detail';
import SafeDetail from './safe-detail';
import SettingDetail from './setting-detail';
import LSM from '../../modules/cache/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../../modules/cache/local-storage.constant';
import { User } from '../../models';
import apiManage from '../../request';
import _ from 'lodash';
import { mainStore } from '../../stores';
import { useTranslation } from 'react-i18next';
import { CustomerLoading } from '../../components/loading/loading';

export default function UserPage() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)
    // tab切换
    const [activeTab, setActiveTab] = useState<ActiveTabProps>({ activeIndex: 0, activeValue: 'account' });
    // 用户详细数据
    const [userDetail, setUserDetail] = useState<User>({})

    useEffect(() => {
        getData()
    }, [])


    /**
     * 获取用户数据
     */
    const getData = async () => {
        setLoading(true)
        const ACCOUNT = LSM.get(LOCAL_STORAGE_KEYS.ACCOUNT) || {};
        // 当前账号信息
        const result = await apiManage.get_user_info_id(ACCOUNT._id)
        if (result && !result.error) {
            setUserDetail(result)
            const newAccount = _.pick(result, 'name', 'gender', 'birthday', 'email', 'avatar');
            const value = {
                ...ACCOUNT,
                ...newAccount
            }
            // 更新缓存
            LSM.set({
                path: LOCAL_STORAGE_KEYS.ACCOUNT, value
            });
            // 更新state
            mainStore.updateAccount(value)
        }
        setLoading(false)
    }

    /**
     * 设置tab
     * @param value 
     */
    const handleChangeTab = (value: ActiveTabProps) => {
        setActiveTab(value)
    }

    /**
     * 渲染tabs
     * @param value 
     * @returns 
     */
    const renderTab = (value: string) => {
        switch (value) {
            case 'account':
                return (
                    <AccountDetail
                        userDetail={userDetail}
                        updateUserDetail={getData} />
                )
            case 'setting':
                return (
                    <SettingDetail
                        userDetail={userDetail}
                        updateUserDetail={getData}
                    />
                )
            case 'safe':
                return (
                    <SafeDetail
                        userDetail={userDetail}
                    />
                )
            default: return <div></div>
        }
    }

    // tab设置
    const staffPanel = [
        {
            id: 'account',
            title: t('general_text_account'),
            icon: <Person2Outlined />
        },
        {
            id: 'setting',
            title: t('setting_page_title_setting'),
            icon: <Settings />
        },
        {
            id: 'safe',
            title: t('general_text_security'),
            icon: <LockOutlined />
        },
    ]

    // tab设置
    const adminPanel = [
        {
            id: 'account',
            title: t('general_text_account'),
            icon: <Person2Outlined />
        },
        {
            id: 'safe',
            title: t('general_text_security'),
            icon: <LockOutlined />
        },
    ]



    return (
        <div>
            {loading && <CustomerLoading />}
            <ScrollTabs
                data={userDetail.type === 'ADMIN' ? adminPanel : staffPanel}
                activeIndex={activeTab.activeIndex}
                onClick={handleChangeTab}
            />
            {renderTab(activeTab.activeValue)}
        </div>
    )
}