import Highlight from 'react-highlight';
import DialogComponent from '../../components/dialog/dialog.component';
import ScrollTabs from '../../components/tab/scroll-tabs.component';
import { useState } from 'react';


export default function LogDetail({ isOpen, handleClose, detail }: Readonly<Props>) {
    const [activeTab, setActiveTab] = useState(0)

    /**
     * 切换tab
     * @param value 
     */
    const handleChangeTab = (value: number) => {
        setActiveTab(value)
    }

    // tab设置
    const config = {
        panel: [
            {
                id: 'request',
                title: 'Request',
            },
            {
                id: 'response',
                title: 'Response',
            },
        ]
    }

    /**
    * 渲染tabs
    * @param value 
    * @returns 
    */
    const renderTab = (value: number) => {
        switch (value) {
            case 0:
                return (
                    <div>
                        API
                        <Highlight className='json' >
                            {detail.url}
                        </Highlight>
                        Query
                        <Highlight className='json' >
                            {formatJson(detail.request)}
                        </Highlight>
                    </div>

                )
            case 1:
                return (
                    <Highlight className='json' >
                        {formatJson(detail.response)}
                    </Highlight>
                )
            default: return <div></div>
        }
    }

    /**
     * 把object转成json string
     * @param obj 
     */
    const formatJson = (obj: object) => {
        try {
            return JSON.stringify(obj, null, 2)
        } catch {
            return ''
        }
    }

    return (
        <DialogComponent
            maxWidth='sm'
            heightSize="medium"
            open={isOpen}
            onClose={handleClose}
            sx={(theme) => ({
                '& .hljs': theme.palette.mode === 'dark' ? {
                    color: '#abb2bf',
                    background: '#282c34',
                } : {},
                '& .hljs-attr': theme.palette.mode === 'dark' ? {
                    color: '#d19a66',
                } : {},
                '& .hljs-string': theme.palette.mode === 'dark' ? {
                    color: '#98c379',
                } : {}
            })}
        >
            <ScrollTabs
                data={config.panel}
                activeIndex={activeTab}
                onClick={(value) => handleChangeTab(value.activeIndex)}
            />

            {renderTab(activeTab)}
        </DialogComponent >
    )
}

type Props = {
    isOpen: boolean;
    handleClose: () => void;
    detail: LogDetailProps;
}

export type LogDetailProps = {
    url: string;
    request: object,
    response: object
};