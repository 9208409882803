import { Autocomplete, Box, Chip, MenuItem, TextField, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import i18n from '../../i18n';
import { CancelSharp } from '@mui/icons-material';
import ButtonComponent from '../button/button.component';

/**
 * 多重选择组件
 * @param props
 * @returns
 */
export default function SelectInputMultiple(props: SelectInputMultipleProps) {
    const { label, options, onChange, value, fullSelect, limitTagsNum } = props;
    // 下拉框选择的值
    const onSelectChange = (value: Option[]) => {
        if (onChange && typeof onChange === 'function') onChange(value);
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <Autocomplete
                autoHighlight
                id='autocomplete-select'
                size="small"
                // clearOnBlur
                fullWidth
                value={value}
                openOnFocus
                // inputValue={''}
                noOptionsText={i18n.t('general_text_no_data')}
                onChange={(event, value) => {
                    onSelectChange(value);
                }}
                options={options}
                isOptionEqualToValue={(option, value) => {
                    return option === value
                }}
                multiple
                limitTags={limitTagsNum ?? 4}
                ChipProps={{
                    variant: 'outlined',
                    color: 'primary',
                }}
                filterSelectedOptions
                disableCloseOnSelect
                getOptionLabel={option => option.label}
                renderOption={(props, option, state) => (
                    <MenuItem
                        {...props}
                        key={option.value}
                        value={option.value}
                        selected={state.selected}
                    >
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            {
                                option?.icon &&
                                <div style={{ marginTop: 5 }}>
                                    {option.icon}
                                </div>
                            }
                            <Typography
                                variant='inherit'
                                style={{
                                    marginLeft: 5,
                                }}
                            >
                                {option.label}
                            </Typography>
                        </Box>
                    </MenuItem>
                )}
                renderInput={params => {
                    return (
                        <Box
                            // className={fullSelect ? classes.root : ''}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            style={{ position: 'relative' }}
                        >
                            <TextField
                                {...params}
                                variant="outlined"
                                label={typeof label === 'string' ? i18n.t(label as string) : label}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ minWidth: 200 }}
                                autoComplete='off'
                                inputProps={{
                                    ...params.inputProps,
                                }}
                            />
                            {fullSelect &&
                                <ButtonComponent
                                    variant='outlined'
                                    sx={{
                                        position: 'absolute', right: 74, top: 6, padding: '0 15px',
                                        height: '28px !important',
                                        minHeight: '0 !important'
                                    }}
                                    iconBtn={false}
                                    handleClick={(event) => {
                                        event.stopPropagation()
                                        onSelectChange?.(options)
                                    }}
                                >
                                    {i18n.t('menu_dish_maker_page_text_select_all')}
                                </ButtonComponent>
                            }
                        </Box>
                    )
                }}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((item, index) => {
                        const { label, icon } = item;
                        return (
                            <Chip
                                avatar={icon}
                                label={label}
                                size={'small'}
                                sx={(theme) => {
                                    return {
                                        '.MuiChip-deleteIcon': {
                                            color: theme.palette.primary.light,
                                            '&:hover': {
                                                color: theme.palette.primary.main
                                            }
                                        }
                                    }
                                }}
                                style={{ color: '#7367f0', borderColor: '#7367f0' }}
                                deleteIcon={(<CancelSharp />)}
                                variant='outlined'
                                {...getTagProps({ index })}
                                key={item.value}
                            />
                        );
                    })
                }
            />
        </Box >

    )
}

export type Option = {
    label: string;
    value: string;
    icon?: JSX.Element;
}
export type SelectInputMultipleProps = {
    // 多选框数组
    options: Option[];
    // 输入框标签
    label?: string | ReactNode;
    // 输入框最多显示的标签数
    limitTagsNum?: number;
    onChange?: ((option: Option[]) => void);
    fullSelect?: boolean;
    value?: Option[];
}
