import { useTranslation } from 'react-i18next';
import DialogComponent from '../../components/dialog/dialog.component';
import { dateFormat } from '../../constants/tool';
import i18n from '../../i18n';
import { GybBillInfo } from '../../models';
import { Grid, Typography } from '@mui/material';

export default function ComponyDetail({
    detail,
    isOpen,
    onClose
}: Readonly<Props>) {
    const { t } = useTranslation();

    // 表单数据
    const formData = [
        {
            key: 'description',
            label: t('compony_page_text_company_name')
        },
        {
            key: 'zip_code',
            label: t('compony_page_text_postcode')
        },
        {
            key: 'city',
            label: t('compony_page_text_city')
        },
        {
            key: 'province',
            label: t('compony_page_text_state')
        },
        {
            key: 'country',
            label: t('compony_page_text_country')
        },
        {
            key: 'address',
            label: t('compony_page_text_address')
        },
        {
            key: 'vat_number',
            label: t('restaurant_info_page_text_vat_number')
        },
        {
            key: 'tax_code',
            label: t('compony_page_text_fiscal_code')
        },
        {
            key: 'PECDestinatario',
            label: t('compony_page_text_PEC')
        },
        {
            key: 'update_time',
            label: t('compony_page_text_update_time'),
            value: dateFormat(detail.update_time)
        },
    ]
    return (
        <DialogComponent
            maxWidth='sm'
            heightSize='small'
            open={isOpen}
            defaultHeaderProps={{
                title: i18n.t('operation_record_operation_object_details'),
            }}
            onClose={onClose}
        >
            <Grid container spacing={2}>
                {
                    formData.map((item) => (
                        <Grid item xs={12} md={6} key={item.key}>
                            <Typography
                                variant='body1'>
                                {item.label}
                            </Typography>
                            <Typography
                                variant='body1'
                                sx={(theme) => ({
                                    color: theme.palette.mode === 'light' ? '#2f2b3db3' : '',
                                    wordBreak: 'break-word'
                                })}
                            >
                                {item.value ?? detail[item.key as keyof GybBillInfo]}
                            </Typography>
                        </Grid>
                    ))
                }
            </Grid>


        </DialogComponent>
    )
}

type Props = {
    detail: GybBillInfo;
    isOpen: boolean;
    onClose: () => void;
}