import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { PaletteMode, ThemeOptions } from '@mui/material';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#7367f0',
    },
    secondary: {
      main: '#a8aaae',
    },
    error: {
      main: red.A400,
    },
  },
});

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode: mode || 'light',
    ...(mode === 'light'
      ? {
        primary: {
          main: '#7367f0',
        },
        secondary: {
          main: '#a8aaae',
        },
      }
      : {
        primary: {
          main: '#7367f0',
        },
        secondary: {
          main: '#a8aaae',
        },
        background: {
          default: '#25293C',
          paper: '#2f3349',
        },
        text: {
          primary: '#fff',
          // secondary: grey[500],
        },
      }),
  },
  components: {
    MuiTable: {
      styleOverrides: mode === 'light' ?
        {
          root: {
            ' & .MuiTableCell-stickyHeader': {
              background: 'rgb(246, 246, 247)'
            }
          }
        } : {
          root: {
            ' & .MuiTableCell-stickyHeader': {
              background: 'rgb(74, 80, 114)'
            }
          }
        }
    }
  }

});

export default theme;