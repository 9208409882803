import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink } from 'react-router-dom';
import { asyncRoutes } from '../../router/router.config';
import { Box, Typography } from '@mui/material';
import './drawer-list.scss'
import { observer } from 'mobx-react-lite';
import { mainStore } from '../../stores';
import picLogo from '../../assets/images/logo.png';
import { useTranslation } from 'react-i18next';


const DrawerListComponent = observer(({
    isOpen,
    handleChangeTitle
}: Readonly<Props>) => {
    const { t } = useTranslation();
    // 用户权限
    const accountType = mainStore.account?.type ?? 'NO_AUTH';
    return (
        <div className={isOpen ? 'nav' : 'nav nav-close'} >
            <Box display='flex' alignItems='center' sx={{
                padding: 2
            }}>
                <img className='logo' src={picLogo} alt='DataBubble' width="48" />
                <Typography
                    sx={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        display: isOpen ? 'block' : 'none'
                    }}>
                    DataBubble
                </Typography>
            </Box>
            <List>
                {asyncRoutes.map((item) => (
                    item.auth.includes(accountType) ?
                        < ListItem
                            key={item.id}
                            disablePadding
                            className='list-item'
                            onClick={() => handleChangeTitle(item.name)}
                        >
                            <NavLink
                                to={item.path}
                                className='nav-link'
                            >
                                <ListItemIcon className='list-icon'>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={t(item.name)}
                                    className='list-text'
                                />
                            </NavLink>
                        </ListItem>
                        :
                        null
                ))}
            </List>
        </div >
    )
})

type Props = {
    // 展开
    isOpen: boolean;
    // 修改标题
    handleChangeTitle: (value: string) => void;
}

export default DrawerListComponent