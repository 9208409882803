import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { ConditionType } from '../../components/form/search-key-input.component';
import { Account } from '../../models/abstract/account.entity';
import { MobxObjectsManager } from '../../components/mobx-objs/mobx-manager';
import apiManage from '../../request';
import _ from 'lodash';
import { HeadCell } from '../../components/table/table.component';
import GlobalNotification from '../../components/common/snackbar';
import i18n from '../../i18n';
import { getImageUrl } from '../../constants/tool-image';

export class AccountState {
    constructor() {
        makeAutoObservable(this);
    }


    public accountList: [] = [];
    public isTableLoading: boolean = false;
    public dataTotal: number = 0;
    // 员工详情弹窗
    public isOpenDetail: boolean = false;
    public detail: Account = {};
    public isOpenConfirmDetail: boolean = false;
    public confirmDetail: Account = {};
    // 排序参数
    public extraSortParams: { sort_key: string, sort_value: string, sort_control: number } = { sort_key: '', sort_value: '', sort_control: 0 };
    // 分页参数
    public perPage: number = 100;
    public page: number = 0;
    // 开始时间
    public startDate: Date | null = moment().startOf('d').toDate();
    public endDate: Date | null = moment().startOf('d').add(1, 'y').toDate();
    // 搜索条件
    public conditions: Array<ConditionType> = [];
    // 状态框
    public selectValue: string = '';
    public opTypeSelectValue: string = '';
    // 当前弹窗开启的员工id
    public currentAccountId: string = '';
    // 当前顾客查询电子发票记录
    public use_details: Array<any> = [];
    // 审核
    public start_date: Date | null = moment().startOf('d').toDate();
    public end_date: Date | null = moment().endOf('d').toDate();
    public disabledDate: boolean = true;

    // 删除二次确认弹窗
    public isOpenDelete = false;
    public deleteIds: string[] = []

    // 查询详情列表
    public isBillTableLoading: boolean = false;
    public disabledPassword: boolean = true;
    public password: string = '';
    public qRecordConditions: Array<ConditionType> = [];
    public loading: boolean = false;

    async init() {
        await this.getAccountData()
    }

    async getAccountData(extra?: Account & {
        // pagination
        pagination_page?: number;
        pagination_per_page?: number;
        // sort
        sort_key?: string;
        // sort_value?: 1 | -1;
        sort_value?: number;
        start_time?: string;
        end_time?: string;
    }) {
        try {
            this.setIsTableLoading(true);
            const searchObj: any = {};
            this.conditions.forEach((item) => {
                searchObj[item.key] = searchObj[item.key] ? _.concat(searchObj[item.key], item.value) : [item.value];
            })
            // 发送的参数
            const params = {
                pag_per_page: 99999,
                pag_page: 1,
                ...extra,
                ...searchObj
            }
            // 获取全部顾客
            const account = await apiManage.get_account_condition(params);
            if (!account.error) {
                this.accountList = account[0].dbResults;
                this.dataTotal = _.get(account, '[0].totalCount[0].count', 0);
            }
        } catch (error) {
            this.setIsTableLoading(false);
        }
        this.setIsTableLoading(false);
    }

    setIsTableLoading(loading: boolean) {
        this.isTableLoading = loading;
    }

    setIsBillTableLoading(loading: boolean) {
        this.isBillTableLoading = loading;
    }

    // 搜索
    setSelectKey() {

    }

    // 获取表头排序状态值
    getSortStatus(key: string) {
        const listSort = this.extraSortParams;
        if (key === listSort.sort_key) {
            return listSort.sort_control
        }
        return 1;
    }

    setCurrentAccountId(id: string) {
        this.currentAccountId = id;
    }

    setIsOpenConfirmDetail(val: boolean) {
        this.isOpenConfirmDetail = val;
    }

    /**
     * 打开员工详情弹窗
     * @param row 
     */
    async handleOpenDetail(row: Account) {
        // 获取当前顾客请求电子发票记录
        await this.setDetail(row);
        await this.getCurrentInvoice(row._id || '');
        this.start_date = moment(row.start_time, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').toDate();
        this.end_date = moment(row.end_time, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').toDate();
        this.currentAccountId = row._id || '';
        this.password = row.password || '';
    }

    setIsOpenDetail(val: boolean) {
        this.isOpenDetail = val;
    }

    async setDetail(row: Account) {
        row.avatar = getImageUrl(row.avatar ?? '');
        this.detail = row;
    }

    setConditions(val: Array<ConditionType>) {
        this.conditions = val;
    }

    setOpTypeSelectValue(val: string) {
        this.opTypeSelectValue = val;
    }


    /**
     * 表格排序  优化重写
     * @param headCell 排序表头字段
     * @var headCell.sortStatus 2表示正序 1表示倒序 0默认倒序
     * 1.排序
     * 2.保存参数
     * 3.改变头部状态
     * 排序状态：2表示正序 1表示倒序 0默认倒序
     */
    async _handleSort(headCell: HeadCell) {
        // 排序值 value 1 正序  -1 倒序
        let value = '',
            sortControl = 0;
        value = headCell.sortStatus === 0 ? '1' : headCell.sortStatus === 1 ? '1' : '-1';
        sortControl = headCell.sortStatus === 0 ? 1 : headCell.sortStatus === 1 ? 2 : 1;
        // 设置排序表头状态
        this.setSortParams({
            sort_key: sortControl === 0 ? '' : headCell.id,
            sort_value: value,
            sort_control: sortControl
        });
        // 请求参数
        const params = sortControl !== 0 ? {
            sort_key: 'create_time',
            sort_value: Number(value),
            // start_time: moment(startDate, 'YYYY-MM-DD HH:mm').toISOString(),
            // end_time: moment(endDate, 'YYYY-MM-DD HH:mm').toISOString(),
        } : {};
        // 请求数据
        await this.getAccountData(params);
    }

    setSortParams(param: {
        sort_key: string,
        sort_value: string,
        sort_control: number
    }) {
        this.extraSortParams = param;
    }

    /**
     * 关闭详情弹窗
     */
    handleClose() {
        this.setIsOpenDetail(false);
    }

    /**
     * 时间筛选
     */
    async handleChangePicker(dates: [Date | null, Date | null] | undefined) {
        this.setStartDate(dates?.[0] ?? null);
        this.setEndDate(dates?.[1] ?? null);
        if (dates) {
            if (dates[0] && dates[1]) {
                // 根据时间请求数据
                // await getAccountData({
                //     start_time: moment(dates?.[0]).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                //     end_time: moment(dates?.[1]).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') 
                // });
            }
        }
    }

    setStartDate(date: Date | null) {
        this.startDate = date;
    }

    setEndDate(date: Date | null) {
        this.endDate = date;
    }

    setUseDetails(val: Array<any>) {
        this.use_details = val;
        this.setIsOpenDetail(true);
    }

    async setDate(dates: [Date | null, Date | null] | undefined) {
        this.setDetailStartDate(dates?.[0] ?? null);
        this.setDetailEndDate(dates?.[1] ?? null);
        if (dates) {
            if (dates[0] && dates[1]) {
                // 根据时间请求数据
                // await getAccountData({
                //     start_time: moment(dates?.[0]).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                //     end_time: moment(dates?.[1]).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') 
                // });
            }
        }
    }

    setDetailStartDate(date: Date | null) {
        this.start_date = date;
    }

    setDetailEndDate(date: Date | null) {
        this.end_date = date;
    }

    editDisabled(val: boolean) {
        this.disabledDate = val;
    }

    async refreshKey() {
        try {
            const result = await apiManage.patch_user_refresh(this.detail._id || '');
            if (!result.error) {
                this.detail.key = result.secret_key;
                await this.getAccountData()
                return 'success'
            }
        } catch (error) {

        } 
    }
    /**
     * 更改审核时间
     */
    async auditAccountDate() {
        try {
            this.loading = true;
            const params = {
                start_time: moment(this.start_date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                end_time: moment(this.end_date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                _id: this.currentAccountId,
                audit: 'true'
            };
            const result = await apiManage.patch_account_audit(params);
            if (!result.error) {
                this.detail.key = result.secret_key;
                this.getAccountData();
                this.loading = false;
            }
        } catch (error) {

        }
    }

    /**
     * 审核通过
     */
    async auditAccount() {
        try {
            const params = {
                start_time: moment(this.startDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                end_time: moment(this.endDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                _id: this.currentAccountId,
                audit: 'true'
            };
            const result = await apiManage.patch_account_audit(params);
            if (!result.error) {
                this.setCurrentAccountId('');
                this.setIsOpenConfirmDetail(false);
                this.getAccountData();

            }
        } catch (error) {

        }
    }

    async getCurrentInvoice(_id: string) {
        try {
            const result = await apiManage.get_user_details(_id, {
                pag_page: 1,
                pag_per_page: 10000
            });

            if (!result.error) {
                this.setUseDetails(_.get(result, '[0].dbResults', []));
            }
        } catch (error) {

        }
    }

    /**
     * 打开删除二次确认弹窗
     * @param deleteId 
     */
    handleOpenDelete(deleteId: string[]) {
        this.deleteIds = deleteId
        this.isOpenDelete = true
    }

    // 关闭弹窗
    handleCancelDelete() {
        this.isOpenConfirmDetail = false
    }

    /**
     * 删除用户
     * @param deleteIds 
     */
    async handleDelete(deleteIds: string[]) {
        const result = await apiManage.delete_user(deleteIds)
        if (result && !result.error) {
            GlobalNotification.success(i18n.t('global_text_operate_success'));
            this.isOpenDelete = false;
            this.getAccountData()
        } else {
            GlobalNotification.error(i18n.t('global_text_operate_failure'));
        }
    }

    /**
     * 查询对象详情
     */
    async getAccountSearchData(val:  Array<ConditionType>) {
        this.setIsBillTableLoading(true);
        const searchObj: any = {};
        val.forEach((item) => {
            searchObj[item.key] = searchObj[item.key] ? _.concat(searchObj[item.key], item.value) : [item.value];
        })
        // 发送的参数
        const params = {
            pag_per_page: 99999,
            pag_page: 1,
            ...searchObj
        }
        try {
            // 获取日志
            const result = await apiManage.get_user_details(this.detail._id ?? '', {
                ...params
            });
            if (!result.error) {
                this.setUseDetails(_.get(result, '[0].dbResults', []));
            }
        } catch (error) {
            this.setIsBillTableLoading(false);
        }
        
        this.setIsBillTableLoading(false);
    }


    /**
     * 更改密码
     */
    async changePassword() {
        try {
            try {
                const result = await apiManage.patch_user_id(this.detail._id || '', { password: this.password });
                if (!result.error) {
                    await this.getAccountData()
                    return 'success'
                }
            } catch (error) {
    
            } 
        } catch (error) {

        }
    }

    editDisabledPassword(val: boolean) {
        this.disabledPassword = val;
    }

    onChange(val: string) {
        this.password = val;
    }

}


export const accountState = MobxObjectsManager.get(AccountState);