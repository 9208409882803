import { makeAutoObservable } from 'mobx';
import { Account } from '../models/abstract/account.entity';
import LSM from '../modules/cache/tool-local-storage';
import { LOCAL_STORAGE_KEYS } from '../modules/cache/local-storage.constant';

export class MainStore {
    // 用户
    account: Account = LSM.get(LOCAL_STORAGE_KEYS.ACCOUNT)

    constructor() {
        makeAutoObservable(this)
    }

    /**
     * 更新用户信息
     * @param value 
     */
    updateAccount(value: Account) {
        this.account = value;
    }
}

export const mainStore = new MainStore()