import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import DialogHeaderComponent, { DialogHeaderProps } from './dialog-header.component';
import { createStyles, makeStyles } from '@mui/styles';

// *********************
// Type & Props
// *********************

declare type DialogComponentProps = {
    // 对话框头部道具
    defaultHeaderProps?: DialogHeaderProps;
    //
    containerWidth?: string;
    // 对话框自定义样式
    style?: React.CSSProperties;
    // 对话框底部
    footer?: JSX.Element;
    // 点击图标或背景关闭
    onClose?: () => void;
    // 弹窗高度大小 'xs' | 'sm' | 'md' | 'lg'
    heightSize?: 'xs' | 'small' | 'medium' | 'large';
} & DialogProps

// 弹窗自定义样式
const useStyles = makeStyles(() =>
    createStyles({
        xs: {
            height: '360px',
        },
        small: {
            height: '480px',
        },
        medium: {
            height: '720px',
        },
        large: {
            height: '960px',
        },
    })
)

/**
 * 对话框组件
 * @param props
 * @returns
 */
function DialogComponent(props: DialogComponentProps) {

    // *********************
    // View
    // *********************
    const { onClose, open, defaultHeaderProps, children, heightSize, footer, ...otherProps } = props;

    const classes = useStyles();

    return (
        <Dialog
            style={props.style}
            aria-describedby={props['aria-describedby']}
            aria-labelledby={props['aria-labelledby']}
            disableEscapeKeyDown={props.disableEscapeKeyDown}
            fullScreen={props?.fullScreen}
            fullWidth={props.fullWidth === undefined ? true : props.fullWidth}
            maxWidth={props.maxWidth ?? false}
            onClose={onClose}
            open={open}
            PaperComponent={props.PaperComponent}
            PaperProps={props.PaperProps}
            scroll={props.scroll}
            TransitionComponent={props.TransitionComponent}
            transitionDuration={props.transitionDuration ?? {
                appear: 450,
                enter: 450,
                exit: 300
            }}
            TransitionProps={props.TransitionProps}
            className={props.className}
            classes={
                heightSize && { paper: classes[heightSize || 'large'] }
            }
            {...otherProps}
        >
            <DialogTitle sx={{ paddingRight: 3 }} id="confirmation-dialog-title">
                <DialogHeaderComponent
                    onClose={onClose}
                    {...defaultHeaderProps}
                />
            </DialogTitle>
            <DialogContent style={{ paddingTop: '8px' }}>
                {children}
            </DialogContent>
            <DialogActions className='dialog-footer'>
                {footer}
            </DialogActions>
        </Dialog>
    )
}

export default DialogComponent