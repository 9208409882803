import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ToolBarButton from './tool-bar-button.component';
import { Typography } from '@mui/material';
import './top-bar.scss';
import { useTranslation } from 'react-i18next';

function TopBarComponent({
    drawerWidth,
    handleMobileDrawerToggle,
    handleDrawerToggle,
    title
}: Readonly<Props>) {
    const { t } = useTranslation();
    return (
        <AppBar
            position='sticky'
            color='transparent'
            sx={{
                boxShadow: 'none'
            }}
            className='top-bar'
        >
            <Toolbar sx={(theme) => ({
                margin: '24px 24px 0 24px',
                background: theme.palette.mode === 'light' ? '#fff' : '#2f3349f2'
            })}
                className='tool-bar'>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleMobileDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>

                <IconButton
                    size="large"
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { xs: 'none', sm: 'flex' } }}
                >
                    {drawerWidth === 240 ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                <Typography variant='h5' sx={{ flex: 1 }}>{t(title)}</Typography>
                <ToolBarButton />
            </Toolbar>
        </AppBar >
    )
}

type Props = {
    // 侧边栏宽度
    drawerWidth: number;
    // 移动端开关
    handleMobileDrawerToggle: () => void;
    // 侧边栏宽度
    handleDrawerToggle: () => void;
    // 标题
    title: string;
}
export default TopBarComponent