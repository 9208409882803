import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { ConditionType } from '../../components/form/search-key-input.component';
import { MobxObjectsManager } from '../../components/mobx-objs/mobx-manager';
import { HeadCell } from '../../components/table/table.component';
import { validateCurrentDate } from '../../constants/tool';
import { InvoiceLog } from '../../models/invoice-log.entity';
import apiManage from '../../request';

export class LogState {
	constructor() {
		makeAutoObservable(this);
	}
	public isTableLoading: boolean = false;
	public dataTotal: number = 0;
	// 排序参数  0 不排序  1 降序 2 升序 
	public extraSortParams = { sort_key: 'create_time', sort_value: 0 };
	// 分页参数
	public perPage: number = 20;
	public page: number = 1;
	// 开始时间
	public startDate: Date | null = moment(validateCurrentDate().startTime, 'YYYY-MM-DD HH:mm').toDate();
	public endDate: Date | null = moment(validateCurrentDate().endTime, 'YYYY-MM-DD HH:mm').toDate();
	// 搜索条件
	public conditions: Array<ConditionType> = [];
	//类型   0 合作伙伴调用接口   1  调用税务信息接口   2  用户登录日志
	public opTypeSelectValue: string = '2';
	public logList: Array<InvoiceLog> = [];

	init() {
		// 排序参数  0 不排序  1 降序 2 升序
		this.extraSortParams = { sort_key: 'create_time', sort_value: 0 };
		// 分页参数
		this.perPage = 20;
		this.page = 1;
		// 开始时间
		this.startDate = moment(validateCurrentDate().startTime, 'YYYY-MM-DD HH:mm').toDate();
		this.endDate = moment(validateCurrentDate().endTime, 'YYYY-MM-DD HH:mm').toDate();
		// 搜索条件
		this.conditions = [];
		//类型   0 合作伙伴调用接口   1  调用税务信息接口   2  用户登录日志
		this.opTypeSelectValue = '2';
		this.getLogData()
	}

	/**
	 * 获取数据
	 * @returns 
	 */
	async getLogData() {
		this.setIsTableLoading(true);
		try {
			// 搜索
			const searchObj: any = {};
			this.conditions.forEach((item) => {
				searchObj[item.key] = searchObj[item.key] ? _.concat(searchObj[item.key], item.value) : [item.value];
			})
			// 排序条件 0 不排序  1 降序 2 升序
			const sortParams = {
				sort_key: (this.extraSortParams.sort_key === '' || this.extraSortParams.sort_value === 0) ? 'create_time' : this.extraSortParams.sort_key,
				sort_value: this.extraSortParams.sort_value === 2 ? 1 : -1,
			}
			// 发送的参数
			const params = {
				pag_per_page: this.perPage,
				pag_page: this.page,
				type: this.opTypeSelectValue,
				start_time: moment(this.startDate, 'YYYY-MM-DD HH:mm').toISOString(),
				end_time: moment(this.endDate, 'YYYY-MM-DD HH:mm').toISOString(),
				...searchObj,
				...sortParams
			}
			// 获取全部顾客
			const log = await apiManage.get_log_condition(params);
			if (!log.error) {
				this.setLogList(_.get(log, '[0].dbResults', []));
				this.setDataTotal(_.get(log, '[0].totalCount[0].count', 0));

			}
		} catch (error) {

		}

		return this.setIsTableLoading(false);
	}

	/**
	 * 时间筛选
	 */
	handleChangePicker(dates: [Date | null, Date | null] | undefined) {
		this.page = 1
		this.setStartDate(dates?.[0] ?? null);
		this.setEndDate(dates?.[1] ?? null);
		if (dates) {
			if (dates[0] && dates[1]) {
				// 根据时间请求数据
				this.getLogData();
			}
		}
	}


	/**
	 * 搜索
	 * @param conditions 
	 */
	handleSearch(conditions: ConditionType[]) {
		this.page = 1
		this.setConditions(conditions);
		this.getLogData();
	}


	/**
	 * 类型筛选
	 * @param val 
	 */
	handleChangeType(val: string) {
		this.conditions = []
		this.page = 1
		this.setOpTypeSelectValue(val)
		this.getLogData();
	}

	/**
	* 页码回调
	* @param params
	*/
	handleChangePage = (params: {
		page: number;
		curRowsPerPage: number;
	}) => {
		this.perPage = params.curRowsPerPage
		this.page = params.page + 1
		this.getLogData();
	}

	/**
	 * 表格排序
	 * @param headCell 
	 */
	_handleSort(headCell: HeadCell) {
		this.page = 1
		let newSortValue = 0;
		switch (headCell.sortStatus) {
			case 0: {
				newSortValue = 1;
				break;
			}
			case 1: {
				newSortValue = 2;
				break;
			}
			case 2: {
				newSortValue = 0;
				break;
			}
		}
		this.setSortParams({
			sort_key: headCell.id,
			sort_value: newSortValue
		})
		// 请求数据
		this.getLogData();
	}


	/**
	 * 获取表头排序状态值  0 不排序  1 降序 2 升序
	 * @param key 
	 * @returns 
	 */
	getSortStatus(key: string) {
		return this.extraSortParams.sort_key === key ? this.extraSortParams.sort_value : 0
	}


	setStartDate(val: Date | null) {
		this.startDate = val;
	}

	setEndDate(val: Date | null) {
		this.endDate = val;
	}

	setSortParams(param: {
		sort_key: string,
		sort_value: number,
	}) {
		this.extraSortParams = param;
	}

	setConditions(val: Array<ConditionType>) {
		this.conditions = val;
	}

	setOpTypeSelectValue(val: string) {
		this.opTypeSelectValue = val;
	}
	setIsTableLoading(val: boolean) {
		this.isTableLoading = val;
	}

	setLogList(list: Array<InvoiceLog>) {
		this.logList = list;
	}

	setDataTotal(val: number) {
		this.dataTotal = val;
	}

}

export const logState = MobxObjectsManager.get(LogState);
