import React, { memo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import './scss/dialog.style.scss'


// *********************
// Type
// *********************

export declare type DialogHeaderProps = {
    // 对话框标题
    title?: string;
    // 标题图标
    TitleIcon?: JSX.Element;
    // 是否隐藏对话框关闭图标
    isHiddenCloseIcon?: boolean;
    // 关闭对话框
    onClose?: () => void;
    rightNode?: JSX.Element;
}

/**
 * 对话框头部组件
 * @param props 
 * @returns 
 */
function DialogHeader(props: DialogHeaderProps) {

    // *********************
    // View
    // *********************

    const { TitleIcon, title, isHiddenCloseIcon, onClose, rightNode } = props;
    return (
        <div className='dialog-header'>
            <div className='header-title-box'>
                {
                    TitleIcon &&
                    TitleIcon
                }
                {
                    title &&
                    <span className='header-title'>
                        {title}
                    </span>
                }
            </div>
            <div className='header-action-box'>
                {
                    rightNode
                }
                {
                    rightNode ? <div className='line' /> : null
                }
                {
                    !isHiddenCloseIcon &&
                    <IconButton
                        className='header-close-icon'
                        onClick={() => onClose && onClose()}
                    >
                        <CloseIcon/>
                    </IconButton>
                }
            </div>
        </div>
    )
}

export default memo(DialogHeader)