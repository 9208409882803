
import loginApi from './api/login';
import accountApi from './api/account';
import logApi from './api/log';
import settingApi from './api/setting';
import companyApi from './api/company';
import userApi from './api/user';

// 接口统一暴露
const apiManage = {
    ...loginApi,
    ...accountApi,
    ...logApi,
    ...settingApi,
    ...companyApi,
    ...userApi
}



export default apiManage;