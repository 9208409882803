import { Box, Chip, Grid, Tooltip, Typography } from '@mui/material';
import CardView from '../../components/card/card-view.component';
import { SearchKeyInput, SearchKeyInputItems } from '../../components/form/search-key-input.component';
import TableComponent, { HeadCell } from '../../components/table/table.component';
import CommonInput from '../../components/form/common-input.component';
import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import './index.scss';
import { logState } from './log-state';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import LogDetail, { LogDetailProps } from '../user-page/log-detail';
import ButtonComponent from '../../components/button/button.component';
import { Log } from '../../models/log.entity';

export default observer(function LogPage() {
	const { t } = useTranslation();

	React.useEffect(() => {
		logState.init();
		SearchKeyInputRef.current?.setSearchKey('name')
		return () => { }
	}, [])

	// 日志详情弹窗
	const [isOpenDetail, setIsOpenDetail] = useState(false)
	const [logDetail, setLogDetail] = useState<LogDetailProps>({ url: '', request: {}, response: {} })

	// 搜索
	const SearchKeyInputRef = React.useRef<{
		setSelectKey: (val: string) => void;
		setSearchKey: (val: string) => void;
	}>();

	// 表格头部
	const tableHeadCell: Array<HeadCell & { hidden?: boolean }> = [
		{
			id: 'name',
			label: t('general_text_name'),
			hidden: logState.opTypeSelectValue === '1',
			renderFunction: (row) => {
				return `${_.get(row, 'belong_user.name', '')}`
			},
		},
		{
			id: 'name',
			label: t('setting_page_text_third_party_platform'),
			hidden: logState.opTypeSelectValue !== '1',
			renderFunction: (row) => {
				return `${_.get(row, 'parameter_call.type', '')}`
			},
		},
		{
			id: 'phone',
			label: t('general_text_phone'),
			hidden: logState.opTypeSelectValue === '1',
			renderFunction: (row) => {
				return <>{`${_.get(row, 'belong_user.phone_prefix')} ${_.get(row, 'belong_user.phone')}`}</>
			},
		},
		{
			id: 'ip',
			label: 'IP',
			hidden: logState.opTypeSelectValue === '1',
			renderFunction: (row) => {
				return <>{row?.ip ?? ''}</>
			}
		},
		{
			id: 'create_time',
			label: t('restaurant_extensions_page_text_create_time'),
			sortStatus: logState.getSortStatus('create_time'),
			renderFunction: (row) => {
				return <>{moment(row?.create_time).format('YYYY-MM-DD HH:mm') ?? ''}</>
			}
		},
		{
			id: 'status',
			label: t('general_text_state'),
			renderFunction: (row) => {
				const failInfo = typeof row.details !== 'string' ? JSON.stringify(row.details) : row.details
				return (
					<Chip
						size='small'
						label={(
							<Tooltip title={failInfo} >
								<Typography sx={{ color: '#fff', fontSize: '13px' }}>{row?.status ? t('general_text_success') : t('general_text_failed')}</Typography>
							</Tooltip>)}
						color={row?.status ? 'success' : 'error'}
					/>
				)
			}
		},
		{
			id: 'details',
			hidden: logState.opTypeSelectValue === '2',
			label: t('general_text_details'),
			renderFunction: (row: Log) => {
				return (
					<ButtonComponent
						action="VIEW"
						handleClick={() => handleOpen({
							url: _.get(row, 'parameter_call.url', '') || '',
							request: row.parameter_call || {},
							response: row.parameter_return || {}
						})}
					/>
				)
			}
		}
	];

	// 表格操作类型下拉框 静态数据
	const operationTypeList: { value: string; label: string }[] = [
		{
			value: '0',
			label: t('log_page_text_log_type_partner')
		}, {
			value: '1',
			label: t('log_page_text_log_type_tax')
		}, {
			value: '2',
			label: t('log_page_text_log_type_log')
		}
	];

	// 查找表格条件下拉框 静态数据    0 合作伙伴调用接口  
	const filterList0: SearchKeyInputItems[] = [
		{
			value: 'name',
			label: t('general_text_name'),
			type: 'TEXT',
		}, {
			value: 'phone',
			label: t('general_text_phone'),
			type: 'TEXT'
		}, {
			value: 'parameter_vat',
			label: t('restaurant_info_page_text_vat_number'),
			type: 'TEXT'
		}
	];
	// 查找表格条件下拉框 静态数据   2 用户登录日志
	const filterList2: SearchKeyInputItems[] = [
		{
			value: 'name',
			label: t('general_text_name'),
			type: 'TEXT',
		}, {
			value: 'phone',
			label: t('general_text_phone'),
			type: 'TEXT'
		}
	];

	// 查找表格条件下拉框 静态数据  1 调用税务信息接口 
	const filterList1: SearchKeyInputItems[] = [
		{
			value: 'parameter_type',
			label: t('setting_page_text_third_party_platform'),
			type: 'SELECT',
			options: [{
				value: 'cerved',
				label: 'cerved'
			}, {
				value: 'gyb',
				label: 'gyb'
			}, {
				value: 'validate-vat',
				label: 'validate-vat'
			}]
		}
	];

	const filterList = [filterList0, filterList1, filterList2]

	/**
	 * 切换日志类型 
	 * 0 合作伙伴调用接口   
	 * 1  调用税务信息接口   
	 * 2  用户登录日志
	 * @param e 
	 */
	const handleChangeType = (e: { target: { value: string } }) => {
		SearchKeyInputRef.current?.setSelectKey('-1')
		if (e.target.value === '1') {
			SearchKeyInputRef.current?.setSearchKey('parameter_type')
		} else {
			SearchKeyInputRef.current?.setSearchKey('name')
		}
		logState.handleChangeType(e.target.value)
	}


	// 渲染页面组件
	const renderContent = (): JSX.Element => {
		return (
			<Box className={'container'}>
				<Grid container justifyContent="space-between" sx={{ mb: 3 }}>
					<Grid item>
						<Box>
							<SearchKeyInput
								ref={SearchKeyInputRef}
								items={filterList[Number(logState.opTypeSelectValue)]}
								conditions={logState.conditions}
								onSearch={(val) => logState.handleSearch(val)}
								showConditionsBox
							/>
						</Box>
					</Grid>
					<Grid item container xs justifyContent="flex-end">
						<Box className="table-top-tool" sx={{ width: 300 }}>
							<CommonInput
								inputType={'DATE_TIME_RANGE'}
								label={t('restaurant_extensions_page_text_create_time')}
								rangeHandleChangePicker={(val) => logState.handleChangePicker(val)}
								rangeValue={[logState.startDate, logState.endDate]}
							/>
						</Box>
						{/* 下拉框 */}
						<Box sx={{ width: 150, marginLeft: 1 }}>
							<CommonInput
								inputType={'SELECT'}
								select={true}
								items={operationTypeList ?? []}
								size={'small'}
								fullWidth
								value={logState.opTypeSelectValue}
								onChange={handleChangeType}
							/>
						</Box>
					</Grid>
				</Grid>
				<TableComponent
					{...{
						page: logState.page - 1,
						isShowSerialNum: true,
						showChecked: 0,
						list: logState.logList,
						// 开启表格自定义页脚
						isUseCustomPagination: true,
						headCellList: tableHeadCell.filter((item) => !item.hidden),
						isTableLoading: logState.isTableLoading,
						handleSort: (headCell) => logState._handleSort(headCell),
						isPagination: true,
						allCount: logState.dataTotal,
						isUseFold: true,
						extraClass: 'extraTable',
						changePageCallback: (params) => logState.handleChangePage(params),
						isCurrentPageData: true,
					}}
				/>
			</Box>
		)
	}

	/**
	 * 关闭日志详情弹窗
	 */
	const handleClose = () => {
		setIsOpenDetail(false)
	}

	/**
	 * 打开日志详情弹窗
	 * @param value 
	 */
	const handleOpen = (value: LogDetailProps) => {
		setLogDetail(value)
		setIsOpenDetail(true)
	}


	return (
		<Box>
			<Box>
				<CardView
					{...{
						className: '',
						contentRender: renderContent()
					}}
				/>
			</Box>
			<LogDetail
				isOpen={isOpenDetail}
				handleClose={handleClose}
				detail={logDetail}
			/>
		</Box>
	);
})


// 详情内容
export interface DetailContent {
	// 请求状态码
	status?: string;
	// 消息
	message?: string;
	// 具体详情
	details?: string;
	// 请求地址
	url?: string;
	// 请求参数
	vat?: string;
	// 国家
	country?: string;
	// 请求方式
	type?: string;
}


