import { AxiosRequestConfig } from 'axios';
import http, { HttpSuccessOrErrorType, ListRequestParams, ListResults } from '../http';
import { Log } from '../../models/log.entity';

const logApi = {
	/**
	 * 获取当前登录的信息
	 */
	async get_log_condition(params: ListRequestParams<Log>, config?: AxiosRequestConfig): Promise<HttpSuccessOrErrorType<ListResults<Log>[]>> {
		return await http.get(`/log/condition`,
			{
				params: {
					...params
				},
				...config
			}
		)
	}
}

export default logApi