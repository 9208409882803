import moment from 'moment';
import i18n from '../i18n';
import GlobalNotification from '../components/common/snackbar';

/**
 * 时间校验 不能超过31天
 * @param startDate 
 * @param endDate 
 * @returns 
 */
export const dateValidate = (startDate: string | Date, endDate: string | Date) => {
    const valid = moment(endDate).isAfter(moment(startDate));
    // 计算日期跨度
    const dayDiff = moment(endDate).diff(moment(startDate), 'day') < 32;
    if (!valid) {
        GlobalNotification.error(i18n.t('business_analysis_page_text_end_time_cannot_earlier_than_start_time'));
    } else if (!dayDiff) {
        // 日期跨度大于31天，抛出错误
        GlobalNotification.error(i18n.t('business_analysis_page_the_date_interval_cannot_exceed_31'))
    }
    return valid && dayDiff
}


// 校验当天日期逻辑
export const validateCurrentDate = () => {
    let startTime: string;
    let endTime: string;
    // 现在的时间
    const presentDate = new Date();
    /**
     * 根据现在的时刻  即商家进入系统初始化的时刻  判断获取数据是从哪一天开始获取
     * 1. 商家在04:00 - 23:59 时，看的是当天 04:00 - 次日 04:00 的数据
     * 2. 商家在00:00 - 03:59 时，看的是昨日 04:00 - 当日 04:00 的数据
     */
    if (moment(presentDate).isBefore(moment('04:00', 'HH:mm'))) {
        // 开始时间是00:00 - 03:59，取昨日 04:00 - 当日 04:00 的数据
        startTime = moment(presentDate).subtract(1, 'day').format('YYYY-MM-DD 04:00');
        endTime = moment(presentDate).format('YYYY-MM-DD 04:00');
    } else {
        // 开始时间是04:00 - 23:59，取当天 04:00 - 次日 04:00 的数据
        startTime = moment(presentDate).format('YYYY-MM-DD 04:00');
        endTime = moment(presentDate).add(1, 'day').format('YYYY-MM-DD 04:00');
    }
    return {
        startTime,
        endTime
    }
}

/**
 * 生成大写字母
 * @returns 
 */
export const generateBig = () => {
    const str = [];
    for (let i = 65; i < 91; i++) {
        str.push(String.fromCharCode(i));
    }
    return str;
}

/**
 * 时间格式化
 * @param date 
 * @param format 
 * @returns 
 */
export const dateFormat = (date: string = '', format = 'YYYY-MM-DD HH:mm:ss') => {
    return date ? moment(date).format(format) : ''
}

/**
 * 时间格式化
 * @param date 
 * @returns 
 */
export const dateFormatUTC = (date: string = '') => {
    return date ? moment(date).utc().format() : ''
}

/**
 * 时间格式化
 * @param date 
 * @param format 
 * @returns 
 */
export const dateFormatLocal = (date: string = '', format = 'YYYY-MM-DD HH:mm:ss') => {
    return date ? moment(date, moment.HTML5_FMT.DATETIME_LOCAL_MS).format(format) : ''
}