import React from 'react';
import { Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Tooltip, Typography, } from '@mui/material';
import { Person2Outlined, ExitToAppOutlined, DarkMode, LightMode, Translate } from '@mui/icons-material';
import i18n from '../../i18n';
import { GetThemeMode } from '../../themes/tool';
import BasePopover from '../popover/base-popover.component';
import TranslatePopover from '../popover/translate-popover';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { mainStore } from '../../stores';
import AvatarBadge from '../avatar/avatar-badge';
import { cleanAccountCache } from '../../constants/tool-login';
import { getImageUrl } from '../../constants/tool-image';
import { ColorModeContext } from '../../App';

const ToolBarButton = observer(() => {
    const navigate = useNavigate();
    const [accountAnchorEl, setAccountAnchorEl] = React.useState<HTMLElement | null>(null);
    const [translateAnchorEl, setTranslateAnchorEl] = React.useState<HTMLElement | null>(null);
    const themeMode = GetThemeMode();
    // 用户信息
    const account = mainStore.account ?? {};
    const name = account.name ?? '';
    const phone =`${account.phone_prefix} ${account.phone}`;
    const avatar = getImageUrl(account.avatar ?? '')

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAccountAnchorEl(event.currentTarget);
    };

    const colorMode = React.useContext(ColorModeContext);

    return (
        <Box sx={{ display: 'flex', color: 'rgb(158, 158, 158)' }}>
            <Box marginRight={4} sx={{ display: 'flex' }}>
                <Tooltip title={i18n.t('login_page_text_language')} className='tool-button'>
                    <IconButton
                        color='inherit'
                        onClick={(event) => {
                            setTranslateAnchorEl(event.currentTarget);
                        }}>
                        <Translate />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box marginRight={4} sx={{ display: 'flex' }}>
                <Tooltip title={i18n.t('general_text_switch_theme')} className='tool-button'>
                    <IconButton
                        color='inherit'
                        onClick={() => {
                            colorMode.toggleColorMode()
                        }}>
                        {themeMode === 'dark' ? <LightMode /> : <DarkMode />}
                    </IconButton>
                </Tooltip>
            </Box>

            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <AvatarBadge
                        name={name}
                        avatar={avatar}
                    />
                </IconButton>
            </Tooltip>

            <BasePopover
                open={Boolean(accountAnchorEl)}
                anchorEl={accountAnchorEl}
                onClose={() => { setAccountAnchorEl(null) }}
                id='account-popover'
            >
                <Box minWidth={200}>
                    <List  >
                        <ListItem >
                            <ListItemText
                                primary={
                                    <Box display='flex'>
                                        <AvatarBadge
                                            name={name}
                                            avatar={avatar}
                                        />
                                        <Box marginLeft={'0.625rem'}>
                                            <Typography variant='body1' fontWeight={600}>
                                                {name}
                                            </Typography>
                                            <Typography variant='body2' >
                                                {phone}
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                                color='primary'
                            />
                        </ListItem>
                        <Divider style={{ marginBottom: 8 }} />
                        <ListItemButton disableGutters={false}
                            onClick={() => {
                                navigate('/user');
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Box display='flex'>
                                        <Person2Outlined style={{ marginRight: '8px', fontSize: 18 }} />
                                        <Typography >
                                            {i18n.t('main_page_text_person_page')}
                                        </Typography>
                                    </Box>
                                }
                                color='primary'
                            />
                        </ListItemButton>
                        <ListItemButton disableGutters={false}
                            onClick={() => {
                                navigate('/login');
                                cleanAccountCache()
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Box display='flex'>
                                        <ExitToAppOutlined style={{ marginRight: '8px', fontSize: 18 }} />
                                        <Typography >
                                            {i18n.t('main_page_button_sign_out')}
                                        </Typography>
                                    </Box>
                                }
                                color='primary'
                            />
                        </ListItemButton>
                    </List>
                </Box>
            </BasePopover>
            <TranslatePopover
                anchorEl={translateAnchorEl}
                onClose={() => {
                    setTranslateAnchorEl(null);
                }}
            />
        </Box>
    )
})


export default ToolBarButton
