import { Box, Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import i18n from '../../i18n';
import CardView from '../../components/card/card-view.component';
import TableComponent, { HeadCell } from '../../components/table/table.component';
import { Account } from '../../models/abstract/account.entity';
import ButtonComponent from '../../components/button/button.component';
import { ConditionType, SearchKeyInput, SearchKeyInputItems } from '../../components/form/search-key-input.component';
import CommonInput from '../../components/form/common-input.component';
import AccountDetail from './account-detail';
import { AddTask } from '@mui/icons-material';
import DialogComponent from '../../components/dialog/dialog.component';
import WaitButton from '../../components/button/wait-button.component';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { accountState } from './account-state';
import DeleteDialog from '../../components/dialog/delete-dialog.component';

export default observer(function AccountPage() {

    // *****************
    // Extra Function
    // *****************

    // 搜索
    const SearchKeyInputRef = React.useRef<{ setSelectKey: (val: string) => void; }>();

    const { t } = useTranslation();
    React.useEffect(() => {
        accountState.setConditions([]);
        accountState.init();
        return () => { }
    }, [])

    // 表格头部
    const tableHeadCell: HeadCell[] = [
        {
            id: 'operator', label: t('general_text_phone'),
            renderFunction: (row: Account) => {
                return <div>{`${row?.phone_prefix ?? ''} ${row?.phone ?? ''}`}</div>
            },
        },
        {
            id: 'name', label: i18n.t('general_text_name'),
            renderFunction: (row: Account) => {
                return <>{row?.name ?? ''}</>
            }
        },
        {
            id: 'email', label: i18n.t('general_text_email'),
            renderFunction: (row: Account) => {
                return <>{row?.email ?? ''}</>
            }
        },
        {
            id: 'create_time', label: i18n.t('restaurant_extensions_page_text_create_time'), sortStatus: accountState.getSortStatus('create_time'),
            renderFunction: (row: Account) => {
                return <>{moment(row?.create_time).format('YYYY-MM-DD HH:mm') ?? ''}</>
            }
        },
        {
            id: 'audit', label: i18n.t('general_text_state'),
            renderFunction: (row: Account) => {
                return <>{row?.audit === 'true' ? <Typography style={{ color: 'green' }}>{t('setting_page_text_status_approved')}</Typography> : <Typography style={{ color: 'red' }}>{t('setting_page_text_status_to_be_reviewed')}</Typography>}</>
            }
        },
        {
            id: '', label: i18n.t('operation_record_operation_object_details'),
            renderFunction: (row) => {
                return (
                    <div>
                        {
                            row?.audit === 'false' ?
                                <ButtonComponent
                                    async
                                    variant='outlined'
                                    handleClick={async () => {
                                        accountState.setCurrentAccountId(row._id ?? '');
                                        accountState.setIsOpenConfirmDetail(true);
                                    }}
                                    titleText={i18n.t('partner_page_text_approve')}
                                >
                                    <AddTask className='iconColor' />
                                </ButtonComponent>
                                :
                                <ButtonComponent
                                    action="VIEW"
                                    handleClick={async () => await accountState.handleOpenDetail(row)}
                                />
                        }
                        <ButtonComponent
                            variant='outlined'
                            action="DELETE"
                            color="secondary"
                            handleClick={() => accountState.handleOpenDelete(row._id ? [row._id] : [])}
                        />
                    </div>
                )
            }
        },
    ];

    // 表格操作类型下拉框 静态数据
    const operationTypeList: { value: string; label: string }[] = [
        {
            value: 'false',
            label: i18n.t('setting_page_text_status_to_be_reviewed')
        }, {
            value: 'true',
            label: i18n.t('setting_page_text_status_approved')
        }
    ];

    // 查找表格条件下拉框 静态数据
    const filterList: SearchKeyInputItems[] = [
        {
            value: 'phone',
            label: i18n.t('general_text_phone'),
            type: 'TEXT'
        }, {
            value: 'email',
            label: i18n.t('general_text_email'),
            type: 'TEXT'
        }, {
            value: 'name',
            label: i18n.t('general_text_name'),
            type: 'TEXT'
        }, {
            value: 'audit',
            label: i18n.t('general_text_state'),
            type: 'SELECT',
            options: operationTypeList
        }
    ];




    // 渲染页面组件
    const renderContent = (): JSX.Element => {
        return (
            <Box className={'container'}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                        <Box>
                            <SearchKeyInput
                                ref={SearchKeyInputRef}
                                items={filterList}
                                conditions={accountState.conditions}
                                onSearch={async (conditions: ConditionType[]) => {
                                    accountState.setConditions(conditions);
                                    await accountState.getAccountData();
                                }}
                                showConditionsBox
                            />
                            {/* 授权类型选择下拉框 */}
                            {
                                accountState.selectValue === 'operation_type' &&
                                <Box marginLeft={2}>
                                    <CommonInput
                                        inputType={'SELECT'}
                                        select={true}
                                        items={operationTypeList ?? []}
                                        size={'small'}
                                        fullWidth
                                        value={accountState.opTypeSelectValue}
                                        onChange={async (e) => {
                                            const selectVal = e.target.value;
                                            accountState.setOpTypeSelectValue(selectVal);
                                            await accountState.getAccountData({
                                                'audit': selectVal,
                                            });
                                        }}
                                    />
                                </Box>
                            }
                        </Box>
                    </Grid>
                    {/* <Grid item>
                        <Box className="table-top-tool" sx={{ width: 300 }}>
                            <CommonInput
                                inputType={'DATE_TIME_RANGE'}
                                label={i18n.t('创建时间')}
                                rangeHandleChangePicker={(dates) => handleChangePicker(dates)}
                                rangeValue={[startDate, endDate]}
                            />
                        </Box>
                    </Grid> */}
                </Grid>
                <Grid item xs={12}>
                    <TableComponent
                        {...{
                            showChecked: 1,
                            list: accountState.accountList,
                            // 开启表格自定义页脚
                            isUseCustomPagination: true,
                            headCellList: tableHeadCell || [],
                            isTableLoading: accountState.isTableLoading,
                            handleSort: (headCell) => {
                                accountState._handleSort(headCell);
                            },
                            isPagination: true,
                            allCount: accountState.dataTotal,
                            isUseFold: true,
                            extraClass: 'extraTable',
                            // changePageCallback: (params) => { changePageCallback(params)},
                            // isCurrentPageData: true,
                            handleDeleteAll: (value) => accountState.handleDelete(value.map((item) => item._id))
                        }}
                    />
                </Grid>
                <AccountDetail
                    isOpen={accountState.isOpenDetail}
                    onClose={() => { accountState.handleClose() }}
                    detail={accountState.detail}
                />
                <DeleteDialog
                    open={accountState.isOpenDelete}
                    title={''}
                    onCancel={accountState.handleCancelDelete}
                    onConfirm={() => accountState.handleDelete(accountState.deleteIds)}
                />
                <DialogComponent
                    maxWidth='sm'
                    open={accountState.isOpenConfirmDetail}
                    onClose={() => { accountState.setIsOpenConfirmDetail(false) }}
                    defaultHeaderProps={{
                        title: i18n.t('partner_page_text_approve'),
                        onClose: () => { accountState.setIsOpenConfirmDetail(false) }
                    }}
                    footer={
                        <div>
                            <Button
                                color='primary'
                                variant='outlined'
                                onClick={() => {
                                    accountState.setIsOpenConfirmDetail(false)
                                }}
                            >{i18n.t('general_button_cancel')}</Button>

                            <Box marginLeft={4} display="inline-block">
                                <WaitButton
                                    color='primary'
                                    variant='contained'
                                    onClick={async () => {
                                        await accountState.auditAccount()
                                    }}
                                    titleText={''}
                                >
                                    {i18n.t('general_button_confirm')}
                                </WaitButton>
                            </Box>
                        </div>
                    }
                >
                    <Grid item xs={12}>
                        <Box className="table-top-tool" sx={{ width: 300 }}>
                            <CommonInput
                                inputType={'DATE_TIME_RANGE'}
                                label={i18n.t('setting_page_text_authorization_time')}
                                rangeHandleChangePicker={(dates) => { accountState.handleChangePicker(dates) }}
                                rangeValue={[accountState.startDate, accountState.endDate]}
                            />
                        </Box>
                    </Grid>
                </DialogComponent>
            </Box>
        )
    }

    return (
        <Box>
            <Box>
                <CardView
                    {...{
                        className: '',
                        contentRender: renderContent()
                    }}
                />
            </Box>
        </Box>
    );
})