import BaseInput, { BaseTextFieldProps } from './base-input.component';
import MaterialUIPickers, { MaterialUIPickersProps, PickerType } from './picker.component';
import CountrySelect from './country-select.component';
import SelectInputMultiple, { Option, SelectInputMultipleProps } from './select-input-multiple';
import { ForwardedRef, forwardRef } from 'react';
import PhoneInputComponent from './phone-input.component';
import PasswordInput from './password-input.component';

// 表单通用组件
export default forwardRef(function CommonInput(props: {
    inputType?: CommonInputType;
    handleChangePicker?: MaterialUIPickersProps['handleChange'];
    onClosePicker?: MaterialUIPickersProps['onClosePicker'];
    handleChange?: (val: any) => void;
    country?: string;
    handleChangeCountry?: (value: string) => void;
    rangeValue?: [Date | null, Date | null];
    minDate?: Date | null;
    maxDate?: Date | null;
    filterTime?(date: Date): boolean;
    multipleProps?: SelectInputMultipleProps;
    rangeHandleChangePicker?: (value?: [Date | null, Date | null], error?: boolean) => void;
} & (Partial<BaseTextFieldProps>), ref: ForwardedRef<HTMLDivElement>) {
    const { inputType = 'INPUT', allowClear, onClosePicker, handleChangeCountry, minDate, maxDate, filterTime, multipleProps, handleChangePicker, rangeHandleChangePicker, ...otherProps } = props;
    switch (inputType) {
        case 'INPUT': {
            return (
                <BaseInput
                    allowClear={allowClear}
                    ref={ref}
                    {...otherProps as BaseTextFieldProps}
                />
            )
        }
        case 'SELECT_MUL': {
            return (
                <SelectInputMultiple
                    value={otherProps.value as unknown as Option[]}
                    label={otherProps.label}
                    {...multipleProps as SelectInputMultipleProps}
                />
            )
        }
        // 选择输入框
        case 'SELECT': {
            return (
                <BaseInput
                    allowClear={allowClear}
                    select={true}
                    ref={ref}
                    {...otherProps as BaseTextFieldProps}
                />
            )
        }
        // 国家区号选择器
        case 'COUNTRY_SELECT': {
            const value = props.value as string || '39';
            return (
                <CountrySelect
                    countryVal={value}
                    disabled={props.disabled ?? false}
                    onChange={handleChangeCountry}
                    size={otherProps?.InputProps?.size}
                />
            )
        }
        // 区号+号码
        case 'PHONE': {
            return (
                <PhoneInputComponent
                    phone={props.value as string}
                    phonePrefix={props.country ?? '39'}
                    handleChangeCountry={handleChangeCountry}
                    {...otherProps as BaseTextFieldProps}
                />
            )
        }
        // 密码
        case 'PASSWORD': {
            return (
                <PasswordInput
                    {...otherProps as BaseTextFieldProps}
                />
            )
        }
        // 时间 日期 选择器
        // case 'DATE': {
        //     const pickerType = props.inputType as PickerType;
        //     const value = props.value as unknown as Date;
        //     return (
        //         props.handleChangePicker ? <MaterialUIPickers
        //             pickerType={pickerType}
        //             value={value}
        //             onClosePicker={onClosePicker}
        //             handleChange={props.handleChangePicker}
        //             textFieldProps={{
        //                 inputProps: {
        //                     size: otherProps.size,
        //                 },
        //                 ...otherProps
        //             }}
        //         /> : <></>
        //     )
        // }
        // 时间 日期 日期时间范围 选择器
        case 'DATE':
        case 'TIME':
        case 'DATE_TIME':
        case 'DATE_TIME_RANGE':
        case 'TIME_RANGE':
        case 'DATE_RANGE':
            {
                const pickerType = props.inputType as PickerType;
                const value = props.value as unknown as Date;
                return (
                    handleChangePicker || rangeHandleChangePicker ? <MaterialUIPickers
                        pickerType={pickerType}
                        value={value || null}
                        onClosePicker={onClosePicker}
                        handleChange={(value) => { handleChangePicker?.(value) }}
                        rangeHandleChangePicker={rangeHandleChangePicker}
                        textFieldProps={{
                            inputProps: {
                                size: otherProps.size,
                            },
                            ...otherProps
                        }}
                        rangeValue={props.rangeValue}
                        minDate={minDate}
                        maxDate={maxDate}
                        filterTime={filterTime}
                    /> : <></>
                )
            }
        default: {
            return (
                <BaseInput
                    allowClear={allowClear}
                    {...otherProps as BaseTextFieldProps}
                />
            )
        }
    }
})

export type CommonInputType = 'INPUT' | 'SELECT_MUL' | 'SELECT' | 'SEARCH' | 'COUNTRY_SELECT' | PickerType | 'PHONE' | 'PASSWORD'
