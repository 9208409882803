import React from 'react';
import { Box, Typography } from '@mui/material';
import { Visibility, Delete, Image, AddAPhoto, NoPhotography } from '@mui/icons-material';
import CropperDialog from './cropper-dialog';
import { useState } from 'react';
import AvatarDialog from './avatar-dialog';
// import DialogComponent from '../dialog/alert-dialog.component';
import i18n from '../../i18n';
import { createStyles, makeStyles } from '@mui/styles';
import { LIMIT_FILE_SIZE } from '../../constants/base.contants';
import GlobalNotification from '../common/snackbar';
import ButtonComponent from '../button/button.component';
/**
 * 头像组件
 * @param props
 * @returns
 */
export default function AvatarCard(props: {
    // 名称
    name?: string;
    // 头像
    avatarUrl?: string;
    // 大图
    avatarUrlMax?: string;
    // 更换头像
    onChangeAvatarUrl?: (avatarUrl: string, file: File) => void;
    // 删除头像
    onDelete?: () => void;
    // 是否禁用上传图片
    isDisabledUpload?: boolean;
}) {
    // 图片加载失败
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [, setAlertOpen] = useState(false);
    const [preViewOpen, setPreviewOpen] = useState(false);
    const [imgSrc, setImgSrc] = useState('')
    const [imgFile, setImgFile] = useState<File | null>(null)
    const { avatarUrl, avatarUrlMax, name, isDisabledUpload } = props;
    const classes = useStyles();
    // 上传图片
    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files
            && event.target.files.length > 0) {
            // 图片过大弹出提示
            const file = event.target.files[0];
            // const size = file.size;
            // if ((size / 1024) > 500) {
            //     GlobalNotification.error('图片不能大于500kb');
            //     return;
            // }
            if (file.size > LIMIT_FILE_SIZE) {
                GlobalNotification.error(i18n.t('input_rule_text_please_enter_file_size_cannot_exceed_x', { x: `${LIMIT_FILE_SIZE / 1024}k` }));
                return;
            }
            const url = await convertImageFile(file);
            // 打开图片编辑弹窗
            setOpen(true);
            setImgSrc(url);
            setImgFile(file)
        }
    }
    // 将图片文件转成图片
    const convertImageFile = async (file: File) => {
        const imgDataUrl = await new Promise<string>((resolve, reject) => {
            // 创建读取文件对象
            const reader = new FileReader();
            // 读取文件
            reader.readAsDataURL(file);
            // 读取成功
            reader.onloadend = ((event) => {
                resolve(event.target?.result as string)
            })
            // 读取失败
            reader.onerror = ((event) => {
                reject(event)
            })
        })
        return imgDataUrl
    }

    // 关闭图片编辑器
    const onClose = () => {
        setOpen(false);
    }
    // 确定
    const confirm = (avatar: string, file: Blob) => {
        const fileName = imgFile?.name || '';
        const fileType = imgFile?.type || '';
        const newFile = new File([file], fileName, {
            type: fileType
        })
        props.onChangeAvatarUrl?.(avatar, newFile);
        onClose();
    }

    // 图片地址解析失败时处理
    const onError = () => {
        setError(true)
    }

    React.useEffect(() => {
        setError(false)
    }, [avatarUrl])

    const inputId = `icon-button-file${Math.random()}`;

    return (
        <>
            <Box justifyContent='center' alignItems='center' display='flex' flexDirection='column'>
                {
                    avatarUrl && (!error) ?
                        <Box className={classes.imgBox}>
                            <div className={classes.avatarUpload}>
                                <img
                                    alt=""
                                    className={classes.avatar}
                                    src={avatarUrl}
                                    onLoad={() => {
                                        setSuccess(true)
                                    }}
                                    onError={onError} />
                            </div>
                            <Box display={success ? 'block' : 'none'}>
                                <Box className={classes.iconBox}>
                                    <ButtonComponent
                                        titleText={i18n.t('general_text_preview_picture')}
                                        handleClick={() => {
                                            setPreviewOpen(true)
                                        }}
                                    >
                                        <Visibility />
                                    </ButtonComponent>
                                    {
                                        props.onChangeAvatarUrl && !isDisabledUpload &&
                                        <UploadImageIcon
                                            handleUpload={handleUpload}
                                        />
                                    }
                                    {props.onDelete && <ButtonComponent
                                        color='secondary'
                                        titleText={i18n.t('general_text_delete')}
                                        handleClick={() => {
                                            setAlertOpen(true)
                                        }}
                                    >
                                        <Delete />
                                    </ButtonComponent>
                                    }
                                </Box>
                            </Box>
                        </Box>
                        :
                        !isDisabledUpload ?
                            <div>
                                <input
                                    key={avatarUrl}
                                    accept="image/*"
                                    className={classes.fileInput}
                                    id={inputId}
                                    type="file"
                                    onClick={(e: any) => {
                                        /**
                                         * 每次点击时清空上传图片的路径值
                                         * 避免在没有裁剪保存的情况下，关闭再次上传同一张图时，
                                         * 导致获取的图片路径值相同而打不开裁剪框
                                         */
                                        e.target.value = '';
                                    }}
                                    onChange={handleUpload} />
                                <label htmlFor={inputId}>
                                    <Box className={`${classes.avatarBox} base-shadow`} padding="10px">
                                        <Box className={`${classes.avatarUpload} `}
                                            sx={(theme) => {
                                                return {
                                                    backgroundColor: theme.palette.background.default,
                                                }
                                            }}>
                                            <AddAPhoto />
                                            <Typography variant={'subtitle1'} className='text'>{i18n.t('general_text_upload_photo')}</Typography>
                                        </Box>
                                    </Box>

                                </label>
                            </div>
                            :
                            <div>
                                <Box className={`${classes.avatarBox} base-shadow`} padding="10px">
                                    <Box className={classes.avatarUpload} style={{ cursor: 'default' }} >
                                        <NoPhotography />
                                        <Typography variant={'subtitle1'} className='text'>{i18n.t('general_text_no_photo')}</Typography>
                                    </Box>
                                </Box>
                            </div>

                }
                {name && <Typography className={classes.avatarName}>{name}</Typography>}
            </Box>
            {
                open &&
                <CropperDialog
                    open={true}
                    onClose={onClose}
                    confirm={confirm}
                    imgSrc={imgSrc}
                />
            }
            {
                preViewOpen &&
                <AvatarDialog
                    open={true}
                    onClose={() => {
                        setPreviewOpen(false)
                    }}
                    data={[
                        {
                            imgSrc: avatarUrlMax || avatarUrl || '',
                            imgName: ''
                        }
                    ]}
                />
            }
            {/* {
                alertOpen &&
                <DialogComponent
                    open={true}
                    title={i18n.t('general_text_delete')}
                    onCancel={() => {
                        setAlertOpen(false)
                    }}
                    onConfirm={() => {
                        setAlertOpen(false);
                        props.onDelete?.();
                    }}
                />
            } */}
        </>
    )
}

const useStyles = makeStyles(() =>
    createStyles({
        fileInput: {
            display: 'none'
        },
        avatarUpload: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            color: '#637381',
            borderRadius: '4px',
            cursor: 'pointer'
        },
        avatar: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            cursor: 'pointer'
        },
        avatarBox: {
            width: '140px',
            height: '140px',
            borderRadius: '4px',
            // border: '1px solid rgba(145, 158, 171, 0.32)',
        },
        imgBox: {
            width: '140px',
            height: '140px',
            borderRadius: '4px',
            // border: '1px solid rgba(145, 158, 171, 0.32)',
            overflow: 'hidden',
            position: 'relative',
        },
        iconBox: {
            justifyContent: 'space-around',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            display: 'flex',
            opacity: 0,
            background: '#4191ff26',
            '&>span': {
                marginLeft: 0,
            },
            '&:hover': {
                opacity: 1,
            }
        },
        icon: {
            width: '20px',
            height: '20px',
            cursor: 'pointer',
            marginLeft: '6px',
            color: '#4191ff',
            backgroundColor: '#4191ff26',
        },
        btn: {
            color: '#4191ff',
            backgroundColor: '#4191ff26',
            borderRadius: '4px',
            display: 'flex',
            cursor: 'pointer',
            width: '40px',
            height: '40px',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
                color: '#4191ff',
                backgroundColor: '#b8e0fe',
            }
        },
        btnLabel: {
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            marginLeft: '-8px'
        },
        avatarName: {
            padding: '5px 0',
            width: '140px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'center'
        }
    })
)


// 上传图片图标
export function UploadImageIcon(props: {
    handleUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    const classes = useStyles();
    const imgId = `icon-button-file-icon${Math.random()}`;
    const inputRefs = React.useRef<HTMLInputElement | null>(null);
    return (
        <>
            <input
                ref={(refs) => inputRefs.current = refs}
                key={imgId}
                accept="image/*"
                className={classes.fileInput}
                id={imgId}
                type="file"
                onChange={props.handleUpload}
            />
            <ButtonComponent
                titleText={i18n.t('general_text_change_image')}
                handleClick={() => { inputRefs?.current?.click?.() }}
            >
                <Image />
            </ButtonComponent>
        </>
    )
}

// 上传图片并编辑
export function UploadImage(props: {
    // 上传图片
    onChange: (file: File) => void;
}) {
    const [open, setOpen] = useState(false);
    const [imgSrc, setImgSrc] = useState('')
    const [imgFile, setImgFile] = useState<File | null>(null)
    // 上传图片
    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files
            && event.target.files.length > 0) {
            // 图片过大弹出提示
            const file = event.target.files[0];
            // const size = file.size;
            // if ((size / 1024) > 500) {
            //     GlobalNotification.error('图片不能大于500kb');
            //     return;
            // }
            const url = await convertImageFile(file);
            // 打开图片编辑弹窗
            setOpen(true);
            setImgSrc(url);
            setImgFile(file)

        }
    }
    // 将图片文件转成图片
    const convertImageFile = async (file: File) => {
        const imgDataUrl = await new Promise<string>((resolve, reject) => {
            // 创建读取文件对象
            const reader = new FileReader();
            // 读取文件
            reader.readAsDataURL(file);
            // 读取成功
            reader.onloadend = ((event) => {
                resolve(event.target?.result as string)
            })
            // 读取失败
            reader.onerror = ((event) => {
                reject(event)
            })
        })
        return imgDataUrl
    }

    // 关闭图片编辑器
    const onClose = () => {
        setOpen(false);
    }
    // 确定
    const confirm = (_avatar: string, file: Blob) => {
        const fileName = imgFile?.name || '';
        const fileType = imgFile?.type || '';
        const newFile = new File([file], fileName, {
            type: fileType
        })
        props.onChange(newFile);
        onClose();
    }
    return (
        <>
            <UploadImageIcon
                handleUpload={handleUpload}
            />
            {
                open &&
                <CropperDialog
                    open={true}
                    onClose={onClose}
                    confirm={confirm}
                    imgSrc={imgSrc}
                />
            }
        </>
    )
}