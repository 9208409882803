import { GybBillInfo } from '../../models'
import http, { HttpSuccessOrErrorType, ListRequestParams, ListResults, OkPacket } from '../http'

const api = {

    /**
     * 获取所有企业信息列表
     * @returns 
     */
    async get_compony_condition(params: ListRequestParams<GybBillInfo>): Promise<HttpSuccessOrErrorType<ListResults<GybBillInfo>[]>> {
        return await http.get(`location/check/condition`, {
            params
        })
    },

    /**
     * 批量删除企业
     * @param ids 
     * @returns 
     */
    async delete_compony(ids: string[]): Promise<HttpSuccessOrErrorType<OkPacket>> {
        return await http.delete(`location/check`, {
            data: {
                _id: ids
            }
        })
    }

}

export default api