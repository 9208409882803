import { Fab, Fade, useScrollTrigger } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function ScrollTop() {
    const trigger = useScrollTrigger();

    const handleClick = (event: any) => {
        const anchor = (
            (event.target as HTMLDivElement).ownerDocument || document
        ).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView(true);
        }
    };

    return (
        <Fade in={trigger}>
            <Fab
                color="primary"
                size="small"
                aria-label="scroll back to top"
                onClick={(event) => handleClick(event)}
                sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                <KeyboardArrowUpIcon />
            </Fab>
        </Fade>
    );
}
