import _ from 'lodash';
import CommonInput, { CommonInputType } from '../form/common-input.component';
import CardView from './card-view.component';
import { Box, FilledInputProps, Grid, InputBaseProps, InputProps, OutlinedInputProps, TextFieldProps, Theme, Typography, TypographyProps } from '@mui/material';
import AvatarCard from '../avatar/avatart-card.component';
import TableComponent, { TableComponentProps } from '../table/table.component';
import BASE_CHECK_TYPE from '../../constants/regular.constant';
import i18n from '../../i18n';
import { MaterialUIPickersProps } from '../form/picker.component';
import { createStyles, makeStyles } from '@mui/styles';
import GlobalNotification from '../common/snackbar';
import { SelectInputMultipleProps } from '../form/select-input-multiple';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputBg: theme?.palette?.mode === 'light' ?
            {
                '& .MuiOutlinedInput-input': {
                    backgroundColor: '#f4f4f5'
                },
            }
            :
            {
                '& .MuiOutlinedInput-input': {
                    backgroundColor: '#f4f4f533'
                }
            },
        inputBgTransparent: {
            '& .MuiOutlinedInput-input': {
                backgroundColor: 'transparent'
            }
        },
    })
);

/**
 * 卡片表单
 */
export default function CardFrom({ config, data }: { config: CardFormConfig[], data: { [key: string]: any } }) {
    return (
        <Grid container spacing={3}>
            {
                config?.map((configItem: CardFormConfig) => {
                    const subCards = configItem.subCardConfig ? configItem.subCardConfig : [configItem];
                    return (
                        <Grid item xs={configItem.xs ?? 12} md={configItem.md ?? 6} key={configItem.key} hidden={configItem.hidden} id={`card-from-${configItem.key}`}>
                            {subCards?.map((item, index) => {
                                return (
                                    <CardView
                                        key={item.key}
                                        title={typeof item.title === 'string' ? i18n.t(item.title) : item.title}
                                        actionRender={item.actionRender}
                                        // className='card-content-class'
                                        titleTypographyProps={{
                                            variant: 'h6',
                                            ...item.titleTypographyProps as any
                                        }}
                                        style={{ overflow: 'unset', marginTop: index === 0 ? 0 : 12 }}
                                        contentRender={(<CardFormChildren form={item} data={data} />)}
                                    />
                                )
                            })}

                        </Grid>
                    )
                })
            }
        </Grid >
    )
}

// 表单组件
export function CardFormChildren({ form, data }: { form: CardFormConfig, data: { [key: string]: any } }) {
    const classes = useStyles();
    return (
        <Grid container rowSpacing={form.rowSpacing ?? 0} columnSpacing={form.columnSpacing ?? 0} >
            {
                form.children && form.children.length > 0 ? form?.children?.map((subItem) => {
                    const onChange = (value: any) => {
                        if (subItem.inputType === 'SELECT_MUL') {
                            const newValue = value.map((newItem: any) => (newItem.value));
                            subItem?.handleMultipleChange?.(newValue)
                        }
                        else {
                            subItem?.onChange?.(value);
                        }
                    }
                    const selectValue: any[] = [];
                    if (subItem.inputType === 'SELECT_MUL') {
                        const v: string[] = _.get(data, subItem.key, []);
                        subItem?.multipleProps?.options?.forEach((iitem) => {
                            if (iitem.value && v && v.includes(iitem.value)) {
                                selectValue.push(iitem);
                            }
                        })
                    }

                    return (
                        // 子级组件
                        <Grid item xs={subItem.xs ?? 0} md={subItem.md ?? 0} key={subItem.key} hidden={subItem.hidden}>
                            {
                                // 头像组件
                                subItem.comType === 'AVATAR' ?
                                    <AvatarCard
                                        avatarUrl={data.avatar ?? subItem.fillback}
                                        onChangeAvatarUrl={subItem.handleChangeAvatar}
                                        isDisabledUpload={subItem.isDisabledUpload ?? false}
                                    />
                                    :
                                    // 输入框组件
                                    subItem.comType === 'INPUT' ?
                                        <Box>
                                            {
                                                subItem.subTitle &&
                                                <Box sx={{ marginBottom: 3, display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Typography display={'flex'} minWidth={'fit-content'}>{i18n.t(subItem.subTitle)}</Typography>
                                                    <Box sx={{ width: '100%' }}>
                                                        {subItem.additionDescription?.()}
                                                    </Box>
                                                </Box>
                                            }
                                            <CommonInput
                                                inputType={subItem.inputType as CommonInputType}
                                                fullWidth
                                                multiline={subItem.multiline}
                                                rows={subItem.multiRowsNumber}
                                                required={subItem.required ?? false}
                                                regularType={subItem.regularType}
                                                label={subItem.label}
                                                type={subItem.contentType ?? ''}
                                                size={subItem.InputProps?.size ?? 'small'}
                                                disabled={subItem.disabledInput ?? false}
                                                items={subItem.items}
                                                value={subItem.inputType === 'SELECT_MUL' ? selectValue : (_.get(data, subItem.key) ?? '')}
                                                InputProps={subItem.InputProps}
                                                inputProps={subItem.inputProps}
                                                multipleProps={{
                                                    options: subItem?.multipleProps?.options as any || [],
                                                    onChange
                                                }}
                                                handleChangePicker={subItem?.handleChangePicker}
                                                handleChangeCountry={subItem?.handleChangeCountry}
                                                onChange={onChange}
                                                className={`${subItem?.inputIsEdited ? classes.inputBg : classes.inputBgTransparent}`}
                                                sx={(theme) => {
                                                    // 判断多行文本域编辑后的输入框底色
                                                    return theme.palette.mode === 'light' ? {
                                                        backgroundColor: subItem?.multiline && subItem?.inputIsEdited ? '#f4f4f5' : 'transparent'
                                                    } : { backgroundColor: subItem?.multiline && subItem?.inputIsEdited ? '#f4f4f533' : 'transparent' }
                                                }}
                                                isEmpty={subItem?.isEmpty}
                                                maxDate={subItem?.maxDate}
                                            />
                                        </Box>
                                        :
                                        // 表格组件
                                        subItem.comType === 'TABLE' && subItem.tableConfig ?
                                            <TableComponent
                                                {...subItem.tableConfig}
                                            />
                                            : subItem.comType === 'CUSTOMCHILDREN' ?
                                                subItem.customSubChildren : null
                            }
                        </Grid>
                    )
                    // }
                }) : null
            }
        </Grid>
    )
}

export type CardFormConfig = {
    // key
    key: string;
    // 卡片标题
    title: string | JSX.Element;
    // 行距
    rowSpacing?: number;
    // 列间距
    columnSpacing?: { [key: string]: number };
    // 标题样式
    titleTypographyProps?: TypographyProps;
    // 小于xs显示占位默认12
    xs?: number;
    // 小于md显示占位默认6
    md?: number;
    // 按钮
    actionRender?: JSX.Element;
    // 隐藏组件
    hidden?: boolean;
    // 卡片里的内容  包裹的子元素
    children?: CardFormChildrenConfig[],
    // 子卡片
    subCardConfig?: CardFormConfig[];
}

export type CardFormChildrenConfig = {
    additionDescription?: () => JSX.Element;
    fillback?: any;
    // key
    key: string;
    // 组件类型
    comType: 'INPUT' | 'AVATAR' | 'TABLE' | 'CUSTOMCHILDREN';
    // 子元素标题
    subTitle?: string;
    // 隐藏组件
    hidden?: boolean;
    // 输入框标题
    label?: string;
    // 输入框类型
    inputType?: CommonInputType;
    // 选择下拉框
    items?: {
        value: string | number;
        label: string;
        disabled?: boolean;
    }[];
    // 禁用输入框
    disabledInput?: boolean;
    // 是否禁用图片上传
    isDisabledUpload?: boolean;
    // 是否为多行文本域
    multiline?: boolean;
    // 多行文本限制显示的高度
    multiRowsNumber?: number;
    // 是否必填 *带星号
    required?: boolean;
    // 正则类型
    regularType?: string;
    // 输入框输入内容类型  number | phone | text 等等
    contentType?: string;
    // 占位宽度
    xs?: number;
    // 占位宽度
    md?: number;
    onChange?: TextFieldProps['onChange'];
    country?: string;
    handleChangeCountry?: (val: string) => void;
    handleChangeAvatar?: (avatarUrl: string, file: File) => void;
    // 操作日期输入框
    handleChangePicker?: MaterialUIPickersProps['handleChange'];
    // 列表配置
    tableConfig?: TableComponentProps;
    // 自定义的子元素的孩子
    customSubChildren?: JSX.Element;
    // 是否编辑过  最新编辑获取的值与原始值不一致 为true 表示编辑过  用于输入框bg置灰
    inputIsEdited?: boolean;
    // isEmpty：文本为空时是否校验 (0表示不校验)，保存时才校验必填且为空的值
    isEmpty?: boolean;
    // 日期选择器最大日期
    maxDate?: Date;
    // 多选属性
    multipleProps?: SelectInputMultipleProps;
    //
    handleMultipleChange?: (value: string[]) => void;
    // 输入框定其他props InputProps
    InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
    inputProps?: InputBaseProps['inputProps'];
}


/**
 * 检查符合输入框正则校验
 * - 配合CardForm组件
 */
export function checkCardFromRule({ config, data }: { config: CardFormConfig[], data: { [key: string]: string } }) {
    const errorList: string[] = [];
    const errorListKeys: string[] = [];
    try {
        const rules: { cardKey: string, key: string; inputLabel: string; regularType?: string; required?: boolean }[] = [];

        // 获取输入框所有规则
        config?.forEach((item) => {
            item?.children?.forEach((childrenItem) => {
                if (childrenItem.regularType || childrenItem.required) {
                    rules.push({ cardKey: item.key, key: childrenItem.key, inputLabel: childrenItem.label || '', regularType: childrenItem.regularType, required: childrenItem.required })
                }
            })
            // 子card
            item?.subCardConfig?.forEach?.((subItem) => {
                subItem.children?.forEach((childrenItem) => {
                    if (childrenItem.regularType || childrenItem.required) {
                        rules.push({ cardKey: item.key, key: childrenItem.key, inputLabel: childrenItem.label || '', regularType: childrenItem.regularType, required: childrenItem.required })
                    }
                })
            })
        })
        // 找出不符合的规则
        rules?.forEach((item) => {
            const value = data[item.key];
            const regulars = item.regularType && BASE_CHECK_TYPE[item.regularType];
            // 必填没有值
            if ((item.required) && !value) {
                const requiredRegular = BASE_CHECK_TYPE['required'];
                const text = i18n.t(requiredRegular?.helperText, requiredRegular?.i18nRule);
                errorList.push(`${i18n.t(item.inputLabel)} ${text}`);
                errorListKeys.push(item.cardKey);
            }
            // 有规则,有值
            if (regulars && String(value ?? '')) {
                const text = i18n.t(regulars?.helperText, regulars?.i18nRule);
                if (!regulars?.regular.test(String(value) || String(value))) {
                    errorList.push(`${i18n.t(item.inputLabel)} ${text}`);
                    errorListKeys.push(item.cardKey);
                }
            }
        })
        if (errorListKeys?.length) {
            // 消息条
            GlobalNotification.error(i18n.t(errorList[0]));
            // 定位
            errorListKeys?.length && cardFromScrollToView(errorListKeys[0]);
        }

    } catch (error) { }
    return errorList;
}

/**
 * 滚动到窗口可视位置
 * - 配合CardForm组件
 */
export function cardFromScrollToView(id: string) {
    document.getElementById(`card-from-${id}`)?.scrollIntoView();
}