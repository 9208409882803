import React from 'react';
import { Box, List, ListItemText, Typography, ListItemButton, Card } from '@mui/material';
import BasePopover from './base-popover.component';
import i18n from 'i18next';

export default function TranslatePopover(props: {
    anchorEl: HTMLElement | null;
    onClose: () => void;
}) {
    const { anchorEl, onClose } = props;

    // 国际化资源文件,命名按照 ISO 639-1
    const languageOption = [
        { value: 'zh', label: '简体中文' },
        { value: 'it', label: 'Italia' },
        { value: 'en', label: 'English' },
        { value: 'es', label: 'España' }
    ];
    // 当前选中语言
    const [language, setLanguage] = React.useState(i18n.language);

    return (
        <BasePopover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            id='translate-popover'
        >
            <Card >
                <List >
                    {languageOption.map((item) => {
                        return (
                            <ListItemButton
                                key={item.value}
                                disableGutters={false}
                                selected={item.value === language}
                                onClick={() => {
                                    setLanguage(item.value)
                                    i18n.changeLanguage(item.value);
                                    onClose()
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Box className='FCC'>
                                            <Typography >
                                                {item.label}
                                            </Typography>
                                        </Box>
                                    }
                                    color='primary'
                                />
                            </ListItemButton>
                        )
                    })}
                </List>
            </Card>
        </BasePopover>
    )
}