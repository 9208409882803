import { Box, IconButton, InputAdornment } from '@mui/material';
import React, { useImperativeHandle } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import './scss/search-input.style.scss';
import BaseInput from './base-input.component';


// *****************
// TYPE
// *****************
type searchProps = {
    // 搜索调用
    onSearch: (value: string) => void;
    // 支持清除输入框内容
    allowClear?: boolean;
    onChange?: (value: string) => void;
    className?: string;
};

// *****************
// 搜索框
// *****************
function SearchComponent(
    // 输入框属性
    props: searchProps,
    ref: React.ForwardedRef<unknown>
) {
    const { allowClear, onSearch, onChange, className } = props;
    const [searchValue, setSearchValue] = React.useState<string>('');
    useImperativeHandle(ref, () => ({
        setSearchValue: (val: string) => void setSearchValue(val),
    }));
    return (
        <Box className={`search-box ${className || null}`}>
            <BaseInput
                className={`searchInput`}
                onChange={(event) => {
                    event.stopPropagation();
                    const value = event.target.value;
                    const str = value.replace(/(^\s*)|(\s*$)/g, '');
                    onChange?.(value);
                    // 搜索值为空时重新请求数据
                    if (str === '' || str === undefined || str == null) {
                        setSearchValue(value);
                        onSearch(value);
                    } else {
                        setSearchValue(value);
                    }
                }}
                id="standard-start-adornment"
                size={'small'}
                value={searchValue}
                onKeyDown={(e) => {
                    e.stopPropagation()
                    if (e.key === 'Enter') {
                        onChange?.(searchValue);
                        onSearch(searchValue);
                    }
                }}
                autoComplete={'off'}
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position="start"
                        >
                            <IconButton
                                className="searchIconBtn"
                                onClick={() => {
                                    onSearch(searchValue)
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        searchValue !== '' && allowClear &&
                        <InputAdornment position="end">
                            <IconButton
                                className="searchIconBtn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSearchValue('');
                                    onChange?.('');
                                    onSearch('');
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            // startAdornment={
            //     <InputAdornment
            //         position="start"
            //     >
            //         <IconButton
            //             className="searchIconBtn"
            //             onClick={() => {
            //                 onSearch(searchValue)
            //             }}
            //         >
            //             <SearchIcon />
            //         </IconButton>
            //     </InputAdornment>
            // }
            // endAdornment={
            //     searchValue !== '' && allowClear &&
            //     <InputAdornment position="end">
            //         <IconButton
            //             className="searchIconBtn"
            //             onClick={(e) => {
            //                 e.stopPropagation();
            //                 setSearchValue('');
            //                 onChange?.('');
            //                 onSearch('');
            //             }}
            //         >
            //             <CloseIcon />
            //         </IconButton>
            //     </InputAdornment>
            // }

            />
        </Box>
    )
}

export default React.forwardRef(SearchComponent);
