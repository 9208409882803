import { Add, CloseSharp, Refresh, Save, Delete } from '@mui/icons-material';
import { Box, Button, CircularProgress, Tooltip } from '@mui/material';
import React, { useState } from 'react';

export default function WaitButton(
    { children, onClick, titleText, variant, loadingStyle, color, action,disabled }:
        {
            children?: JSX.Element | string,
            onClick: () => void | Promise<void>,
            titleText: string,
            variant?: 'text' | 'contained' | 'outlined',
            loadingStyle?: React.CSSProperties,
            color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined,
            action?: 'ADD' | 'DELETE' | 'REFRESH' | 'SAVE' | 'CLOSE';
            disabled?:boolean
        }
): JSX.Element {
    const [isLoading, setLoad] = useState(false);

    const ActionIcon = () => {
        switch (action) {
            case 'ADD':
                return <Add />;
            case 'DELETE':
                return <Delete />;
            case 'REFRESH':
                return <Refresh />;
            case 'SAVE':
                return <Save />;
            case 'CLOSE':
                return <CloseSharp />;
            default:
                return children;
        }
    }
    // *********************
    // View
    // *********************
    return (
        <Tooltip title={titleText ?? ''} placement='top' >
            <Button
                variant={variant ? variant : 'contained'}
                disabled={disabled || (isLoading ? true : false)}
                color={color}
                onClick={async (e) => {
                    e.stopPropagation();
                    setLoad(true)
                    await onClick();
                    setLoad(false);
                }}

            >
                <>
                    {
                        isLoading &&
                        <Box style={{
                            backgroundColor: '#fff3',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1,
                            borderRadius: 'inherit',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <CircularProgress
                                color='primary'
                                size={'20px'}
                                style={{
                                    ...loadingStyle
                                }} />
                        </Box>
                    }
                    {ActionIcon()}
                </>
            </Button >
        </Tooltip >
    )
}

