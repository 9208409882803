import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import cn from './locales/cn.json';
import en from './locales/en.json';
import it from './locales/it.json';
import sp from './locales/sp.json';
import * as i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// Workaround for https://github.com/isaachinman/next-i18next/issues/1781
declare module 'i18next' {
  interface TFunction {
    <
      TKeys extends i18next.TFuncKey = string,
      TInterpolationMap extends object = i18next.StringMap
    >(
      key: TKeys,
      options?: i18next.TOptions<TInterpolationMap> | string,
    ): string;
  }
}

// 国际化资源文件,命名按照 ISO 639-1
const resources = {
  zh: {
    translation: cn
  },
  en: {
    translation: en
  },
  it: {
    translation: it
  },
  es: {
    translation: sp
  },
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    // 设置默认语言为IT
    fallbackLng: 'it',
    // we do not use keys in form messages.welcome
    keySeparator: false,
    interpolation: {
      // react already safes from xss
      escapeValue: false
    }
  });

// 当i18next初始化完成后
i18n.on('initialized', () => {
  // 如果当前语言不存在
  if (!i18n.services.languageUtils.isWhitelisted(i18n.language)) {
    // 设置当前语言为默认语言
    i18n.changeLanguage('it');
  }
});


export type Resources = keyof (typeof resources);

export default i18n;
