import { Warning } from '@mui/icons-material';
import { Box } from '@mui/material';
import DialogComponent from './dialog.component';
import './scss/dialog.style.scss';
import ButtonComponent from '../button/button.component';
import i18n from '../../i18n';

/**
 * 对话框组件
 * @param props
 * @returns
 */
function DeleteDialog(props: {
    // 开关
    open: boolean;
    // 标题
    title: string
    // 详情
    desc?: string
    // 取消文字
    cancelText?: string;
    // 确认文字
    confirmText?: string;
    // 点击图标或背景关闭
    onClose?: () => void;
    // 取消
    onCancel: () => void;
    // 确认
    onConfirm: () => void;
}) {
    const { title, open, desc, cancelText, confirmText, onCancel, onConfirm, onClose } = props;

    return (
        <DialogComponent
            defaultHeaderProps={{
                title: title || i18n.t('general_text_tips'),
                onClose: onClose ? onClose : onCancel,
                TitleIcon: (
                    <Box color="#ffc107" display="inline-flex" marginRight="8px">
                        <Warning />
                    </Box>
                )
            }}
            open={open}
            fullWidth={true}
            maxWidth={'xs'}
            onClose={onClose ? onClose : onCancel}
            transitionDuration={{
                appear: 450,
                enter: 450,
                exit: 300
            }}
            footer={
                <div className={'dialog-footer-button'}>
                    <ButtonComponent
                        size='medium'
                        color='primary'
                        variant='outlined'
                        extraButtonStyle={'button-group'}
                        handleClick={onCancel}
                    >{cancelText || i18n.t('general_button_cancel')}</ButtonComponent>

                    <ButtonComponent
                        size='medium'
                        color='primary'
                        variant='contained'
                        extraButtonStyle={'button-group'}
                        // sx={{ paddingLeft: 5, paddingRight: 5 }}
                        async={true}
                        handleClick={async () => {
                            // e.stopPropagation();
                            await onConfirm();
                        }}
                    >
                        {confirmText || i18n.t('general_button_confirm')}
                    </ButtonComponent>
                </div>
            }
        >
            <span>{desc || i18n.t('general_text_whether_to_delete_the_selection')}</span>
        </DialogComponent >
    );
}

export default DeleteDialog;