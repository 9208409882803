/**
 * 所有缓存的键值枚举
 */
export enum LOCAL_STORAGE_KEYS {
    // 语言
    'LANGUAGE' = 'LANGUAGE',
    // 账号信息
    'ACCOUNT' = 'ACCOUNT',
    // TOKEN信息
    'TOKEN' = 'TOKEN',
    // 标题
    'TITLE' = 'TITLE'
}