import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


// 可滚动Tabs
export default function ScrollTabs({
    data,
    activeIndex,
    onClick
}: {
    data: DataProps[],
    activeIndex: number,
    onClick: (value: ActiveTabProps) => void,
}) {
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        onClick({
            activeIndex: newValue,
            activeValue: data[newValue].id,
        });
    };

    return (
        <StyledTabs
            value={activeIndex}
            onChange={handleChange}
        >
            {
                data.map((item) => (
                    <StyledTab key={item.id} title={item.title} icon={item.icon} />
                ))
            }
        </StyledTabs>
    );
}

// 自定义Tabs
const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        variant='scrollable'
        scrollButtons='auto'
        {...props}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'none'
    },
    '& .MuiTabs-scrollButtons': {
        marginBottom: 10
    }
});

// 自定义Tab
const StyledTab = styled((props: StyledTabProps) => (
    <Tab {...props} label={props.title} />
))(({ theme }) => ({
    minHeight: 38,
    height: 38,
    borderRadius: 4,
    lineHeight: 1.75,
    color: theme.palette.primary.main,
    flexDirection: 'row',
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
    '.MuiSvgIcon-root': {
        marginRight: 8
    }
}));

// types
interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
    title: string;
    icon?: string | JSX.Element;
}

type DataProps = {
    title: string;
    icon?: string | JSX.Element;
    id: string
}

export type ActiveTabProps = {
    activeIndex: number;
    activeValue: string
}