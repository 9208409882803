import { Box, TextFieldProps } from '@mui/material';
import React from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { filterCountryAreaCode } from '../../constants/country-code';
import i18n from '../../i18n';
import BaseInput from './base-input.component';
// scss
import './scss/country-select.style.scss';


/**
 * 国家选择器组件  new
 */
function CountrySelect(props: CountrySelectProps) {

    const { countryVal = '39', disabled, onChange } = props;
    const filterObj = filterCountryAreaCode(countryVal);

    const [countryValue, setCountryValue] = React.useState<string>(filterObj?.dial_code || '39');
    const [inputVal, setInputVal] = React.useState<string>(filterObj?.value || 'IT');

    // 选择国家时调用
    const onSelectCountry = (selectVal: string, selectCountryCode: string) => {
        const setInputValue = selectCountryCode.toUpperCase();
        setCountryValue(selectVal);
        setInputVal(setInputValue);
        onChange?.(selectVal);
    }

    return (
        <Box className={'country-select-input'}>
            <Box className={'country-select'}>
                <PhoneInput
                    enableSearch={true}
                    preferredCountries={['it', 'es', 'cn']}
                    containerClass='react-input-fixed'
                    // dropdownClass='drop-pre'
                    // buttonClass='button-pre'
                    inputStyle={{
                        display: 'none'
                    }}
                    containerStyle={{
                        width: 35,
                        height: 25,
                    }}
                    searchStyle={{
                        border: 'none',
                        outline: 'none',
                    }}
                    disabled={disabled || false}
                    disableSearchIcon={true}
                    searchNotFound={i18n.t('general_text_not_found')}
                    value={countryValue}
                    onChange={(value, data: CountryData) => {
                        const inputValue = data.countryCode;
                        onSelectCountry(value, inputValue);
                    }}
                />
            </Box>
            <BaseInput
                className='code-input'
                type='phone'
                InputLabelProps={{
                    shrink: true
                }}
                size={props.size || 'small'}
                value={inputVal}
                disabled={true}
                variant="outlined"
                placeholder={'Select a country...'}
                label={i18n.t('general_text_country_code')}
                onChange={() => {
                    // const changeVal = event?.target?.value;
                }}
                fullWidth
            />
        </Box>
    );
}

export default CountrySelect;

type CountrySelectProps = {
    // 国家选择器默认输入值
    countryVal?: string;
    // 选择框禁用
    disabled?: boolean;
    // 下拉框改变
    onChange?: (value: string) => void;
    // size
    size: TextFieldProps['size'];
}