import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './scss/react-datepicker-fixed.scss';
import i18n from '../../i18n';

export default function MaterialUIPickers(props: MaterialUIPickersProps) {

    const [value, setValue] = React.useState<Date | null>(null);

    React.useEffect(() => {
        if (props.value !== undefined) {
            const v = props.value instanceof Date || moment.isMoment(props.value)
                ? props.value
                : moment(props.value, moment.HTML5_FMT.DATETIME_LOCAL_MS).toDate()
            setValue(props.value ? v : null)
        }
    }, [props.value])


    const handleChange = (newValue: Date | null) => {
        props?.handleChange?.(newValue);

    };

    // 完整选择时间值后的回调
    const onClosePicker = (newValue: Date | null) => {
        props?.onClosePicker?.(newValue);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <PickerCom
                {...{
                    value: value,
                    handleChange,
                    onClosePicker,
                    pickerType: props.pickerType,
                    textFieldProps: props.textFieldProps,
                    rangeValue: props.rangeValue,
                    rangeHandleChangePicker: props.rangeHandleChangePicker,
                    fullWidth: props.fullWidth,
                    minDate: props.minDate,
                    maxDate: props.maxDate,
                    filterTime: props.filterTime
                }}
            />
        </LocalizationProvider>
    );
}
interface PickerProps {
    label?: React.ReactNode;
    end?: Date | null;
    start?: Date | null;
    pickertype?: PickerType;
    changecallback?: (params: { dates?: [Date, Date], error?: boolean }) => void;
}
const CustomInput = React.forwardRef((props: PickerProps, ref) => {
    const { label, end, start, pickertype, changecallback, ...otherProps } = props;
    const formatRule = pickertype === 'DATE_RANGE' ? 'YYYY/MM/DD' : 'YYYY/MM/DD HH:mm';

    const [value, setValue] = React.useState('');
    const [isError, setIsError] = React.useState(false);
    React.useEffect(() => {
        setIsError(false);
        const startDate = start ? moment(start).format(formatRule) : '';
        const endDate = end ? ` - ${moment(end).format(formatRule)}` : null;
        setValue(`${startDate}${endDate !== null ? endDate : ''}`);
    }, [start, end])

    const checkDate = (date: string) => {
        try {
            if (date.length !== formatRule.length || moment(date).format(formatRule) === 'Invalid date') {
                setIsError(true);
                return false
            } else {
                setIsError(false);
                return moment(date).toDate();
            }
        } catch (error) {
            setIsError(true);
            return false
        }
    }
    return <TextField
        inputRef={ref}
        label={label || ''}
        {...otherProps}
        fullWidth
        size='small'
        value={value}
        error={isError}
        helperText={isError ? i18n.t('general_text_invalid_date') : undefined}
        onChange={(v) => {
            const tValue = v.target.value;
            setValue(v.target.value);
            if (tValue && tValue.includes(' - ')) {
                const reange = tValue.split(' - ');
                const startDate = checkDate(reange[0]);
                const endDate = checkDate(reange[1]);
                if (startDate instanceof Date && endDate instanceof Date) {
                    if (moment(startDate).isAfter(endDate)) {
                        setIsError(true);
                        changecallback?.({ error: true });
                    } else {
                        changecallback?.({ dates: [startDate, endDate] });
                    }
                } else {
                    changecallback?.({ error: true });
                }
            }

        }}
    />;
})

CustomInput.displayName = 'CustomInput'

function PickerCom(props: MaterialUIPickersProps) {
    const { value, pickerType, handleChange, onClosePicker, textFieldProps, rangeValue, rangeHandleChangePicker, minDate, maxDate, filterTime } = props;
    const { label, disabled, className } = textFieldProps ?? {};
    const inputFn = <TextField inputProps={{ readOnly: true }} fullWidth label={label} size={textFieldProps?.size} className={className} />
    const popperModifiers = [
        {
            name: 'offset',
            options: {
                offset: [5, 10],
            },
        },
        {
            name: 'preventOverflow',
            options: {
                rootBoundary: 'viewport',
                tether: false,
                altAxis: true,
            },
        },
    ];
    const startDateRange = rangeValue?.[0];
    const endDateRange = rangeValue?.[1];
    const startTimeRange = rangeValue?.[0] || null;
    const endTimeRange = rangeValue?.[1] || null;
    // 判断endTime是否超过00：00
    const isOver: boolean = moment(endTimeRange).hour() === 0 && moment(endTimeRange).minute() > 0;
    switch (pickerType) {
        case 'DATE':
            return <ReactDatePicker
                showMonthDropdown
                showYearDropdown
                selected={value as Date | null | undefined}
                dateFormat='yyyy/MM/dd'
                onChange={handleChange}
                customInput={inputFn}
                onCalendarClose={() => onClosePicker?.(value as Date | null)}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                popperModifiers={popperModifiers}
                scrollableYearDropdown
            />

        case 'TIME':
            return <ReactDatePicker
                showTimeSelect
                showTimeSelectOnly
                selected={value as Date | null | undefined}
                timeIntervals={30}
                timeFormat='HH:mm'
                dateFormat='HH:mm'
                onChange={handleChange}
                customInput={inputFn}
                onCalendarClose={() => onClosePicker?.(value as Date | null)}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                popperModifiers={popperModifiers}
            />

        case 'DATE_TIME':
            return <ReactDatePicker
                showTimeSelect
                showMonthDropdown
                showYearDropdown
                selected={value as Date | null | undefined}
                timeIntervals={30}
                timeFormat='HH:mm'
                dateFormat='MM/dd/yyyy HH:mm'
                onChange={handleChange}
                customInput={inputFn}
                onCalendarClose={() => onClosePicker?.(value as Date | null)}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                popperModifiers={popperModifiers}
            />
        case 'DATE_TIME_RANGE':
        case 'DATE_RANGE':
            return <ReactDatePicker
                endDate={endDateRange}
                selected={endDateRange || startDateRange}
                startDate={startDateRange}
                selectsRange
                showTimeSelect={pickerType === 'DATE_TIME_RANGE'}
                selectsStart
                showYearDropdown
                timeFormat='HH:mm'
                minDate={minDate}
                maxDate={maxDate}
                filterTime={filterTime}
                onChange={(dates) => {
                    if (dates instanceof Array) {
                        rangeHandleChangePicker?.(dates);
                    } else {
                        const newStart = startDateRange ? new Date(startDateRange) : null;
                        const newEnd = endDateRange ? new Date(endDateRange) : null;
                        const hour = (dates as Date).getHours();
                        const min = (dates as Date).getMinutes();
                        // $ 交互流程，1. 选择开始日期，时间点，2.再选结束日期，时间点；
                        if (newEnd) {
                            newEnd?.setHours(hour);
                            newEnd?.setMinutes(min);
                        } else {
                            newStart?.setHours(hour);
                            newStart?.setMinutes(min);
                        }
                        rangeHandleChangePicker?.([newStart, newEnd]);
                    }
                }}
                shouldCloseOnSelect={false}
                customInput={
                    <CustomInput label={label} start={startDateRange} end={endDateRange} pickertype={pickerType}
                        changecallback={(params) => {
                            if (params.dates instanceof Array) {
                                rangeHandleChangePicker?.(params.dates);
                            } else {
                                rangeHandleChangePicker?.(undefined, true);
                            }
                        }} />
                }
                disabled={disabled}
                popperModifiers={popperModifiers}
            />
        case 'TIME_RANGE':

            return <div className='time-range-box FCC'>
                <ReactDatePicker
                    showTimeSelect
                    showTimeSelectOnly
                    selected={startTimeRange as Date | null | undefined}
                    timeIntervals={10}
                    timeFormat='HH:mm'
                    dateFormat='HH:mm'
                    onChange={(value) => {
                        rangeHandleChangePicker?.([value, endTimeRange]);
                    }}
                    customInput={<TextField inputProps={{ readOnly: true }} fullWidth label={i18n.t('business_analysis_page_text_start_time')} size={textFieldProps?.size} />}
                    disabled={disabled}
                    minDate={minDate}
                    maxDate={maxDate}
                    popperModifiers={popperModifiers}
                />
                <span> - </span>
                <ReactDatePicker
                    showTimeSelect
                    showTimeSelectOnly
                    selected={endTimeRange as Date | null | undefined}
                    timeIntervals={10}
                    timeFormat={'HH:mm'}
                    dateFormat={isOver ? 'HH:mm' : 'kk:mm'}
                    onChange={(value) => {
                        rangeHandleChangePicker?.([startTimeRange, value]);
                    }}
                    customInput={<TextField inputProps={{ readOnly: true }} fullWidth label={i18n.t('business_analysis_page_text_end_time')} size={textFieldProps?.size} />}
                    disabled={disabled}
                    minDate={minDate}
                    maxDate={maxDate}
                    popperModifiers={popperModifiers}
                />
            </div>
        default:
            return <></>
    }
}

export type PickerType = 'DATE' | 'TIME' | 'DATE_TIME' | 'DATE_TIME_RANGE' | 'TIME_RANGE' | 'DATE_RANGE';

export type MaterialUIPickersProps = {
    pickerType?: PickerType;
    value?: Date | null;
    rangeValue?: [Date | null, Date | null];
    handleChange: (value: Date | null) => void;
    onClosePicker?: (value: Date | null) => void;
    textFieldProps?: TextFieldProps;
    rangeHandleChangePicker?: (value?: [Date | null, Date | null], error?: boolean) => void;
    fullWidth?: boolean;
    minDate?: Date | null;
    maxDate?: Date | null;
    filterTime?(date: Date): boolean;
}