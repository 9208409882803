import React, { useImperativeHandle } from 'react';
import { Box, Chip } from '@mui/material';
import CommonInput from './common-input.component';
import { SelectOption } from './base-input.component';
import i18n from '../../i18n';
import { Option } from './select-input-multiple'
import SearchComponent from './search-input.component';
import _ from 'lodash';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import GlobalNotification from '../common/snackbar';


/**
 * 搜索关键词输入框
 */
const SearchKeyInput = React.forwardRef((props: {
    items: SearchKeyInputItems[];
    // 当前全部筛选条件
    conditions: ConditionType[];
    // 右边的onChange 返回条件数组
    onSearch: (conditions: ConditionType[]) => void;
    // 显示已选条件
    showConditionsBox?: boolean;
    style?: React.CSSProperties;
    className?: string;
    onSearchKeyChange?: (key: string) => void;
}, ref: React.ForwardedRef<unknown>) => {
    const { items, showConditionsBox, } = props;
    const [searchKey, setSearchKey] = React.useState('');
    const [selectKey, setSelectKey] = React.useState('');
    const [dateTimeData, setDateTimeData] = React.useState<{
        startDate: Date | null,
        endDate: Date | null,
    }>({
        startDate: new Date(new Date().setHours(4, 0, 0)),
        endDate: new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(4, 0, 0))
    });

    useImperativeHandle(ref, () => ({
        searchKey: searchKey,
        setSelectKey: (val: string) => setSelectKey(val),
        setSearchKey: (val: string) => setSearchKey(val),
    }));

    React.useEffect(() => {
        setSearchKey(String(items[0]?.value || ''));
    }, [])

    /**
     * 创建条件
     * @param condition
     */
    const createCondition = (condition: ConditionType) => {
        const { conditions } = props;
        // 找出key相同且值相同的条件
        const sameConditions = _.filter(conditions, item => {
            return item && condition && (item.key === condition.key) && (_.isEqual(item.value, condition.value));
        })
        if (sameConditions.length === 0) {
            conditions.push(condition);
        }
        props.onSearch(conditions)
    }


    const searchItem = items.find((item) => item.value === searchKey);
    const searchRef = React.useRef<{ setSearchValue: (val: string) => void }>();

    return <Box style={props.style} className={props.className}>
        <Box display={'flex'} >
            {/* 下拉框 */}
            <Box style={{ marginRight: '8px' }}>
                <CommonInput
                    inputType={'SELECT'}
                    select={true}
                    items={items}
                    size={'small'}
                    fullWidth
                    value={searchKey}
                    onChange={(e) => {
                        setSearchKey(e.target.value);
                        props.onSearchKeyChange?.(e.target.value);
                        setSelectKey('');
                        searchRef?.current?.setSearchValue?.('');
                    }}
                />
            </Box>
            {/* 搜索框 */}
            <Box>
                {
                    searchItem?.type === 'SELECT' &&
                    <CommonInput
                        style={{ minWidth: '200px' }}
                        inputType={'SELECT'}
                        items={[
                            { value: '-1', label: i18n.t('google_text_please_select'), disabled: true },
                            ...(searchItem.options || [])
                        ]}
                        value={selectKey === '' ? '-1' : selectKey}
                        onChange={(e) => {
                            const value = e.target.value;
                            const obj = searchItem.options?.find((item) => item.value === value);
                            if (value) {
                                setSelectKey(value);
                                createCondition({
                                    value: value,
                                    key: searchKey,
                                    selectValue: obj?.label,
                                    label: searchItem?.label || ''
                                })
                            }
                        }}
                    />
                }
                {searchItem?.type === 'TEXT' &&
                    <SearchComponent
                        ref={searchRef}
                        {...{
                            allowClear: true,
                            onSearch: (value) => {
                                if (value.trim()) {
                                    createCondition({
                                        value: value,
                                        key: searchKey,
                                        selectValue: value,
                                        label: searchItem?.label || ''
                                    })
                                }
                            },
                        }}
                    />
                }
                {searchItem?.type === 'DATE_TIME_RANGE' &&
                    <Box sx={{ width: 300 }}>
                        <CommonInput
                            inputType={'DATE_TIME_RANGE'}
                            label={i18n.t('operation_record_operation_time')}
                            rangeHandleChangePicker={(dates) => {
                                if (dates) {
                                    const startDate = dates[0] ? moment(dates[0]).format('YYYY/MM/DD HH:mm') : '';
                                    const endDate = dates[1] ? `${moment(dates[1]).format('YYYY/MM/DD HH:mm')}` : null;
                                    setDateTimeData({ startDate: dates[0], endDate: dates[1] });
                                    // 计算日期跨度
                                    const dayDiff = moment(endDate).diff(moment(startDate), 'day');
                                    if (searchItem.dayDiffLimit && (dayDiff > searchItem.dayDiffLimit)) {
                                        // 日期跨度大于31天，抛出错误
                                        GlobalNotification.error(i18n.t('business_analysis_page_the_date_interval_cannot_exceed_31'));
                                        return
                                    }
                                    if (dates[0] && dates[1]) {
                                        createCondition({
                                            value: `${startDate},${endDate}`,
                                            key: searchKey,
                                            selectValue: `${startDate} - ${endDate}`,
                                            label: searchItem?.label || ''
                                        })
                                    }
                                }
                            }
                            }
                            rangeValue={[dateTimeData.startDate, dateTimeData.endDate]}
                        />
                    </Box>
                }
            </Box>
        </Box>
        {showConditionsBox &&
            <Box style={{ marginTop: '8px' }}>
                <ConditionsBox
                    conditions={props.conditions}
                    onDelete={(conditions) => {
                        props.onSearch(conditions);
                        setSelectKey('');
                    }}
                />
            </Box>
        }
    </Box>
})

SearchKeyInput.displayName = 'SearchKeyInput'

export { SearchKeyInput }


/**
 * 已搜的搜索条件
 * @param conditions
 * @param onDelete
 * @returns
 */
export function ConditionsBox(props: { conditions: ConditionType[], onDelete: (conditions: ConditionType[]) => void }) {
    return <Box  >
        {
            props.conditions.map((item, index) => (
                <Chip
                    key={index}
                    deleteIcon={<Close />}
                    className='statusAlert mr8 mb8'
                    label={`${i18n.t(item.label)}:${item.selectValue || item.value}`}
                    onDelete={() => {
                        const conditions = props.conditions;
                        conditions.splice(index, 1);
                        props.onDelete(conditions);
                    }
                    }
                />
            ))
        }
    </Box>
}


export type SearchKeyInputItems = {
    options?: Option[];
    type: 'TEXT' | 'SELECT' | 'DATE_TIME_RANGE';
    dayDiffLimit?: number;
} & SelectOption;


export type ConditionType = {
    // value
    value: string;
    // key
    key: string;
    // 左边文本
    label: string;
    // 右边文本
    selectValue?: string;
    // range 范围
    rangeValue?: string;
}