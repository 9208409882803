import { AxiosRequestConfig } from 'axios';
import http, { HttpSuccessOrErrorType, OkPacket } from '../http';
import { User } from '../../models';


export default {
	/**
	 * 注册
	 */
	async post_register_account(
		params: Pick<User, 'name' | 'phone' | 'phone_prefix' | 'password' | 'email'>,
		config?: AxiosRequestConfig): Promise<HttpSuccessOrErrorType<OkPacket>> {
		return await http.post(`/register`, {
			...params,
			...config
		})
	},

	/**
	 * 登录
	 */
	async post_login_account(params: {
		phone: string,
		phone_prefix: string,
		password: string
	}, config?: AxiosRequestConfig): Promise<HttpSuccessOrErrorType<any>> {
		return await http.post(`/login`, {
			...params
		},
			{
				headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
					'authorization-type': 'BASIC_AUTH'
                },
				...config
			}

		)
	},

	/**
	 * 获取当前登录的信息
	 */
	async get_auth_token_metadata(config?: AxiosRequestConfig): Promise<HttpSuccessOrErrorType<any>> {
		return await http.get(`/auth/token/metadata`,
			{ ...config }
		)
	}
}