import CardFrom, { CardFormChildrenConfig, CardFormConfig, checkCardFromRule } from '../../components/card/card-from';
import { useState } from 'react';
import ButtonComponent from '../../components/button/button.component';
import apiManage from '../../request';
import { dateFormat } from '../../constants/tool';
import { User } from '../../models';
import GlobalNotification from '../../components/common/snackbar';
import { getImageUrl } from '../../constants/tool-image';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

function AccountDetail({ userDetail, updateUserDetail }: Readonly<Props>) {
    const { t } = useTranslation();
    // 开启校验
    const [isValidate, setIsValidate] = useState(false)
    // 修改了的账号数据，只传修改了的给后端
    const [editData, setEditData] = useState<User>({})
    // 头像
    const [avatarFile, setAvatarFile] = useState<File>()
    // 账号数据
    const data = {
        ...userDetail,
        avatar: getImageUrl(userDetail.avatar ?? ''),
        ...editData
    };


    /**
     * 表单回调
     * @param key 
     * @param value 
     */
    const handleChange = (value: string | Date | null, key: string) => {
        setEditData({
            ...editData,
            [key]: value
        })
    }

    /**
     * 上传头像
     * @param file 
     */
    const handleChangeAvatar = (file: File) => {
        setAvatarFile(file)
    }



    /**
     * 保存
     * 只传修改的数据过去
     */
    const handleSave = async (cardFormConfig: CardFormConfig[]) => {
        // 开启校验
        setIsValidate(true)
        // 表单校验
        const errorList = checkCardFromRule({ config: cardFormConfig, data: data as unknown as {} });
        if (errorList.length) {
            return;
        }

        let ok = true;
        if (Object.keys(editData).length > 0) {
            // 修改个人信息
            const userInfoResult = await apiManage.patch_user_info_id(data._id ?? '', {
                ...editData,
                birthday: editData.birthday ? dateFormat(editData.birthday) : undefined,
                avatar: undefined
            })
            if (!(userInfoResult && !userInfoResult.error)) {
                ok = false
            }
        }

        if (avatarFile) {
            // 修改头像
            const avatarResult = await apiManage.post_user_avatar_id(data._id ?? '', avatarFile)
            if (!(avatarResult && !avatarResult.error)) {
                ok = false
            }
        }
        if (ok) {
            GlobalNotification.success(t('global_text_operate_success'));
            updateUserDetail()
        } else {
            GlobalNotification.error(t('global_text_operate_failure'));
        }
    }


    // 表单
    const formData: CardFormChildrenConfig[] = [
        {
            key: 'avatar',
            comType: 'AVATAR',
            md: 12,
            handleChangeAvatar: ((avatarUrl: string, file: File) => {
                handleChange(avatarUrl, 'avatar');
                handleChangeAvatar(file)
            })
        },
        {
            key: 'name',
            label: t('general_text_name'),
            required: true,
            regularType: 'inputLimit',
            comType: 'INPUT',
            inputType: 'INPUT',
            inputIsEdited: editData.name ? editData.name === data.name : false,
            onChange: (e) => handleChange(e.target.value, 'name'),
        },
        {
            key: 'gender',
            label: t('general_text_gender'),
            comType: 'INPUT',
            inputType: 'SELECT',
            items: [{
                value: '0',
                label: t('person_page_button_male')
            }, {
                value: '1',
                label: t('person_page_button_female')
            }],
            inputIsEdited: editData.gender ? editData.gender === data.gender : false,
            onChange: (e) => handleChange(e.target.value, 'gender'),
        },
        {
            key: 'birthday',
            label: t('person_page_text_birthday'),
            comType: 'INPUT',
            inputType: 'DATE',
            inputIsEdited: editData.birthday ? editData.birthday === data.birthday : false,
            handleChangePicker: (value) => handleChange(value, 'birthday'),
            maxDate: new Date()
        },
        {
            key: 'email',
            label: t('general_text_email'),
            regularType: 'email',
            comType: 'INPUT',
            inputIsEdited: editData.email ? editData.email === data.email : false,
            onChange: (e) => handleChange(e.target.value, 'email'),
        },
    ]

    // 表单设置
    const cardConfig: CardFormConfig = {
        key: 'baseInfo',
        title: t('ground_staff_component_text_basic_information'),
        rowSpacing: 4,
        columnSpacing: { md: 4, xs: 4 },
        md: 12,
        actionRender: <ButtonComponent action='SAVE' handleClick={() => handleSave([cardConfig])} />,
        children: formData.map((item) => ({
            xs: 12,
            md: 6,
            inputIsEdited: false,
            isEmpty: isValidate,
            ...item
        }))
    }

    return (
        <Box width={600}>
            <CardFrom
                config={[cardConfig]}
                data={data}
            />
        </Box>
    )
}

type Props = {
    // 用户详情
    userDetail: User;
    // 更新用户详情
    updateUserDetail: () => void;
}

export default AccountDetail