import { useState } from 'react';
import CardFrom, { CardFormConfig, checkCardFromRule } from '../../components/card/card-from'
import ButtonComponent from '../../components/button/button.component';
import GlobalNotification from '../../components/common/snackbar';
import apiManage from '../../request';
import { Box } from '@mui/material';
import { User } from '../../models';
import { cleanAccountCache } from '../../constants/tool-login';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function SafeDetail({ userDetail = {} }: Readonly<Props>) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // 账号数据
    const [data, setData] = useState<EditPasswordParamsProps>({});
    // 是否开启校验
    const [isValidate, setIsValidate] = useState(false)

    /**
     * 保存
     */
    const handleSave = async (cardFormConfig: CardFormConfig[]) => {
        setIsValidate(true)
        // 表单校验
        const errorList = checkCardFromRule({ config: cardFormConfig, data: data as unknown as {} });
        if (errorList.length) {
            return;
        }
        if (data.confirmNewPassword !== data.newPassword) {
            GlobalNotification.error(t('password_page_text_inconsistent_passwords'))
            return
        }

        // 修改个人信息
        const userInfoResult = await apiManage.patch_user_info_id_password(userDetail._id ?? '', {
            newPassword: data.newPassword ?? '',
            currentPassword: data.currentPassword ?? ''
        })
        if (userInfoResult && !userInfoResult.error) {
            GlobalNotification.success(t('global_text_operate_success'));
            navigate('/login');
            cleanAccountCache()
        } else {
            GlobalNotification.error(t('global_text_operate_failure'));
        }

    }


    /**
     * 表单回调
     * @param key 
     * @param value 
     */
    const handleChange = (value: string | Date | null, key: string) => {
        setData({
            ...data,
            [key]: value
        })
    }


    // 表单
    const formData: { key: keyof EditPasswordParamsProps, label: string, required: boolean, regularType: string }[] = [
        {
            key: 'currentPassword',
            label: t('password_page_text_old_password'),
            required: true,
            regularType: 'password',

        },
        {
            key: 'newPassword',
            label: t('password_page_text_new_password'),
            required: true,
            regularType: 'password',
        },
        {
            key: 'confirmNewPassword',
            label: t('password_page_text_confirm_password'),
            required: true,
            regularType: 'password',
        }
    ]

    // 表单设置
    const cardConfig: CardFormConfig = {
        key: 'baseInfo',
        title: t('person_page_change_password'),
        rowSpacing: 2,
        columnSpacing: { md: 2, xs: 2 },
        md: 12,
        actionRender: <ButtonComponent action='SAVE' handleClick={() => handleSave([cardConfig])} />,
        children: formData.map((item) => ({
            ...item,
            xs: 12,
            md: 12,
            inputIsEdited: false,
            inputType: 'PASSWORD',
            comType: 'INPUT',
            isEmpty: isValidate,
            onChange: (value) => handleChange(value.target.value, item.key)
        }))
    }


    return (
        <Box width={400}>
            <CardFrom
                config={[cardConfig]}
                data={data}
            />
        </Box>
    )
}

type Props = {
    userDetail: User
}

// 修改密码参数
type EditPasswordParamsProps = {
    currentPassword?: string;
    newPassword?: string;
    confirmNewPassword?: string
}