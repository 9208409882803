import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import GlobalNotification from '../../components/common/snackbar';
import { MobxObjectsManager } from '../../components/mobx-objs/mobx-manager';
import apiManage from '../../request';
import i18n from '../../i18n';



export class SettingState {
	constructor() {
		makeAutoObservable(this);
	}

	public isLoading: boolean = false;
	public isEditDate: boolean = false;
	public isEditITInvoice: boolean = false;
	public isEditESInvoice: boolean = false;
	public month: number | undefined = 6;
	public ITInvoice: Array<{ name: string, status: boolean }> = [];
	public ESInvoice: Array<{ name: string, status: boolean }> = [];

	public cerved_key: string = '';
	public isEditKey: boolean = false;

	public gyb_nickname: string = '';
	public gyb_password: string = '';
	public isEditGyb: boolean = false;

	async init() {
		this.isEditDate = false;
		this.isEditGyb = false;
		this.isEditGyb = false;
		this.isEditESInvoice = false;
		this.isEditITInvoice = false;
		await this.getSettingData()
	}

	setGybName(val: string) {
		this.gyb_nickname = val;
	}

	setGybPassword(val: string) {
		this.gyb_password = val;
	}

	setIsEditGYB(val: boolean) {
		this.isEditGyb = val;
	}

	setCervedKey(val: string) {
		this.cerved_key = val;
	}

	setIsEditKey(val: boolean) {
		this.isEditKey = val;
	}

	setMonth(val: number | undefined) {
		this.month = val;
	}

	setIsEditDate(val: boolean) {
		this.isEditDate = val;
	}

	// 编辑设置
	async editSetting(param: any) {
		try {
			this.setIsLoading(true);
			const setting = await apiManage.patch_admin_setting(param);
			if (!setting.error) {
				GlobalNotification.success(i18n.t('global_text_operate_success'));
				await this.getSettingData();
			}
		} catch (error) {
			GlobalNotification.success(i18n.t('global_text_operate_failure'));
		}
	}

	setIsLoading(val: boolean) {
		this.isLoading = val;
	}

	async getSettingData() {
		try {
			this.setIsLoading(true);
			const setting = await apiManage.get_admin_setting();
			if (!setting.error) {
				this.setESInvoice(setting.priority.ES);
				this.setITInvoice(setting.priority.IT);
				this.setMonth(setting.renewal_cycle || 6);
				this.setCervedKey(setting.cerved_key);
				this.setGybName(setting.username);
				this.setGybPassword(setting.password)
			}
			this.setIsLoading(false);
		} catch (error) {
			this.setIsLoading(false);
		}
	}

	setESInvoice(val: Array<{ name: string, status: boolean }>) {
		this.ESInvoice = val;
	}

	setITInvoice(val: Array<{ name: string, status: boolean }>) {
		this.ITInvoice = val;
	}


	ITOnDragEnd(result: any) {
		// Check if the item was dropped outside the list
		if (!result.destination || !this.isEditITInvoice) {
			return;
		}

		// Reorder the items in ITInvoice array
		const updatedITInvoice = Array.from(this.ITInvoice);
		const [reorderedItem] = updatedITInvoice.splice(result.source.index, 1);
		updatedITInvoice.splice(result.destination.index, 0, reorderedItem);

		this.setITInvoice(updatedITInvoice);
	}

	ESOnDragEnd(result: any) {
		// Check if the item was dropped outside the list
		if (!result.destination || !this.isEditESInvoice) {
			return;
		}

		// Reorder the items in ITInvoice array
		const updatedITInvoice = Array.from(this.ESInvoice);
		const [reorderedItem] = updatedITInvoice.splice(result.source.index, 1);
		updatedITInvoice.splice(result.destination.index, 0, reorderedItem);

		this.setESInvoice(updatedITInvoice);
	}

	onChange(type: string, name: string, status: boolean) {
		if (type === 'IT') {
			const invoice = _.cloneDeep(this.ITInvoice);
			const targetObject = invoice.find(item => item.name === name);
			if (targetObject) {
				targetObject.status = !status;
			}
			this.setITInvoice(invoice)
		}
		if (type === 'ES') {
			const invoice = _.cloneDeep(this.ESInvoice);
			const targetObject = invoice.find(item => item.name === name);
			if (targetObject) {
				targetObject.status = !status;
			}
			this.setESInvoice(invoice)
		}
	}

	setIsEditESInvoice(val: boolean) {
		this.isEditESInvoice = val;
	}

	setIsEditITInvoice(val: boolean) {
		this.isEditITInvoice = val;
	}
}

export const settingState = MobxObjectsManager.get(SettingState);

