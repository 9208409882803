// themeTool
import { PaletteMode } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/**
 * 获取当前主题模式 "light" | "dark"
 */
export function GetThemeMode(): PaletteMode {
    const theme = useTheme();
    return theme.palette.mode
}