
import { mainStore } from '../stores';

/**
 * 清空缓存
 * @param
 */
export function cleanAccountCache() {
    try {
        mainStore.updateAccount({})
        localStorage.clear()
    } catch (error) { }
}

